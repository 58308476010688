import { MessageBundle } from "@amzn/arb-tools";
import {
  FORM_MODE,
  RECORD_NAME_VALID_CHARACTERS_REGEX,
  RECORD_NAME_SIZE_LIMIT,
  SecurityTeamFormFields,
  SecurityTeamFormStrings,
  TextInputFieldValidationType,
} from "../../constants/constants";
import { object, string, array, boolean } from "yup";
import { CriteriaSetValueTokens } from "./SecurityTeamConfigPage";

export const SecurityTeamConfigPageFormValidationSchema = (
  bundle: MessageBundle,
  securityTeamRecordName: string,
  securityTeamsNameSet: Set<string>,
  formMode: FORM_MODE
) => {
  const valueRequredErrorMessage: string = bundle.getMessage(
    SecurityTeamFormStrings.SECURITY_TEAM_INPUT_REQUIRED_ERROR_MESSAGE
  );
  const tokenEmptyErrorMessage: string = bundle.getMessage(
    SecurityTeamFormStrings.SECURITY_TEAM_FILTER_TOKEN_EMPTY_ERROR_MESSAGE
  );

  return object().shape({
    teamName: string()
      .required(valueRequredErrorMessage)
      .test(
        TextInputFieldValidationType.CHARACTERS_SIZE,
        bundle.getMessage(
          SecurityTeamFormStrings.SECURITY_TEAM_NAME_SIZE_LIMITATIONS_ERROR_MESSAGE
        ),
        function (value: string) {
          // Check if teamName size is within the limit
          return value.length < RECORD_NAME_SIZE_LIMIT;
        }
      )
      .test(
        TextInputFieldValidationType.SPECIAL_CHARACTERS,
        bundle.getMessage(
          SecurityTeamFormStrings.SECURITY_TEAM_NAME_CHARACTER_LIMITATIONS_ERROR_MESSAGE
        ),
        function (value: string) {
          // Check if teamName contains only allowed characters (alphanumeric, _, (, ), &, /, @, ', -, and , characters plus spaces)
          return RECORD_NAME_VALID_CHARACTERS_REGEX.test(value);
        }
      )
      .test(
        SecurityTeamFormStrings.SECURITY_TEAM_NAME_UNIQUE_ERROR_MESSAGE,
        bundle.getMessage(
          SecurityTeamFormStrings.SECURITY_TEAM_NAME_UNIQUE_ERROR_MESSAGE
        ),
        function (value) {
          //Check if taskName is unique using existing tasksName Set we created from Tasks Get API response
          const isUnique = isSecurityTeamNameUnique(
            value,
            securityTeamRecordName,
            securityTeamsNameSet,
            formMode
          );
          return isUnique;
        }
      ),
    ldapWithSnowRoleList: array().of(
      object().shape({
        teamLdapGroup: string().required(valueRequredErrorMessage),
        serviceNowRole: object()
          .shape({
            label: string().required(valueRequredErrorMessage),
            value: string().required(valueRequredErrorMessage),
          })
          .required(valueRequredErrorMessage),
      })
    ),
    disableTpsConfig: boolean().nullable(),
    disableTier3Automation: boolean().nullable(),
    disableTier4Automation: boolean().nullable(),
    disableAriesWorkflow: boolean().nullable(),
    isActive: boolean().nullable(),
    teamDeterminationCriteria: object()
      .shape({
        tokens: array().of(
          object().shape({
            operator: string().required(tokenEmptyErrorMessage),
            questionDisplay: string().required(tokenEmptyErrorMessage),
            answerDisplay: string().required(tokenEmptyErrorMessage),
          })
        ),
        operation: string().required(tokenEmptyErrorMessage),
      })
      .notRequired(),
    ddqQuestionGenerationCheckbox: boolean(),
    ddqLogicTagNameList: array()
      .of(
        object().shape({
          label: string().required(valueRequredErrorMessage),
          value: string().required(valueRequredErrorMessage),
        })
      )
      .notRequired()
      .when(SecurityTeamFormFields.DDQ_QUESTION_GENERATION_CHECKBOX, {
        is: true,
        then: () =>
          array()
            .of(
              object().shape({
                label: string().required(valueRequredErrorMessage),
                value: string().required(valueRequredErrorMessage),
              })
            )
            .min(1, valueRequredErrorMessage)
            .required(valueRequredErrorMessage),
        otherwise: () =>
          array()
            .of(
              object().shape({
                label: string().required(valueRequredErrorMessage),
                value: string().required(valueRequredErrorMessage),
              })
            )
            .notRequired(),
      }),
    ddqQuestionCriteriaSetCheckbox: boolean(),
    ddqLogicCriteriaSetLists: array()
      .of(
        object().shape({
          tokens: array().of(
            object().shape({
              operator: string().required(tokenEmptyErrorMessage),
              questionDisplay: string().required(tokenEmptyErrorMessage),
              answerDisplay: string().required(tokenEmptyErrorMessage),
            })
          ),
          operation: string().required(tokenEmptyErrorMessage),
        })
      )
      .notRequired()
      .when(SecurityTeamFormFields.DDQ_QUESTION_CRITERIA_SET_CHECKBOX, {
        is: true,
        then: () =>
          array().of(
            object().shape({
              tokens: array()
                .of(
                  object().shape({
                    operator: string().required(tokenEmptyErrorMessage),
                    questionDisplay: string().required(tokenEmptyErrorMessage),
                    answerDisplay: string().required(tokenEmptyErrorMessage),
                  })
                )
                .min(1, valueRequredErrorMessage)
                .required(valueRequredErrorMessage),
              operation: string().required(tokenEmptyErrorMessage),
            })
          ),
        otherwise: () =>
          array()
            .of(
              object().shape({
                tokens: array().of(
                  object().shape({
                    operator: string().required(tokenEmptyErrorMessage),
                    questionDisplay: string().required(tokenEmptyErrorMessage),
                    answerDisplay: string().required(tokenEmptyErrorMessage),
                  })
                ),
                operation: string().required(tokenEmptyErrorMessage),
              })
            )
            .notRequired(),
      }),
    ddqLogicCriteriaSetsTagNamesLists: array()
      .of(
        array().of(
          object().shape({
            label: string().required(valueRequredErrorMessage),
            value: string().required(valueRequredErrorMessage),
          })
        )
      )
      .notRequired()
      .when(SecurityTeamFormFields.DDQ_QUESTION_CRITERIA_SET_CHECKBOX, {
        is: true,
        then: () =>
          array()
            .of(
              array()
                .of(
                  object().shape({
                    label: string().required(valueRequredErrorMessage),
                    value: string().required(valueRequredErrorMessage),
                  })
                )
                .min(1, valueRequredErrorMessage)
                .required(valueRequredErrorMessage)
            )
            .min(1, valueRequredErrorMessage)
            .required(valueRequredErrorMessage),
        otherwise: () =>
          array()
            .of(
              array().of(
                object().shape({
                  label: string().required(valueRequredErrorMessage),
                  value: string().required(valueRequredErrorMessage),
                })
              )
            )
            .notRequired(),
      }),
    tasksGenerationCheckbox: boolean(),
    taskLogicTagNameList: array()
      .of(
        object().shape({
          label: string().required(valueRequredErrorMessage),
          value: string().required(valueRequredErrorMessage),
        })
      )
      .notRequired()
      .when(SecurityTeamFormFields.TASKS_GENERATION_CHECKBOX, {
        is: true,
        then: () =>
          array()
            .of(
              object().shape({
                label: string().required(valueRequredErrorMessage),
                value: string().required(valueRequredErrorMessage),
              })
            )
            .min(1, valueRequredErrorMessage)
            .required(valueRequredErrorMessage),
        otherwise: () =>
          array()
            .of(
              object().shape({
                label: string().required(valueRequredErrorMessage),
                value: string().required(valueRequredErrorMessage),
              })
            )
            .notRequired(),
      }),
    tasksCriteriaSetCheckbox: boolean(),
    taskLogicCriteriaSetLists: array()
      .of(
        object().shape({
          tokens: array().of(
            object().shape({
              operator: string().required(tokenEmptyErrorMessage),
              questionDisplay: string().required(tokenEmptyErrorMessage),
              answerDisplay: string().required(tokenEmptyErrorMessage),
            })
          ),
          operation: string().required(tokenEmptyErrorMessage),
        })
      )
      .notRequired()
      .when(SecurityTeamFormFields.TASKS_CRITERIA_SET_CHECKBOX, {
        is: true,
        then: () =>
          array().of(
            object().shape({
              tokens: array()
                .of(
                  object().shape({
                    operator: string().required(tokenEmptyErrorMessage),
                    questionDisplay: string().required(tokenEmptyErrorMessage),
                    answerDisplay: string().required(tokenEmptyErrorMessage),
                  })
                )
                .min(1, valueRequredErrorMessage)
                .required(valueRequredErrorMessage),
              operation: string().required(tokenEmptyErrorMessage),
            })
          ),
        otherwise: () =>
          array()
            .of(
              object().shape({
                tokens: array().of(
                  object().shape({
                    operator: string().required(tokenEmptyErrorMessage),
                    questionDisplay: string().required(tokenEmptyErrorMessage),
                    answerDisplay: string().required(tokenEmptyErrorMessage),
                  })
                ),
                operation: string().required(tokenEmptyErrorMessage),
              })
            )
            .notRequired(),
      }),
    taskLogicCriteriaSetsTagNamesLists: array()
      .of(
        array().of(
          object().shape({
            label: string().required(valueRequredErrorMessage),
            value: string().required(valueRequredErrorMessage),
          })
        )
      )
      .notRequired()
      .when(SecurityTeamFormFields.TASKS_CRITERIA_SET_CHECKBOX, {
        is: true,
        then: () =>
          array()
            .of(
              array()
                .of(
                  object().shape({
                    label: string().required(valueRequredErrorMessage),
                    value: string().required(valueRequredErrorMessage),
                  })
                )
                .min(1, valueRequredErrorMessage)
                .required(valueRequredErrorMessage)
            )
            .min(1, valueRequredErrorMessage)
            .required(valueRequredErrorMessage),
        otherwise: () =>
          array()
            .of(
              array().of(
                object().shape({
                  label: string().required(valueRequredErrorMessage),
                  value: string().required(valueRequredErrorMessage),
                })
              )
            )
            .notRequired(),
      }),
  });
};

const isSecurityTeamNameUnique = (
  value: string,
  securityTeamRecordName: string,
  securityTeamsNameSet: Set<string>,
  formMode: FORM_MODE
) => {
  if (formMode === FORM_MODE.EDIT && value === securityTeamRecordName)
    return true; //In edit mode user didn't changes the existing task Name in that case it should always return true to avoid unnecessary error

  return !securityTeamsNameSet.has(value.trimEnd());
};

export const getPropertyFilterValidationError = (
  errorObject: string | CriteriaSetValueTokens
): string => {
  let errorMessage = "";
  if (typeof errorObject === "string") return errorObject;

  for (let index = 0; index < errorObject.length; index++) {
    if (errorMessage !== "") break; //When any one of the option had error we can break this loop to display generic error message about filter token options
    const error = errorObject[index];
    if (error.questionDisplay) errorMessage = error.questionDisplay;
    else if (error.answerDisplay) errorMessage = error.answerDisplay;
    else if (error.operator) errorMessage = error.operator;
  }

  return errorMessage;
};
