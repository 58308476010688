import React, { createContext, ReactElement, useContext } from "react";

export type PanelContextType = {
  toolsPanelOpen: boolean;
  setToolsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setToolsPanelContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  splitPanelOpen: boolean;
  setSplitPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSplitPanelContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

const PanelContext = createContext<PanelContextType | undefined>(undefined);

const usePanelContext = () => useContext(PanelContext);

interface Props {
  children: ReactElement;
  panelContext: PanelContextType;
}
function PanelContextProvider(props: Props): ReactElement {
  return (
    <PanelContext.Provider value={props.panelContext!}>
      {props.children}
    </PanelContext.Provider>
  );
}

export { PanelContextProvider, usePanelContext };
