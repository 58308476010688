import React, { useState, useEffect } from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { useFormik } from "formik";
import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { UseMutationResult } from "@tanstack/react-query";
import { CreateTagMutation, UpdateTagMutation } from "../../api/API";
import CloudScapeSplitPanel from "@cloudscape-design/components/split-panel";
import CloudScapeForm from "@cloudscape-design/components/form";
import CloudScapeSpaceBetween from "@cloudscape-design/components/space-between";
import CloudScapeButton from "@cloudscape-design/components/button";
import CloudScapeFormField from "@cloudscape-design/components/form-field";
import CloudScapeInput from "@cloudscape-design/components/input";
import CloudScapeTextarea from "@cloudscape-design/components/textarea";
import {
  OrangeButton,
  CustomCheckBoxBtn,
} from "../../components/Buttons/CustomizedButtons";
import { PanelContextType } from "../../context/PanelContextProvider";
import {
  FORM_MODE,
  LOADING_SPINNER,
  RECORD_ACTIVE_VALUE,
  RECORD_IN_ACTIVE_VALUE,
  TAGS_FORM_STRINGS,
  TAG_FIELDS,
  RECORD_STATE,
} from "../../constants/constants";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useCreateTag } from "../../api/tags/createTagAPI";
import { useUpdateTag } from "../../api/tags/updateTagAPI";
import { TagsTableRecord } from "./TagsPageTable";
import { TagFormValidationSchema } from "./TagsPageFormValidationSchema";
import { decodeString, encodeString } from "src/utils/CommonUtils";

export const TAG_CANCEL_BUTTON_DATA_TEST_ID = "cancel-btn";
export const TAG_SAVE_BUTTON_DATA_TEST_ID = "save-tag-btn";

// The data structure required to feed CloudScape form components
export interface TagFormValues {
  tagName: string;
  description: string;
  isActive: boolean;
  lastUpdated?: string; // Added this unused fields to match upstream types
  updatedBy?: string;
  tagId?: string;
}

// Initial form state when the user clicks on the "create new tag" button
export const formInitialState: TagFormValues = {
  tagName: "",
  description: "",
  isActive: RECORD_IN_ACTIVE_VALUE, // By default, the active field is inactive (false) until the user activates it by checking the checkbox
};

const TAG_MUTATION_MAP: Record<
  FORM_MODE,
  () => UseMutationResult<
    GraphQLResult<GraphQLQuery<CreateTagMutation | UpdateTagMutation>>,
    unknown,
    any,
    unknown
  >
> = {
  create: useCreateTag,
  edit: useUpdateTag,
};

export interface TagsPageFormProps extends WithBundleProps {
  panelContext: PanelContextType;
  formMode: FORM_MODE;
  tagNamesSet: Set<string>;
  tagRecord?: TagsTableRecord;
}

export const TagsPageForm = withBundle("pages.TagsPageForm")(
  (props: TagsPageFormProps) => {
    const { setSplitPanelOpen, setSplitPanelContent } = props.panelContext!;
    const { mutate, isIdle, isLoading, isSuccess } =
      TAG_MUTATION_MAP[props.formMode]();
    const tagFormState = useFormik({
      initialValues: { ...formInitialState },
      validationSchema: TagFormValidationSchema(
        props.bundle,
        props.formMode === FORM_MODE.EDIT ? props.tagRecord!.tagName : "",
        props.tagNamesSet!,
        props.formMode
      ),
      onSubmit: () => {
        //API Mutation hook will be called when form fields had no error's
        mutate({
          tagName: encodeString(tagFormState.values.tagName.trimEnd()),
          description: encodeString(tagFormState.values.description.trimEnd()),
          isActive: tagFormState.values.isActive,
          ...(props.formMode === FORM_MODE.EDIT
            ? { tagId: props.tagRecord!.tagId }
            : {}), //In case of edit mode to update existing tag record data we are sending tagId which is it's primary key
        });
      },
    });
    const [formStateLoaded, setFormStateLoaded] = useState(false);

    useEffect(() => {
      if (isIdle === false && isLoading === false && isSuccess === true) {
        setSplitPanelContent(null);
        setSplitPanelOpen(false);
      }
    }, [isIdle, isLoading]);

    useEffect(() => {
      setFormStateLoaded(false);
      if (props.formMode === FORM_MODE.EDIT) {
        //need to convert table data type to form editable type interface TagFormValues by removing some unwanted fields so we can feed cloudscape components
        const { updatedBy, lastUpdated, ...requiredFields } = props.tagRecord!;

        tagFormState.resetForm({
          values: {
            tagName: requiredFields.tagName,
            description:
              requiredFields.description === null
                ? ""
                : requiredFields.description,
            isActive:
              requiredFields.isActive.toLowerCase() === RECORD_STATE.ACTIVE
                ? RECORD_ACTIVE_VALUE
                : RECORD_IN_ACTIVE_VALUE,
          },
        });
      } else {
        //When form is in edit state and user clicks create new tag button in full page header to create brand new tag so in that case form needs to be reset to formInitialState
        tagFormState.resetForm({ values: { ...formInitialState } });
      }
      setFormStateLoaded(true);
    }, [props.tagRecord, props.formMode]);

    return (
      <CloudScapeSplitPanel
        header={
          props.formMode === FORM_MODE.EDIT
            ? props.bundle.getMessage(TAGS_FORM_STRINGS.EDIT_TAG)
            : props.bundle.getMessage(TAGS_FORM_STRINGS.NEW_TAG)
        }
        hidePreferencesButton
        closeBehavior="hide"
      >
        {formStateLoaded ? (
          <form onSubmit={tagFormState!.handleSubmit}>
            <CloudScapeForm
              actions={
                <CloudScapeSpaceBetween direction="horizontal" size="xs">
                  <CloudScapeButton
                    formAction="none"
                    variant="link"
                    onClick={() => {
                      setSplitPanelContent(null);
                      setSplitPanelOpen(false);
                    }}
                    data-testid={TAG_CANCEL_BUTTON_DATA_TEST_ID}
                  >
                    {props.bundle.getMessage(TAGS_FORM_STRINGS.CANCEL)}
                  </CloudScapeButton>
                  <OrangeButton
                    variant="primary"
                    loading={isLoading} //Showing loading spinner indicator when valid form data mutation is in progress
                    formAction="submit"
                    data-testid={TAG_SAVE_BUTTON_DATA_TEST_ID}
                  >
                    {props.bundle.getMessage(TAGS_FORM_STRINGS.SAVE_TAG)}
                  </OrangeButton>
                </CloudScapeSpaceBetween>
              }
            >
              <CloudScapeSpaceBetween size="l" direction="vertical">
                <CloudScapeFormField
                  constraintText={props.bundle.getMessage(
                    TAGS_FORM_STRINGS.TAG_NAME_CONSTRAINT
                  )}
                  label={props.bundle.getMessage(TAGS_FORM_STRINGS.TAG_NAME)}
                  errorText={
                    tagFormState.touched.tagName &&
                    tagFormState.errors.tagName &&
                    `${tagFormState.errors.tagName}`
                  }
                  stretch={true}
                  data-test-id={TAGS_FORM_STRINGS.TAG_NAME}
                >
                  <CloudScapeInput
                    value={decodeString(tagFormState.values.tagName)}
                    onChange={({ detail }) => {
                      tagFormState.setFieldValue(
                        TAG_FIELDS.TAG_NAME,
                        detail.value.trimStart()
                      );
                    }}
                  />
                </CloudScapeFormField>

                <CloudScapeFormField
                  label={props.bundle.getMessage(TAGS_FORM_STRINGS.DESCRIPTION)}
                  errorText={
                    tagFormState.touched.description &&
                    tagFormState.errors.description &&
                    `${tagFormState.errors.description}`
                  }
                  stretch={true}
                  data-test-id={TAGS_FORM_STRINGS.DESCRIPTION}
                >
                  <CloudScapeTextarea
                    value={tagFormState.values.description}
                    onChange={({ detail }) => {
                      tagFormState.setFieldValue(
                        TAG_FIELDS.DESCRIPTION,
                        detail.value.trimStart()
                      );
                    }}
                  />
                </CloudScapeFormField>
              </CloudScapeSpaceBetween>

              <CloudScapeSpaceBetween size="l" direction="vertical">
                <CloudScapeFormField data-test-id={TAGS_FORM_STRINGS.IS_ACTIVE}>
                  <CustomCheckBoxBtn
                    checked={tagFormState.values.isActive}
                    onChange={({ detail }) => {
                      tagFormState.setFieldValue(
                        TAG_FIELDS.IS_ACTIVE,
                        detail.checked
                      );
                    }}
                  >
                    {props.bundle.getMessage(TAGS_FORM_STRINGS.IS_ACTIVE)}
                  </CustomCheckBoxBtn>
                </CloudScapeFormField>
              </CloudScapeSpaceBetween>
            </CloudScapeForm>
          </form>
        ) : (
          <LoadingSpinner data-testid={LOADING_SPINNER} />
        )}
      </CloudScapeSplitPanel>
    );
  }
);
