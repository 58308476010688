import React from "react";
import { MessageBundle } from "@amzn/arb-tools";
import CloudScapeButton from "@cloudscape-design/components/button";
import {
  TASK_FIELDS,
  TASK_TABLE_COLUMN_HEADINGS,
  FORM_MODE,
} from "../../constants/constants";
import { FormHandlerType } from "../../components/Header/FullPageHeader";
import { TasksTableRecord } from "./TasksPageTable";

export const TASKS_COLUMN_FIELDS_MAP: Map<
  TASK_FIELDS,
  TASK_TABLE_COLUMN_HEADINGS
> = new Map([
  [TASK_FIELDS.TASK_NAME, TASK_TABLE_COLUMN_HEADINGS.TASK_NAME_COLUMN_HEADING],
  [TASK_FIELDS.SUMMARY, TASK_TABLE_COLUMN_HEADINGS.SUMMARY],
  [TASK_FIELDS.DESCRIPTION, TASK_TABLE_COLUMN_HEADINGS.DESCRIPTION],
  [TASK_FIELDS.SEVERITY, TASK_TABLE_COLUMN_HEADINGS.SEVERITY],
  [TASK_FIELDS.DUE_DATE, TASK_TABLE_COLUMN_HEADINGS.DUE_DATE],
  [TASK_FIELDS.TAG_NAMES, TASK_TABLE_COLUMN_HEADINGS.TAG_NAMES],
  [TASK_FIELDS.IS_ACTIVE, TASK_TABLE_COLUMN_HEADINGS.IS_ACTIVE],
  [TASK_FIELDS.ISSUE_TITLE, TASK_TABLE_COLUMN_HEADINGS.ISSUE_TITLE],
  [TASK_FIELDS.LAST_UPDATED, TASK_TABLE_COLUMN_HEADINGS.LAST_UPDATED],
  [TASK_FIELDS.UPDATED_BY, TASK_TABLE_COLUMN_HEADINGS.UPDATED_BY],
]);

const ROW_HEADER = TASK_FIELDS.TASK_NAME;

export const getTasksPageTableColumnDefinition = (
  bundle: MessageBundle,
  formHandler: FormHandlerType
) => {
  return Array.from(TASKS_COLUMN_FIELDS_MAP.entries()).map(([key, value]) => {
    if (key === ROW_HEADER) {
      return {
        id: key,
        header: bundle.getMessage(value),
        cell: (item: TasksTableRecord) => {
          return (
            <CloudScapeButton
              variant="inline-link"
              onClick={() => {
                formHandler(FORM_MODE.EDIT, item);
              }}
              data-testid={item.taskName} //which is unique
            >
              {item[key]}
            </CloudScapeButton>
          );
        },
        sortingField: key,
        isRowHeader: true,
      };
    }
    return {
      id: key,
      header: bundle.getMessage(value),
      cell: (item: TasksTableRecord) => item[key],
      sortingField: key,
    };
  });
};
