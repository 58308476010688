import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { createSecurityTeam } from "../graphql/mutations";
import { CreateSecurityTeamMutation, CreateSecurityTeamRequest } from "../API";
import { useUserContext } from "../../context/UserProvider";
import { retryConfig } from "../APIUtils";
import {
  API_KEYS,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

export const useCreateSecurityTeam = () => {
  const flashbarContext = useFlashBarContext();
  const { alias } = useUserContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [API_KEYS.CREATE_SECURITY_TEAM],
    mutationFn: async (recordData: CreateSecurityTeamRequest) =>
      await API.graphql<GraphQLQuery<CreateSecurityTeamMutation>>(
        graphqlOperation(createSecurityTeam, {
          input: {
            ...recordData,
            updatedBy: alias,
          },
        })
      ),
    ...retryConfig,
    //After trying mutation as configured in retryConfig we will perform necessary steps accordingly
    onSuccess: () => {
      //Display Mutation status using FlashBar message in security list view page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.SECURITY_TEAMS);

      //Display success FlashBar Message for creation successful
      flashbarContext.setFlashBarMessage(
        FLASH_BAR_MESSAGES.SECURITY_TEAM_CREATION_SUCCESSFUL
      );

      //invalidating existing API data up on performing successful mutation to fetch latest data
      queryClient.invalidateQueries({
        queryKey: [API_KEYS.GET_SECURITY_TEAMS],
      });
    },
    onError: () => {
      //Display Mutation status using FlashBar message in Security Config page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.SECURITY_TEAM_CONFIG);

      //Display error FlashBar Message for creation failed
      flashbarContext.setFlashBarMessage(
        FLASH_BAR_MESSAGES.SECURITY_TEAM_CREATION_FAILED
      );

      //invalidating existing API data up on performing failed mutation to fetch latest data
      queryClient.invalidateQueries({
        queryKey: [API_KEYS.GET_SECURITY_TEAMS],
      });
    },
  });
};
