import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { createTask } from "../graphql/mutations";
import { CreateTaskMutation, CreateTaskRequest } from "../API";
import { useUserContext } from "../../context/UserProvider";
import { retryConfig } from "../APIUtils";
import {
  API_KEYS,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

export const useCreateTask = () => {
  const flashbarContext = useFlashBarContext();
  const { alias } = useUserContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [API_KEYS.CREATE_TASK],
    mutationFn: async (recordData: CreateTaskRequest) =>
      await API.graphql<GraphQLQuery<CreateTaskMutation>>(
        graphqlOperation(createTask, {
          input: {
            ...recordData,
            updatedBy: alias,
          },
        })
      ),
    ...retryConfig,
    //After trying mutation as configured in retryConfig we will perform necessary steps accordingly
    onSuccess: () => {
      //Display Mutation status using FlashBar message in tasks page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.TASKS);

      //Display success FlashBar Message for creation successful
      flashbarContext.setFlashBarMessage(
        FLASH_BAR_MESSAGES.TASK_CREATION_SUCCESSFUL
      );

      //invalidating existing API data up on performing successful mutation to fetch latest data
      queryClient.invalidateQueries({ queryKey: [API_KEYS.GET_TASKS] });
    },
    onError: () => {
      //Display Mutation status using FlashBar message in tasks page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.TASKS);

      //Display error FlashBar Message for creation failed
      flashbarContext.setFlashBarMessage(
        FLASH_BAR_MESSAGES.TASK_CREATION_FAILED
      );

      //invalidating existing API data up on performing failed mutation to fetch latest data
      queryClient.invalidateQueries({ queryKey: [API_KEYS.GET_TASKS] });
    },
  });
};
