import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import CloudScapeAppLayout from "@cloudscape-design/components/app-layout";
import enMessages from "@cloudscape-design/components/i18n/messages/all.en";
import { I18nProvider } from "@cloudscape-design/components/i18n";
import TopNavBar from "../components/TopNavBar/TopNavBar";
import SideNavBar from "../components/SideNavBar/SideNavBar";
import { FlashBarNotifications } from "../components/Notifications/Notifications";
import { BreadCrumbs } from "../components/BreadCrumbs/BreadCrumbs";
import { PanelContextProvider } from "../context/PanelContextProvider";
import { FlashBarContextProvider } from "../context/FlashBarContextProvider";
import { BreadCrumbContextProvider } from "../context/BreadCrumbContextProvider";
import { PAGE_TITLE } from "../constants/constants";

function App() {
  const [navOpen, setNavOpen] = useState(false);
  const [toolsPanelOpen, setToolsPanelOpen] = useState<boolean>(false);
  const [toolsPanelContent, setToolsPanelContent] =
    useState<React.ReactNode>(null);
  const [splitPanelOpen, setSplitPanelOpen] = useState<boolean>(false);
  const [splitPanelContent, setSplitPanelContent] =
    useState<React.ReactNode>(null);
  const location = useLocation();

  return (
    <I18nProvider
      messages={[enMessages]}
      locale="en"
      data-testid="localization-provider"
    >
      <TopNavBar />
      <PanelContextProvider
        panelContext={{
          toolsPanelOpen,
          setToolsPanelOpen,
          setToolsPanelContent,
          splitPanelOpen,
          setSplitPanelOpen,
          setSplitPanelContent,
        }}
      >
        <FlashBarContextProvider>
          <BreadCrumbContextProvider>
            <CloudScapeAppLayout
              //Navigation Props
              navigationOpen={navOpen}
              navigation={<SideNavBar />}
              onNavigationChange={({ detail }) => setNavOpen(detail.open)}
              //Page Header Content
              headerSelector="#h"
              notifications={<FlashBarNotifications />}
              breadcrumbs={<BreadCrumbs />}
              stickyNotifications
              //Page Content
              contentType="table"
              content={<Outlet />}
              //Tools Panel
              toolsOpen={toolsPanelOpen}
              onToolsChange={() => {
                setToolsPanelOpen((prevState) => !prevState);
                if (!toolsPanelOpen) {
                  setToolsPanelContent(null);
                }
              }}
              tools={toolsPanelContent}
              toolsHide={!toolsPanelOpen}
              //Split Panel
              splitPanelOpen={splitPanelOpen}
              onSplitPanelToggle={() => {
                setSplitPanelOpen((prevState) => !prevState);
                setSplitPanelContent(null);
              }}
              splitPanel={splitPanelContent}
              splitPanelPreferences={{
                position:
                  location.pathname.substring(1) === PAGE_TITLE.TASKS
                    ? "bottom"
                    : "side",
              }}
              //data id for component unit testing
              data-testid="applayout-component"
            />
          </BreadCrumbContextProvider>
        </FlashBarContextProvider>
      </PanelContextProvider>
    </I18nProvider>
  );
}
export default App;
