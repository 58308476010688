import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { updateSecurityTeam } from "../graphql/mutations";
import { UpdateSecurityTeamMutation, UpdateSecurityTeamRequest } from "../API";
import { useUserContext } from "../../context/UserProvider";
import { retryConfig } from "../APIUtils";
import {
  API_KEYS,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

export const useUpdateSecurityTeam = () => {
  const { alias } = useUserContext();
  const flashbarContext = useFlashBarContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [API_KEYS.UPDATE_SECURITY_TEAM],
    mutationFn: async (recordData: UpdateSecurityTeamRequest) =>
      await API.graphql<GraphQLQuery<UpdateSecurityTeamMutation>>(
        graphqlOperation(updateSecurityTeam, {
          input: {
            ...recordData,
            updatedBy: alias,
          },
        })
      ),
    ...retryConfig,
    //After trying mutation as configured in retryConfig we will perform necessary steps accordingly
    onSuccess: () => {
      //Display Mutation status using FlashBar message in security list view page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.SECURITY_TEAMS);

      //Display success FlashBar Message for update successful
      flashbarContext.setFlashBarMessage(
        FLASH_BAR_MESSAGES.SECURITY_TEAM_UPDATE_SUCCESSFUL
      );

      //invalidating existing API data up on performing successful mutation to fetch latest data
      queryClient.invalidateQueries({
        queryKey: [API_KEYS.GET_SECURITY_TEAMS],
      });

      //Invalidating getSecurityTeamById API to prefil the config form to fetch latest data
      queryClient.invalidateQueries({
        queryKey: [API_KEYS.GET_SECURITY_TEAM],
      });
    },
    onError: () => {
      //Display Mutation status using FlashBar message in Security Config page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.SECURITY_TEAM_CONFIG);

      //Display error FlashBar Message for update failed
      flashbarContext.setFlashBarMessage(
        FLASH_BAR_MESSAGES.SECURITY_TEAM_UPDATE_FAILED
      );

      //invalidating existing API data up on performing failed mutation to fetch latest data
      queryClient.invalidateQueries({
        queryKey: [API_KEYS.GET_SECURITY_TEAMS],
      });

      //Invalidating getSecurityTeamById API to prefil the config form to fetch latest data
      queryClient.invalidateQueries({
        queryKey: [API_KEYS.GET_SECURITY_TEAM],
      });
    },
  });
};
