import React from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeFormField from "@cloudscape-design/components/form-field";
import CloudScapeContainer from "@cloudscape-design/components/container";
import CloudScapeHeader from "@cloudscape-design/components/header";
import CloudScapeInput from "@cloudscape-design/components/input";
import CloudScapeSpaceBetween from "@cloudscape-design/components/space-between";
import CloudScapeAttributeEditor from "@cloudscape-design/components/attribute-editor";
import CloudScapeSelect from "@cloudscape-design/components/select";
import CloudScapeToggle from "@cloudscape-design/components/toggle";
import CloudScapeBox from "@cloudscape-design/components/box";
import {
  SecurityTeamFormStrings,
  SecurityTeamFormFields,
  SERVICE_NOW_ROLE_LIST,
} from "../../../constants/constants";
import { LdapWithSnowRoleFormValues } from "../SecurityTeamConfigPage";
import { decodeString } from "../../../utils/CommonUtils";

export const newPermissionGroupDetails: Readonly<LdapWithSnowRoleFormValues> =
  Object.freeze({
    teamLdapGroup: "",
    serviceNowRole: null,
  });

interface PermissionGroupFields {
  key: string;
  value: string;
}

interface SecurityTeamBasicInformationProps extends WithBundleProps {
  securityTeamConfigFormState: any; //this is internal for useFormik
}

export const SecurityTeamBasicInformation = withBundle(
  "pages.SecurityTeamBasicInformation"
)((props: SecurityTeamBasicInformationProps) => {
  return (
    <CloudScapeContainer
      header={
        <CloudScapeHeader variant="h2">
          {props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_CONTAINER_HEADER
          )}
        </CloudScapeHeader>
      }
      data-testid={
        SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_CONTAINER_HEADER
      }
    >
      <CloudScapeSpaceBetween direction="vertical" size="l">
        <CloudScapeFormField
          label={props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_SECURITY_TEAM_NAME_LABEL
          )}
          constraintText={props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_SECURITY_TEAM_NAME_CONSTRAINT_TEXT
          )}
          errorText={
            props.securityTeamConfigFormState.touched[
              SecurityTeamFormFields.SECURITY_TEAM_NAME
            ] &&
            props.securityTeamConfigFormState.errors[
              SecurityTeamFormFields.SECURITY_TEAM_NAME
            ] &&
            `${
              props.securityTeamConfigFormState.errors[
                SecurityTeamFormFields.SECURITY_TEAM_NAME
              ]
            }`
          }
          data-testid={
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_SECURITY_TEAM_NAME_LABEL
          }
        >
          <CloudScapeInput
            value={decodeString(
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.SECURITY_TEAM_NAME
              ]!
            )}
            onChange={({ detail }) => {
              props.securityTeamConfigFormState.setFieldValue(
                SecurityTeamFormFields.SECURITY_TEAM_NAME,
                decodeString(detail.value.trimStart())
              );
            }}
            data-testid={
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_SECURITY_TEAM_NAME_LABEL
            }
          />
        </CloudScapeFormField>
        <CloudScapeFormField
          label={props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_LABEL
          )}
          description={props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_DESCRIPTION
          )}
          data-testid={
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_LABEL
          }
        >
          <CloudScapeAttributeEditor
            onAddButtonClick={() => {
              //User doesnot have LDAP Groups Selected so far
              if (
                props.securityTeamConfigFormState.values[
                  SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                ].length === 0
              ) {
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST,
                  [newPermissionGroupDetails]
                );
              } else {
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST,
                  [
                    ...props.securityTeamConfigFormState.values[
                      SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                    ],
                    newPermissionGroupDetails,
                  ]
                );
              }
            }}
            onRemoveButtonClick={({ detail: { itemIndex } }) => {
              const tmpItems = [
                ...props.securityTeamConfigFormState.values[
                  SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                ],
              ];
              tmpItems.splice(itemIndex, 1);
              props.securityTeamConfigFormState.setFieldValue(
                SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST,
                tmpItems
              );
            }}
            items={
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
              ]
            }
            addButtonText={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_ADD_PERMISSION_GROUP_BTN_TEXT
            )}
            removeButtonText={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_REMOVE_BTN_TEXT
            )}
            definition={[
              {
                label: props.bundle.getMessage(
                  SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_LDAP_GROUP_LABEL
                ),
                control: (item: PermissionGroupFields, itemIndex: number) => (
                  <CloudScapeFormField
                    errorText={
                      props.securityTeamConfigFormState.touched[
                        SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                      ] &&
                      props.securityTeamConfigFormState.touched[
                        SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                      ][itemIndex] &&
                      props.securityTeamConfigFormState.errors[
                        SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                      ] &&
                      props.securityTeamConfigFormState.errors[
                        SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                      ][itemIndex] &&
                      props.securityTeamConfigFormState.errors[
                        SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                      ][itemIndex].teamLdapGroup &&
                      `${
                        props.securityTeamConfigFormState.errors[
                          SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                        ][itemIndex].teamLdapGroup
                      }`
                    }
                    data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_LDAP_GROUP_LABEL}-${itemIndex}`}
                  >
                    <CloudScapeInput
                      value={
                        props.securityTeamConfigFormState.values[
                          SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                        ][itemIndex].teamLdapGroup
                      }
                      onChange={({ detail }) => {
                        props.securityTeamConfigFormState.setFieldValue(
                          SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST,
                          props.securityTeamConfigFormState.values[
                            SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                          ].map(
                            (
                              ldapWithSnowRoleObject: LdapWithSnowRoleFormValues,
                              index: number
                            ) => {
                              if (itemIndex === index) {
                                return {
                                  ...ldapWithSnowRoleObject,
                                  teamLdapGroup: detail.value.trimStart(),
                                };
                              } else {
                                return ldapWithSnowRoleObject;
                              }
                            }
                          )
                        );
                      }}
                      data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_LDAP_GROUP_LABEL}-${itemIndex}`}
                    />
                  </CloudScapeFormField>
                ),
              },
              {
                label: props.bundle.getMessage(
                  SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_SERVICE_NOW_ROLE_MULTISELCT_LABEL
                ),
                control: (item: PermissionGroupFields, itemIndex: number) => (
                  <CloudScapeFormField
                    errorText={
                      props.securityTeamConfigFormState.touched[
                        SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                      ] &&
                      props.securityTeamConfigFormState.touched[
                        SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                      ][itemIndex] &&
                      props.securityTeamConfigFormState.errors[
                        SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                      ] &&
                      props.securityTeamConfigFormState.errors[
                        SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                      ][itemIndex] &&
                      props.securityTeamConfigFormState.errors[
                        SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                      ][itemIndex].serviceNowRole &&
                      `${
                        props.securityTeamConfigFormState.errors[
                          SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                        ][itemIndex].serviceNowRole
                      }`
                    }
                    data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_SERVICE_NOW_ROLE_MULTISELCT_LABEL}-${itemIndex}`}
                  >
                    <CloudScapeSelect
                      options={SERVICE_NOW_ROLE_LIST.map((serviceNowRole) => {
                        return {
                          label: props.bundle.getMessage(
                            serviceNowRole.toLowerCase()
                          ), //Retrieving this value from puff.json for internalization
                          value: serviceNowRole,
                        };
                      })}
                      selectedOption={
                        props.securityTeamConfigFormState.values[
                          SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                        ][itemIndex].serviceNowRole
                          ? {
                              label: props.bundle.getMessage(
                                props.securityTeamConfigFormState.values[
                                  SecurityTeamFormFields
                                    .LDAP_WITH_SNOW_ROLE_LIST
                                ][itemIndex].serviceNowRole.value.toLowerCase()
                              ),
                              value:
                                props.securityTeamConfigFormState.values[
                                  SecurityTeamFormFields
                                    .LDAP_WITH_SNOW_ROLE_LIST
                                ][itemIndex].serviceNowRole.value,
                            }
                          : null
                      }
                      onChange={({ detail }) => {
                        props.securityTeamConfigFormState.setFieldValue(
                          SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST,
                          props.securityTeamConfigFormState.values[
                            SecurityTeamFormFields.LDAP_WITH_SNOW_ROLE_LIST
                          ].map(
                            (
                              ldapWithSnowRoleObject: LdapWithSnowRoleFormValues,
                              index: number
                            ) => {
                              if (itemIndex === index) {
                                return {
                                  ...ldapWithSnowRoleObject,
                                  serviceNowRole: detail.selectedOption,
                                };
                              } else {
                                return ldapWithSnowRoleObject;
                              }
                            }
                          )
                        );
                      }}
                      placeholder={props.bundle.getMessage(
                        SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_SERVICE_NOW_ROLE_MULTISELCT_PLACEHOLDER_TEXT
                      )}
                      data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_SERVICE_NOW_ROLE_MULTISELCT_LABEL}-${itemIndex}`}
                    />
                  </CloudScapeFormField>
                ),
              },
            ]}
            empty={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_EMPTY_MESSAGE
            )}
            data-testid={
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_LABEL
            }
          />
        </CloudScapeFormField>
        <CloudScapeFormField
          data-testid={
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TPS_CONFIG_TOGGLE_BTN_LABEL
          }
        >
          <CloudScapeToggle
            checked={
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DISABLE_TPS_CONFIG
              ]
            }
            onChange={({ detail }) => {
              props.securityTeamConfigFormState.setFieldValue(
                SecurityTeamFormFields.DISABLE_TPS_CONFIG,
                detail.checked
              );
            }}
            description={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TPS_CONFIG_TOGGLE_BTN_DESCRIPTION
            )}
            data-testid={
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TPS_CONFIG_TOGGLE_BTN_LABEL
            }
          >
            <CloudScapeBox variant="strong">
              {props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TPS_CONFIG_TOGGLE_BTN_LABEL
              )}
            </CloudScapeBox>
          </CloudScapeToggle>
        </CloudScapeFormField>
        <CloudScapeFormField
          data-testid={
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_3_AUTOMATION_TOGGLE_BTN_LABEL
          }
        >
          <CloudScapeToggle
            checked={
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DISABLE_TIER_3_AUTOMATION
              ]
            }
            onChange={({ detail }) => {
              props.securityTeamConfigFormState.setFieldValue(
                SecurityTeamFormFields.DISABLE_TIER_3_AUTOMATION,
                detail.checked
              );
            }}
            description={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_3_AUTOMATION_TOGGLE_BTN_DESCRIPTION
            )}
            data-testid={
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_3_AUTOMATION_TOGGLE_BTN_LABEL
            }
          >
            <CloudScapeBox variant="strong">
              {props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_3_AUTOMATION_TOGGLE_BTN_LABEL
              )}
            </CloudScapeBox>
          </CloudScapeToggle>
        </CloudScapeFormField>
        <CloudScapeFormField
          data-testid={
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_4_AUTOMATION_TOGGLE_BTN_LABEL
          }
        >
          <CloudScapeToggle
            checked={
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DISABLE_TIER_4_AUTOMATION
              ]
            }
            onChange={({ detail }) => {
              props.securityTeamConfigFormState.setFieldValue(
                SecurityTeamFormFields.DISABLE_TIER_4_AUTOMATION,
                detail.checked
              );
            }}
            description={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_4_AUTOMATION_TOGGLE_BTN_DESCRIPTION
            )}
            data-testid={
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_4_AUTOMATION_TOGGLE_BTN_LABEL
            }
          >
            <CloudScapeBox variant="strong">
              {props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_4_AUTOMATION_TOGGLE_BTN_LABEL
              )}
            </CloudScapeBox>
          </CloudScapeToggle>
        </CloudScapeFormField>
        <CloudScapeFormField
          data-testid={
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_ARIES_WORKFLOW_TOGGLE_BTN_LABEL
          }
        >
          <CloudScapeToggle
            checked={
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DISABLE_ARIES_WORKFLOW
              ]
            }
            onChange={({ detail }) => {
              props.securityTeamConfigFormState.setFieldValue(
                SecurityTeamFormFields.DISABLE_ARIES_WORKFLOW,
                detail.checked
              );
            }}
            description={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_ARIES_WORKFLOW_TOGGLE_BTN_DESCRIPTION
            )}
            data-testid={
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_ARIES_WORKFLOW_TOGGLE_BTN_LABEL
            }
          >
            <CloudScapeBox variant="strong">
              {props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_DISABLE_ARIES_WORKFLOW_TOGGLE_BTN_LABEL
              )}
            </CloudScapeBox>
          </CloudScapeToggle>
        </CloudScapeFormField>
        <CloudScapeFormField
          data-testid={
            SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_IS_ACTIVE_AUTOMATION_TOGGLE_BTN_LABEL
          }
        >
          <CloudScapeToggle
            checked={
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.IS_ACTIVE
              ]
            }
            onChange={({ detail }) => {
              props.securityTeamConfigFormState.setFieldValue(
                SecurityTeamFormFields.IS_ACTIVE,
                detail.checked
              );
            }}
            description={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_IS_ACTIVE_AUTOMATION_TOGGLE_BTN_DESCRIPTION
            )}
            data-testid={
              SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_IS_ACTIVE_AUTOMATION_TOGGLE_BTN_LABEL
            }
          >
            <CloudScapeBox variant="strong">
              {props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_BASIC_INFORMATION_IS_ACTIVE_AUTOMATION_TOGGLE_BTN_LABEL
              )}
            </CloudScapeBox>
          </CloudScapeToggle>
        </CloudScapeFormField>
      </CloudScapeSpaceBetween>
    </CloudScapeContainer>
  );
});
