import { MessageBundle } from "@amzn/arb-tools";
import {
  PROPERTY_FILTER_OPERATORS_LIST,
  SecurityTeamFields,
  SecurityTeamTableColumnHeadings,
} from "../../constants/constants";

export const SecurityTeamsFilterFieldsMaps: Map<
  SecurityTeamFields,
  SecurityTeamTableColumnHeadings
> = new Map([
  [
    SecurityTeamFields.SECURITY_TEAM_NAME,
    SecurityTeamTableColumnHeadings.SECURITY_TEAM_COLUMN_HEADINGS,
  ],
  [
    SecurityTeamFields.TASK_TAG_NAMES,
    SecurityTeamTableColumnHeadings.TASK_TAG_NAMES,
  ],
  [
    SecurityTeamFields.DISABLE_TIER_3_AUTOMATION,
    SecurityTeamTableColumnHeadings.DISABLE_TIER_3_AUTOMATION,
  ],
  [
    SecurityTeamFields.DISABLE_TIER_4_AUTOMATION,
    SecurityTeamTableColumnHeadings.DISABLE_TIER_4_AUTOMATION,
  ],
  [SecurityTeamFields.IS_ACTIVE, SecurityTeamTableColumnHeadings.IS_ACTIVE],
  [SecurityTeamFields.UPDATED_BY, SecurityTeamTableColumnHeadings.UPDATED_BY],
]);

export const getSecurityTeamsPageFilteringProperties = (
  bundle: MessageBundle
) => {
  return Array.from(SecurityTeamsFilterFieldsMaps.entries()).map(
    ([key, value]) => {
      return {
        propertyLabel: bundle.getMessage(value),
        key: key,
        groupValuesLabel: bundle.getMessage(value),
        operators: PROPERTY_FILTER_OPERATORS_LIST,
      };
    }
  );
};
