import { MessageBundle } from "@amzn/arb-tools";
import {
  SEVERITY,
  FORM_MODE,
  TASKS_FORM_STRINGS,
  RECORD_NAME_SIZE_LIMIT,
  RECORD_NAME_VALID_CHARACTERS_REGEX,
  TextInputFieldValidationType,
} from "../../constants/constants";
import { Select } from "./TasksPageForm";
import { object, string, number, array, boolean } from "yup";

export const highSeverityTest = (severity: Select | null): boolean =>
  severity?.value === SEVERITY.HIGH;

const textFieldCommonValidation = (
  requiredErrorMessage: string,
  specialCharactersErrorMessage: string
) =>
  string()
    .required(requiredErrorMessage)
    .test(
      TextInputFieldValidationType.SPECIAL_CHARACTERS,
      specialCharactersErrorMessage,
      function (value: string) {
        // Check if text input field contains only allowed characters (alphanumeric, _, (, ), &, /, @, ', -, and , characters plus spaces)
        return RECORD_NAME_VALID_CHARACTERS_REGEX.test(value);
      }
    );

const issueFieldsCommonValidation = (
  requiredErrorMessage: string,
  specialCharactersErrorMessage: string
) =>
  string()
    .notRequired()
    .when(TASKS_FORM_STRINGS.SEVERITY, {
      is: (severity: Select) => highSeverityTest(severity),
      then: () =>
        textFieldCommonValidation(
          requiredErrorMessage,
          specialCharactersErrorMessage
        ),
      otherwise: () => string().notRequired(),
    });

const dueDateFieldValidation = (
  requiredErrorMessage: string,
  positiveValueErrorMessage: string,
  integerValueErrorMessage: string
) =>
  number()
    .required(requiredErrorMessage)
    .positive(positiveValueErrorMessage)
    .integer(integerValueErrorMessage);

export const TaskFormValidationSchema = (
  bundle: MessageBundle,
  taskRecordName: string,
  tasksNameSet: Set<string>,
  formMode: FORM_MODE
) => {
  const requiredErrorMessage: string = bundle.getMessage(
    TASKS_FORM_STRINGS.ERROR_MESSAGE_REQUIRED
  );

  const specialCharactersErrorMessage: string = bundle.getMessage(
    TASKS_FORM_STRINGS.ERROR_MESSAGE_TASK_TEXT_INPUT_FIELD_CHARACTER_LIMITATIONS
  );

  return object().shape({
    taskName: textFieldCommonValidation(
      requiredErrorMessage,
      specialCharactersErrorMessage
    )
      .test(
        TextInputFieldValidationType.CHARACTERS_SIZE,
        bundle.getMessage(
          TASKS_FORM_STRINGS.ERROR_MESSAGE_TASK_NAME_SIZE_LIMITATIONS
        ),
        function (value: string) {
          // Check if taskName size is within the limit
          return value.length < RECORD_NAME_SIZE_LIMIT;
        }
      )
      .test(
        TextInputFieldValidationType.UNIQUE,
        bundle.getMessage(TASKS_FORM_STRINGS.ERROR_MESSAGE_TASK_NAME_UNIQUE),
        function (value) {
          //Check if taskName is unique using existing tasksName Set we created from Tasks Get API response
          const isUnique = isTaskNameUnique(
            value,
            taskRecordName,
            tasksNameSet,
            formMode
          );
          return isUnique;
        }
      ),
    summary: textFieldCommonValidation(
      requiredErrorMessage,
      specialCharactersErrorMessage
    ),
    description: textFieldCommonValidation(
      requiredErrorMessage,
      specialCharactersErrorMessage
    ),
    severity: object().required(requiredErrorMessage),
    dueDate: dueDateFieldValidation(
      requiredErrorMessage,
      bundle.getMessage(
        TASKS_FORM_STRINGS.ERROR_MESSAGE_DUE_DATE_POSITIVE_NUMBER
      ),
      bundle.getMessage(TASKS_FORM_STRINGS.ERROR_MESSAGE_DUE_DATE_INTEGER)
    ),
    tagNames: array(),
    issueTitle: issueFieldsCommonValidation(
      requiredErrorMessage,
      specialCharactersErrorMessage
    ),
    issueDescription: issueFieldsCommonValidation(
      requiredErrorMessage,
      specialCharactersErrorMessage
    ),
    whyIssueIsBad: issueFieldsCommonValidation(
      requiredErrorMessage,
      specialCharactersErrorMessage
    ),
    howToFixIt: issueFieldsCommonValidation(
      requiredErrorMessage,
      specialCharactersErrorMessage
    ),
    requestedEvidence: issueFieldsCommonValidation(
      requiredErrorMessage,
      specialCharactersErrorMessage
    ),
    isActive: boolean(),
  });
};

const isTaskNameUnique = (
  value: string,
  taskRecordName: string,
  tasksNameSet: Set<string>,
  formMode: FORM_MODE
) => {
  if (formMode === FORM_MODE.EDIT && value === taskRecordName) return true; //In edit mode user didn't changes the existing task Name in that case it should always return true to avoid unnecessary error

  return !tasksNameSet.has(value.trimEnd());
};
