import React from "react";
import { useNavigate } from "react-router-dom";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeModal from "@cloudscape-design/components/modal";
import CloudScapeBox from "@cloudscape-design/components/box";
import CloudSpaceBetween from "@cloudscape-design/components/space-between";
import CloudScapeButton from "@cloudscape-design/components/button";
import CloudScapeAlert from "@cloudscape-design/components/alert";
import { OrangeButton } from "../Buttons/CustomizedButtons";
import { PAGE_TITLE } from "../../constants/constants";
import { useDeleteTags } from "../../api/tags/deleteTagsAPI";
import { useDeleteTasks } from "../../api/tasks/deleteTasksAPI";
import { useDeleteSecurityTeams } from "../../api/securityTeams/deleteSecurityTeamsAPI";
import { PromptCutTicketType } from "../../utils/ModalUtils";
import { SECURITY_TEAMS_PAGE } from "../../constants/linkConstants";

const DELETE_HOOK_MAP = Object.freeze({
  tags: useDeleteTags,
  tasks: useDeleteTasks,
  security_teams: useDeleteSecurityTeams,
  security_team_config: useDeleteSecurityTeams,
});

export const DELETE_TEXT = "delete";
export const CANCEL_TEXT = "cancel";

export interface DeleteModalProps extends WithBundleProps {
  selectedRecordIds: string[];
  deleteModalOpen: boolean;
  closeModal: () => void;
  page:
    | PAGE_TITLE.TAGS
    | PAGE_TITLE.TASKS
    | PAGE_TITLE.SECURITY_TEAMS
    | PAGE_TITLE.SECURITY_TEAM_CONFIG;
  promptCutTicket: PromptCutTicketType;
}

const isSecurityPage = (page: PAGE_TITLE) =>
  page === PAGE_TITLE.SECURITY_TEAMS ||
  page === PAGE_TITLE.SECURITY_TEAM_CONFIG;

export const DeleteModal = withBundle("components.DeleteModal")(
  (props: DeleteModalProps) => {
    const { mutate, isLoading } = DELETE_HOOK_MAP[`${props.page}`]();
    const navigate = useNavigate();
    const deleteFn = (records: string[]) => {
      if (isSecurityPage(props.page)) {
        props.closeModal();
        props.promptCutTicket(records, () =>
          mutate(records, {
            onSuccess: () => navigate(SECURITY_TEAMS_PAGE.href),
          })
        );
      } else
        mutate(records, {
          onSettled: props.closeModal,
        });
    };

    const footer = (
      <CloudScapeBox float="right">
        <CloudSpaceBetween direction="horizontal" size="xs">
          <CloudScapeButton
            variant="link"
            onClick={props.closeModal}
            disabled={isLoading}
            data-test-id={CANCEL_TEXT}
          >
            {props.bundle.getMessage(CANCEL_TEXT)}
          </CloudScapeButton>
          <OrangeButton
            variant="primary"
            onClick={() => deleteFn(props.selectedRecordIds)}
            loading={isLoading}
            disabled={isLoading}
            data-test-id={DELETE_TEXT}
          >
            {props.bundle.getMessage(DELETE_TEXT)}
          </OrangeButton>
        </CloudSpaceBetween>
      </CloudScapeBox>
    );

    return (
      <CloudScapeModal
        onDismiss={props.closeModal}
        visible={props.deleteModalOpen && props.selectedRecordIds.length > 0}
        footer={footer}
        header={props.bundle.formatMessage(
          `delete_modal_header_context_for_${props.page}`,
          { numRecords: props.selectedRecordIds.length }
        )}
        data-testid={`${props.page}-delete-modal`}
      >
        {props.bundle.formatMessage(`delete_modal_info_content_${props.page}`, {
          numRecords: props.selectedRecordIds.length,
          div: (child: string) => <div key="1">{child}</div>,
          strong: (child: string) => <strong key="1">{child}</strong>,
        })}
        <br />
        <CloudScapeAlert statusIconAriaLabel="Info">
          {props.bundle.formatMessage("delete_modal_info_alert_content", {
            page: props.page,
            div: (child: string) => <div key="1">{child}</div>,
          })}
        </CloudScapeAlert>
      </CloudScapeModal>
    );
  }
);
