import React, { useEffect, useState } from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { useLocation } from "react-router-dom";
import CloudScapeBreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import styled from "styled-components";
import {
  getBreadCrumbText,
  getBreadCrumbHref,
} from "../../utils/BreadCrumbUtils";
import { useBreadCrumbContext } from "../../context/BreadCrumbContextProvider";

const BreadCrumbsGroup = styled(CloudScapeBreadcrumbGroup)`
  a:link {
    text-decoration-line: none !important;
  }
`;

interface BreadCrumbGroup {
  text: string;
  href: string;
}

export const BreadCrumbs = withBundle("components.BreadCrumbs")(
  (props: WithBundleProps) => {
    const location = useLocation();
    const [breadCrumbState, setBreadCrumbState] = useState<BreadCrumbGroup[]>(
      []
    );
    const { breadCrumbDisplayValue } = useBreadCrumbContext();

    useEffect(() => {
      const listOfChildPaths = location.pathname.split("/"); //Pathname will have child routes that are '/' separated
      setBreadCrumbState(
        listOfChildPaths.map((path: string, pathIndex: number) => {
          if (location.pathname === "/" && pathIndex === 1) {
            //As per BreadCrumb Group guidelines https://tiny.amazon.com/iwo94vkb/cloudesibrea last link is always inactive to activate serverice now link in home page added this condition
            //Thus BreadCrumb Group link in home page is always active
            return {
              text: "",
              href: "",
            };
          } else {
            return {
              text: getBreadCrumbText(
                path,
                props.bundle,
                breadCrumbDisplayValue
              ),
              href: getBreadCrumbHref(pathIndex, listOfChildPaths),
            };
          }
        })
      );
    }, [location.pathname, breadCrumbDisplayValue]);

    return <BreadCrumbsGroup items={breadCrumbState} />;
  }
);
