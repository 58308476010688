import React from "react";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import {
  HOME_PAGE,
  SECURITY_TEAMS_PAGE,
  SECURITY_TEAM_CONFIG_PAGE,
  TAGS_PAGE,
  TASKS_PAGE,
} from "../../constants/linkConstants";
import ErrorPage from "../../pages/errorPage/ErrorPage";
import App from "../../components/App";
import { TagsPageTable } from "../../pages/tagsPage/TagsPageTable";
import { TasksPageTable } from "../../pages/tasksPage/TasksPageTable";
import { SecurityTeamsPageTable } from "../../pages/securityTeamsPage/SecurityTeamsPageTable";
import { SecurityTeamConfigPage } from "../../pages/securityTeamConfigPage/SecurityTeamConfigPage";
import { DEEP_LINK } from "src/constants/constants";

export function getRoutes(): RouteObject[] {
  const routes = [
    {
      path: SECURITY_TEAM_CONFIG_PAGE.href,
      element: <SecurityTeamConfigPage />,
    },
    {
      path: SECURITY_TEAMS_PAGE.href,
      element: <SecurityTeamsPageTable />,
    },
    {
      path: TAGS_PAGE.href,
      element: <TagsPageTable />,
    },
    {
      path: TASKS_PAGE.href,
      element: <TasksPageTable />,
    },
  ];

  return routes;
}

export default function PortalRouter() {
  const temp = window.localStorage.getItem(DEEP_LINK);
  if (temp) {
    window.localStorage.removeItem(DEEP_LINK);
    window.location.href = temp;
  }

  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: HOME_PAGE.href,
          element: <App />,
          errorElement: <ErrorPage />,
          children: getRoutes(),
        },
      ])}
    />
  );
}
