/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateTagRequest = {
  tagName: string;
  description?: string | null;
  isActive?: boolean | null;
  updatedBy?: string | null;
  lastUpdated?: number | null;
};

export type Tag = {
  __typename: "Tag";
  intakeId?: Array<string | null> | null;
  tagName?: string | null;
  securityTeam?: string | null;
  description?: string | null;
  isActive?: boolean | null;
  comments?: string | null;
  lastUpdated?: number | null;
  updatedBy?: string | null;
  tagId?: string | null;
  tier?: Tier | null;
};

// ########################
// Input Types      #
// ########################
export enum Tier {
  tier_1 = "tier_1",
  tier_2 = "tier_2",
  tier_3 = "tier_3",
  tier_4 = "tier_4",
  defaultTier = "defaultTier",
  NA = "NA",
}

export type UpdateTagRequest = {
  tagId: string;
  intakeId?: Array<string | null> | null;
  tagName?: string | null;
  securityTeam?: string | null;
  description?: string | null;
  isActive?: boolean | null;
  comments?: string | null;
  lastUpdated?: number | null;
  updatedBy?: string | null;
  tier?: Tier | null;
};

export type DeleteTagsRequest = {
  tagIds?: Array<string | null> | null;
};

export type CreateTaskRequest = {
  taskName: string;
  description?: string | null;
  howToFixIt?: string | null;
  issueDescription?: string | null;
  issueTitle?: string | null;
  requestedEvidence?: string | null;
  severity?: string | null;
  summary?: string | null;
  tagNames?: Array<string | null> | null;
  whyIssueIsBad?: string | null;
  updatedBy?: string | null;
  lastUpdated?: number | null;
  dueDate?: number | null;
  isActive?: boolean | null;
};

export type Task = {
  __typename: "Task";
  taskName?: string | null;
  description?: string | null;
  howToFixIt?: string | null;
  issueDescription?: string | null;
  issueTitle?: string | null;
  requestedEvidence?: string | null;
  severity?: string | null;
  state?: string | null;
  summary?: string | null;
  tagNames?: Array<string | null> | null;
  title?: string | null;
  whyIssueIsBad?: string | null;
  type?: string | null;
  lastUpdated?: number | null;
  updatedBy?: string | null;
  taskId?: string | null;
  dueDate?: number | null;
  isActive?: boolean | null;
};

export type UpdateTaskRequest = {
  taskId: string;
  taskName?: string | null;
  description?: string | null;
  howToFixIt?: string | null;
  issueDescription?: string | null;
  issueTitle?: string | null;
  requestedEvidence?: string | null;
  severity?: string | null;
  state?: string | null;
  summary?: string | null;
  tagNames?: Array<string | null> | null;
  title?: string | null;
  whyIssueIsBad?: string | null;
  type?: string | null;
  updatedBy?: string | null;
  lastUpdated?: number | null;
  dueDate?: number | null;
  isActive?: boolean | null;
};

export type DeleteTasksRequest = {
  taskIds?: Array<string | null> | null;
};

export type CreateSecurityTeamRequest = {
  teamName: string;
  teamDescription?: string | null;
  securityTeamSeniorLeaders?: Array<string | null> | null;
  taskTagNames?: Array<string | null> | null;
  ddqTagNames?: Array<string | null> | null;
  ldapWithSnowRole?: Array<SNowRoleModel | null> | null;
  disableTier3Automation?: boolean | null;
  disableTier4Automation?: boolean | null;
  disableAriesWorkflow?: boolean | null;
  snowGroupName?: string | null;
  isActive?: boolean | null;
  disableTpsConfig?: boolean | null;
  updatedBy?: string | null;
  lastUpdated?: number | null;
  criteria?: Array<CreateCriteria | null> | null;
  taskCriteriaRequests?: Array<Array<CreateCriteria | null> | null> | null;
  ddqCriteriaRequests?: Array<Array<CreateCriteria | null> | null> | null;
};

export type SNowRoleModel = {
  teamLdapGroup?: string | null;
  serviceNowRole?: ServiceNowRoleType | null;
};

export enum ServiceNowRoleType {
  ASSESSOR = "ASSESSOR",
  ELEVATED_ASSESSOR = "ELEVATED_ASSESSOR",
  ASSESSOR_REVIEWER = "ASSESSOR_REVIEWER",
}

export type CreateCriteria = {
  tagNames?: Array<string | null> | null;
  intakeMaps?: IntakeMap | null;
  criteriaType?: CriteriaType | null;
  operator?: CriteriaOperator | null;
};

export type IntakeMap = {
  key: string;
  value: string;
};

export enum CriteriaType {
  TASK_CRITERIA = "TASK_CRITERIA",
  DDQ_CRITERIA = "DDQ_CRITERIA",
  SECURITY_TEAM_CRITERIA = "SECURITY_TEAM_CRITERIA",
}

export enum CriteriaOperator {
  AND = "AND",
  OR = "OR",
}

export type SecurityTeam = {
  __typename: "SecurityTeam";
  securityTeamId?: string | null;
  teamName?: string | null;
  teamDescription?: string | null;
  securityTeamSeniorLeaders?: Array<string | null> | null;
  taskTagNames?: Array<string | null> | null;
  ddqTagNames?: Array<string | null> | null;
  ldapWithSnowRole?: Array<SNowRoleModelOutput | null> | null;
  disableTier3Automation?: boolean | null;
  disableTier4Automation?: boolean | null;
  disableAriesWorkflow?: boolean | null;
  snowGroupName?: string | null;
  isActive?: boolean | null;
  disableTpsConfig?: boolean | null;
  lastUpdated?: number | null;
  updatedBy?: string | null;
  criteria?: Array<CriteriaOutput | null> | null;
  taskCriteria?: Array<Array<CriteriaOutput | null>> | null;
  ddqCriteria?: Array<Array<CriteriaOutput | null>> | null;
};

export type SNowRoleModelOutput = {
  __typename: "SNowRoleModelOutput";
  teamLdapGroup?: string | null;
  serviceNowRole?: ServiceNowRoleType | null;
};

export type CriteriaOutput = {
  __typename: "CriteriaOutput";
  criteriaId?: string | null;
  questionId?: string | null;
  answer?: string | null;
  operator?: CriteriaOperator | null;
  tagNames?: Array<string | null> | null;
  securityTeamId?: string | null;
  criteriaType?: CriteriaType | null;
  mappedCriteria?: Array<string | null> | null;
};

export type UpdateSecurityTeamRequest = {
  securityTeamId: string;
  teamName?: string | null;
  teamDescription?: string | null;
  securityTeamSeniorLeaders?: Array<string | null> | null;
  taskTagNames?: Array<string | null> | null;
  ddqTagNames?: Array<string | null> | null;
  ldapWithSnowRole?: Array<SNowRoleModel | null> | null;
  teamLdapGroup?: string | null;
  disableTier3Automation?: boolean | null;
  disableTier4Automation?: boolean | null;
  snowGroupName?: string | null;
  isActive?: boolean | null;
  disableTpsConfig?: boolean | null;
  disableAriesWorkflow?: boolean | null;
  updatedBy?: string | null;
  lastUpdated?: number | null;
  criteria?: Array<CreateCriteria | null> | null;
  taskCriteriaRequests?: Array<Array<CreateCriteria | null> | null> | null;
  ddqCriteriaRequests?: Array<Array<CreateCriteria | null> | null> | null;
};

export type DeleteSecurityTeamsRequest = {
  securityTeamIds?: Array<string | null> | null;
};

export type GetTagsInputType = {
  requestor: string;
  intakeMap?: Array<IntakeMap> | null;
  securityTeam: string;
  tagType?: string | null;
};

export type TagsResponse = {
  __typename: "TagsResponse";
  tags?: Array<Tag | null> | null;
};

export type GetTasksInputType = {
  tagNames: Array<string | null>;
};

export type TaskResponse = {
  __typename: "TaskResponse";
  tasks?: Array<Task | null> | null;
};

export type GetTagByIdRequest = {
  tagId: string;
};

export type AllTagsResponse = {
  __typename: "AllTagsResponse";
  tags?: Array<Tag | null> | null;
  continuationKey?: string | null;
};

export type GetTaskByIdRequest = {
  taskId: string;
};

export type AllTasksResponse = {
  __typename: "AllTasksResponse";
  tasks?: Array<Task | null> | null;
  continuationKey?: string | null;
};

export type GetSecurityTeamByIdRequest = {
  securityTeamId: string;
};

export type GetSecurityTeamType = {
  seniorLeader: Array<string>;
  intakeMap?: Array<IntakeMap> | null;
};

export type AllSecurityTeamsResponse = {
  __typename: "AllSecurityTeamsResponse";
  securityTeam?: Array<SecurityTeam | null> | null;
  continuationKey?: string | null;
};

export type AllIntakeQuestionsResponse = {
  __typename: "AllIntakeQuestionsResponse";
  result?: Array<QuestionObject | null> | null;
};

export type QuestionObject = {
  __typename: "QuestionObject";
  question?: string | null;
  questionType?: string | null;
  questionId?: string | null;
  choices?: Array<QuestionResponseChoice | null> | null;
};

export type QuestionResponseChoice = {
  __typename: "QuestionResponseChoice";
  displayValue?: string | null;
  backendValue?: string | null;
};

export type CreateTagMutationVariables = {
  input: CreateTagRequest;
};

export type CreateTagMutation = {
  createTag?: {
    __typename: "Tag";
    intakeId?: Array<string | null> | null;
    tagName?: string | null;
    securityTeam?: string | null;
    description?: string | null;
    isActive?: boolean | null;
    comments?: string | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    tagId?: string | null;
    tier?: Tier | null;
  } | null;
};

export type UpdateTagMutationVariables = {
  input?: UpdateTagRequest | null;
};

export type UpdateTagMutation = {
  updateTag?: {
    __typename: "Tag";
    intakeId?: Array<string | null> | null;
    tagName?: string | null;
    securityTeam?: string | null;
    description?: string | null;
    isActive?: boolean | null;
    comments?: string | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    tagId?: string | null;
    tier?: Tier | null;
  } | null;
};

export type DeleteTagMutationVariables = {
  tagId: string;
};

export type DeleteTagMutation = {
  deleteTag?: {
    __typename: "Tag";
    intakeId?: Array<string | null> | null;
    tagName?: string | null;
    securityTeam?: string | null;
    description?: string | null;
    isActive?: boolean | null;
    comments?: string | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    tagId?: string | null;
    tier?: Tier | null;
  } | null;
};

export type DeleteTagsMutationVariables = {
  input: DeleteTagsRequest;
};

export type DeleteTagsMutation = {
  deleteTags?: Array<{
    __typename: "Tag";
    intakeId?: Array<string | null> | null;
    tagName?: string | null;
    securityTeam?: string | null;
    description?: string | null;
    isActive?: boolean | null;
    comments?: string | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    tagId?: string | null;
    tier?: Tier | null;
  } | null> | null;
};

export type CreateTaskMutationVariables = {
  input: CreateTaskRequest;
};

export type CreateTaskMutation = {
  createTask?: {
    __typename: "Task";
    taskName?: string | null;
    description?: string | null;
    howToFixIt?: string | null;
    issueDescription?: string | null;
    issueTitle?: string | null;
    requestedEvidence?: string | null;
    severity?: string | null;
    state?: string | null;
    summary?: string | null;
    tagNames?: Array<string | null> | null;
    title?: string | null;
    whyIssueIsBad?: string | null;
    type?: string | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    taskId?: string | null;
    dueDate?: number | null;
    isActive?: boolean | null;
  } | null;
};

export type UpdateTaskMutationVariables = {
  input?: UpdateTaskRequest | null;
};

export type UpdateTaskMutation = {
  updateTask?: {
    __typename: "Task";
    taskName?: string | null;
    description?: string | null;
    howToFixIt?: string | null;
    issueDescription?: string | null;
    issueTitle?: string | null;
    requestedEvidence?: string | null;
    severity?: string | null;
    state?: string | null;
    summary?: string | null;
    tagNames?: Array<string | null> | null;
    title?: string | null;
    whyIssueIsBad?: string | null;
    type?: string | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    taskId?: string | null;
    dueDate?: number | null;
    isActive?: boolean | null;
  } | null;
};

export type DeleteTaskMutationVariables = {
  taskId: string;
};

export type DeleteTaskMutation = {
  deleteTask?: {
    __typename: "Task";
    taskName?: string | null;
    description?: string | null;
    howToFixIt?: string | null;
    issueDescription?: string | null;
    issueTitle?: string | null;
    requestedEvidence?: string | null;
    severity?: string | null;
    state?: string | null;
    summary?: string | null;
    tagNames?: Array<string | null> | null;
    title?: string | null;
    whyIssueIsBad?: string | null;
    type?: string | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    taskId?: string | null;
    dueDate?: number | null;
    isActive?: boolean | null;
  } | null;
};

export type DeleteTasksMutationVariables = {
  input: DeleteTasksRequest;
};

export type DeleteTasksMutation = {
  deleteTasks?: {
    __typename: "Task";
    taskName?: string | null;
    description?: string | null;
    howToFixIt?: string | null;
    issueDescription?: string | null;
    issueTitle?: string | null;
    requestedEvidence?: string | null;
    severity?: string | null;
    state?: string | null;
    summary?: string | null;
    tagNames?: Array<string | null> | null;
    title?: string | null;
    whyIssueIsBad?: string | null;
    type?: string | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    taskId?: string | null;
    dueDate?: number | null;
    isActive?: boolean | null;
  } | null;
};

export type CreateSecurityTeamMutationVariables = {
  input: CreateSecurityTeamRequest;
};

export type CreateSecurityTeamMutation = {
  createSecurityTeam?: SecurityTeam | null;
};

export type UpdateSecurityTeamMutationVariables = {
  input?: UpdateSecurityTeamRequest | null;
};

export type UpdateSecurityTeamMutation = {
  updateSecurityTeam?: SecurityTeam | null;
};

export type DeleteSecurityTeamMutationVariables = {
  securityTeamId: string;
};

export type DeleteSecurityTeamMutation = {
  deleteSecurityTeam?: boolean | null;
};

export type DeleteSecurityTeamsMutationVariables = {
  input: DeleteSecurityTeamsRequest;
};

export type DeleteSecurityTeamsMutation = {
  deleteSecurityTeams?: {
    __typename: "SecurityTeam";
    securityTeamId?: string | null;
    teamName?: string | null;
    teamDescription?: string | null;
    securityTeamSeniorLeaders?: Array<string | null> | null;
    taskTagNames?: Array<string | null> | null;
    ddqTagNames?: Array<string | null> | null;
    ldapWithSnowRole?: Array<{
      __typename: "SNowRoleModelOutput";
      teamLdapGroup?: string | null;
      serviceNowRole?: ServiceNowRoleType | null;
    } | null> | null;
    disableTier3Automation?: boolean | null;
    disableTier4Automation?: boolean | null;
    snowGroupName?: string | null;
    isActive?: boolean | null;
    disableTpsConfig?: boolean | null;
    disableAriesWorkflow?: boolean | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    criteria?: Array<CriteriaOutput | null> | null;
    taskCriteria?: Array<CriteriaOutput | null> | null;
    ddqCriteria?: Array<CriteriaOutput | null> | null;
  } | null;
};

export type GetTagsQueryVariables = {
  input: GetTagsInputType;
};

export type GetTagsQuery = {
  getTags?: {
    __typename: "TagsResponse";
    tags?: Array<{
      __typename: "Tag";
      intakeId?: Array<string | null> | null;
      tagName?: string | null;
      securityTeam?: string | null;
      description?: string | null;
      isActive?: boolean | null;
      comments?: string | null;
      lastUpdated?: number | null;
      updatedBy?: string | null;
      tagId?: string | null;
      tier?: Tier | null;
    } | null> | null;
  } | null;
};

export type GetTasksQueryVariables = {
  input: GetTasksInputType;
};

export type GetTasksQuery = {
  getTasks?: {
    __typename: "TaskResponse";
    tasks?: Array<{
      __typename: "Task";
      taskName?: string | null;
      description?: string | null;
      howToFixIt?: string | null;
      issueDescription?: string | null;
      issueTitle?: string | null;
      requestedEvidence?: string | null;
      severity?: string | null;
      state?: string | null;
      summary?: string | null;
      tagNames?: Array<string | null> | null;
      title?: string | null;
      whyIssueIsBad?: string | null;
      type?: string | null;
      lastUpdated?: number | null;
      updatedBy?: string | null;
      taskId?: string | null;
      dueDate?: number | null;
      isActive?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetTagByIdQueryVariables = {
  input: GetTagByIdRequest;
};

export type GetTagByIdQuery = {
  getTagById?: {
    __typename: "Tag";
    intakeId?: Array<string | null> | null;
    tagName?: string | null;
    securityTeam?: string | null;
    description?: string | null;
    isActive?: boolean | null;
    comments?: string | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    tagId?: string | null;
    tier?: Tier | null;
  } | null;
};

export type GetAllTagsQueryVariables = {
  limit?: number | null;
  tagId?: string | null;
};

export type GetAllTagsQuery = {
  getAllTags?: {
    __typename: "AllTagsResponse";
    tags?: Array<{
      __typename: "Tag";
      intakeId?: Array<string | null> | null;
      tagName?: string | null;
      securityTeam?: string | null;
      description?: string | null;
      isActive?: boolean | null;
      comments?: string | null;
      lastUpdated?: number | null;
      updatedBy?: string | null;
      tagId?: string | null;
      tier?: Tier | null;
    } | null> | null;
    continuationKey?: string | null;
  } | null;
};

export type GetTaskByIdQueryVariables = {
  input: GetTaskByIdRequest;
};

export type GetTaskByIdQuery = {
  getTaskById?: {
    __typename: "Task";
    taskName?: string | null;
    description?: string | null;
    howToFixIt?: string | null;
    issueDescription?: string | null;
    issueTitle?: string | null;
    requestedEvidence?: string | null;
    severity?: string | null;
    state?: string | null;
    summary?: string | null;
    tagNames?: Array<string | null> | null;
    title?: string | null;
    whyIssueIsBad?: string | null;
    type?: string | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    taskId?: string | null;
    dueDate?: number | null;
    isActive?: boolean | null;
  } | null;
};

export type GetAllTasksQueryVariables = {
  limit?: number | null;
  taskId?: string | null;
};

export type GetAllTasksQuery = {
  getAllTasks?: {
    __typename: "AllTasksResponse";
    tasks?: Array<{
      __typename: "Task";
      taskName?: string | null;
      description?: string | null;
      howToFixIt?: string | null;
      issueDescription?: string | null;
      issueTitle?: string | null;
      requestedEvidence?: string | null;
      severity?: string | null;
      state?: string | null;
      summary?: string | null;
      tagNames?: Array<string | null> | null;
      title?: string | null;
      whyIssueIsBad?: string | null;
      type?: string | null;
      lastUpdated?: number | null;
      updatedBy?: string | null;
      taskId?: string | null;
      dueDate?: number | null;
      isActive?: boolean | null;
    } | null> | null;
    continuationKey?: string | null;
  } | null;
};

export type GetSecurityTeamByIdQueryVariables = {
  input: GetSecurityTeamByIdRequest;
};

export type GetSecurityTeamByIdQuery = {
  getSecurityTeamById?: {
    __typename: "SecurityTeam";
    securityTeamId?: string | null;
    teamName?: string | null;
    teamDescription?: string | null;
    securityTeamSeniorLeaders?: Array<string | null> | null;
    taskTagNames?: Array<string | null> | null;
    ddqTagNames?: Array<string | null> | null;
    ldapWithSnowRole?: Array<{
      __typename: "SNowRoleModelOutput";
      teamLdapGroup?: string | null;
      serviceNowRole?: ServiceNowRoleType | null;
    } | null> | null;
    disableTier3Automation?: boolean | null;
    disableTier4Automation?: boolean | null;
    snowGroupName?: string | null;
    isActive?: boolean | null;
    disableTpsConfig?: boolean | null;
    disableAriesWorkflow?: boolean | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    criteria?: Array<CriteriaOutput | null> | null;
    taskCriteria?: Array<CriteriaOutput | null> | null;
    ddqCriteria?: Array<CriteriaOutput | null> | null;
  } | null;
};

export type GetSecurityTeamQueryVariables = {
  input: GetSecurityTeamType;
};

export type GetSecurityTeamQuery = {
  getSecurityTeam?: {
    __typename: "SecurityTeam";
    securityTeamId?: string | null;
    teamName?: string | null;
    teamDescription?: string | null;
    securityTeamSeniorLeaders?: Array<string | null> | null;
    taskTagNames?: Array<string | null> | null;
    ddqTagNames?: Array<string | null> | null;
    ldapWithSnowRole?: Array<{
      __typename: "SNowRoleModelOutput";
      teamLdapGroup?: string | null;
      serviceNowRole?: ServiceNowRoleType | null;
    } | null> | null;
    disableTier3Automation?: boolean | null;
    disableTier4Automation?: boolean | null;
    snowGroupName?: string | null;
    isActive?: boolean | null;
    disableTpsConfig?: boolean | null;
    disableAriesWorkflow?: boolean | null;
    lastUpdated?: number | null;
    updatedBy?: string | null;
    criteria?: Array<CriteriaOutput | null> | null;
    taskCriteria?: Array<CriteriaOutput | null> | null;
    ddqCriteria?: Array<CriteriaOutput | null> | null;
  } | null;
};

export type GetAllSecurityTeamsQueryVariables = {
  limit?: number | null;
  securityTeamId?: string | null;
};

export type GetAllSecurityTeamsQuery = {
  getAllSecurityTeams?: {
    __typename: "AllSecurityTeamsResponse";
    securityTeam?: Array<{
      __typename: "SecurityTeam";
      securityTeamId?: string | null;
      teamName?: string | null;
      teamDescription?: string | null;
      securityTeamSeniorLeaders?: Array<string | null> | null;
      taskTagNames?: Array<string | null> | null;
      ddqTagNames?: Array<string | null> | null;
      ldapWithSnowRole?: Array<{
        __typename: "SNowRoleModelOutput";
        teamLdapGroup?: string | null;
        serviceNowRole?: ServiceNowRoleType | null;
      } | null> | null;
      disableTier3Automation?: boolean | null;
      disableTier4Automation?: boolean | null;
      snowGroupName?: string | null;
      isActive?: boolean | null;
      disableTpsConfig?: boolean | null;
      disableAriesWorkflow?: boolean | null;
      lastUpdated?: number | null;
      updatedBy?: string | null;
      criteria?: Array<CriteriaOutput | null> | null;
      taskCriteria?: Array<CriteriaOutput | null> | null;
      ddqCriteria?: Array<CriteriaOutput | null> | null;
    } | null> | null;
    continuationKey?: string | null;
  } | null;
};

export type GetAllIntakeFieldsQueryVariables = {};

export type GetAllIntakeFieldsQuery = {
  getAllIntakeFields?: {
    __typename: "AllIntakeQuestionsResponse";
    result?: Array<{
      __typename: "QuestionObject";
      question?: string | null;
      questionType?: string | null;
      questionId?: string | null;
      choices?: Array<{
        __typename: "QuestionResponseChoice";
        displayValue?: string | null;
        backendValue?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};
