import React, {
  createContext,
  ReactElement,
  useState,
  useContext,
} from "react";

export type BreadCrumbContextType = {
  breadCrumbDisplayValue: string;
  setBreadCrumbDisplayValue: React.Dispatch<React.SetStateAction<string>>;
};

const BreadCrumbContext = createContext<BreadCrumbContextType>({
  breadCrumbDisplayValue: "",
  setBreadCrumbDisplayValue: () => {}, // eslint-disable-line
});

const useBreadCrumbContext = () => useContext(BreadCrumbContext);

interface Props {
  children: ReactElement;
}

function BreadCrumbContextProvider(props: Props): ReactElement {
  const [breadCrumbDisplayValue, setBreadCrumbDisplayValue] =
    useState<string>("");

  return (
    <BreadCrumbContext.Provider
      value={{
        breadCrumbDisplayValue,
        setBreadCrumbDisplayValue,
      }}
    >
      {props.children}
    </BreadCrumbContext.Provider>
  );
}

export { BreadCrumbContextProvider, useBreadCrumbContext };
