import React from "react";
import { createRoot } from "react-dom/client";
import AppWrapper from "./components/AppWrapper";
import LocalizationMessageProvider from "./components/LocalizationMessageProvider";
import { Amplify, Auth } from "aws-amplify";
import PortalRouter from "src/components/Router/PortalRouter";
import "@cloudscape-design/global-styles/index.css";
import { LoggerProvider } from "./context/LoggerProvider";
import "./context/LoggerProvider";
import "./index.scss";
import getAmplifyConfiguration from "src/utils/AmplifyConfigUtils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UserProvider } from "./context/UserProvider";

//Get Stage Specific Cognito Configuration using dynamic Stage environment variable which will be replace by eiter beta, gamma, prod in the run-time by Katal
//Please refer this link https://tiny.amazon.com/179wl8foj/codeamazpackTPSSbloba254lib to know deployment time replacements and are configured in webpack configuration
Amplify.configure(getAmplifyConfiguration("prod"));

export const queryClient = new QueryClient();

const container = document.getElementById("root");
const root = createRoot(container!);

const fetchToken = async (): Promise<string> => {
  const authHeader = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();
  return authHeader;
};

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <LoggerProvider fetchToken={fetchToken}>
          <AppWrapper>
            <LocalizationMessageProvider>
              <PortalRouter />
            </LocalizationMessageProvider>
          </AppWrapper>
        </LoggerProvider>
      </UserProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
