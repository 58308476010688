import React from "react";
import { MessageBundle } from "@amzn/arb-tools";
import CloudScapeButton from "@cloudscape-design/components/button";
import {
  TAG_FIELDS,
  TAG_TABLE_COLUMN_HEADINGS,
  FORM_MODE,
} from "../../constants/constants";
import { FormHandlerType } from "../../components/Header/FullPageHeader";
import { TagsTableRecord } from "./TagsPageTable";

export const TAGS_COLUMN_FIELDS_MAP: Map<
  TAG_FIELDS,
  TAG_TABLE_COLUMN_HEADINGS
> = new Map([
  [TAG_FIELDS.TAG_NAME, TAG_TABLE_COLUMN_HEADINGS.TAG_NAME_COLUMN_HEADING],
  [TAG_FIELDS.DESCRIPTION, TAG_TABLE_COLUMN_HEADINGS.DESCRIPTION],
  [TAG_FIELDS.IS_ACTIVE, TAG_TABLE_COLUMN_HEADINGS.IS_ACTIVE],
  [TAG_FIELDS.LAST_UPDATED, TAG_TABLE_COLUMN_HEADINGS.LAST_UPDATED],
  [TAG_FIELDS.UPDATED_BY, TAG_TABLE_COLUMN_HEADINGS.UPDATED_BY],
]);

const ROW_HEADER = TAG_FIELDS.TAG_NAME;

export const getTagsPageTableColumnDefinition = (
  bundle: MessageBundle,
  formHandler: FormHandlerType
) => {
  return Array.from(TAGS_COLUMN_FIELDS_MAP.entries()).map(([key, value]) => {
    if (key === ROW_HEADER) {
      return {
        id: key,
        header: bundle.getMessage(value),
        cell: (item: TagsTableRecord) => (
          <CloudScapeButton
            variant="inline-link"
            onClick={() => {
              formHandler(FORM_MODE.EDIT, item);
            }}
            data-testid={item.tagName}
          >
            {item[key]}
          </CloudScapeButton>
        ),
        sortingField: key,
        isRowHeader: true,
      };
    }
    return {
      id: key,
      header: bundle.getMessage(value),
      cell: (item: TagsTableRecord) => item[key],
      sortingField: key,
    };
  });
};
