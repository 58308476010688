import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { deleteTags } from "../graphql/mutations";
import { DeleteTagsMutation } from "../API";
import { retryConfig } from "../APIUtils";
import {
  API_KEYS,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

export const useDeleteTags = () => {
  const flashbarContext = useFlashBarContext();
  const [recordsLength, setRecordsLength] = useState(0);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [API_KEYS.DELETE_TAGS],
    mutationFn: async (recordIds: string[]) => {
      setRecordsLength(recordIds.length); //Setting record length to show flashbar messages
      return await API.graphql<GraphQLQuery<DeleteTagsMutation>>(
        graphqlOperation(deleteTags, {
          input: {
            tagIds: recordIds,
          },
        })
      );
    },
    ...retryConfig,
    //After trying mutation as configured in retryConfig we will perform necessary steps accordingly
    onSuccess: () => {
      //Display Mutation status using FlashBar message in tags page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.TAGS);

      //Display success FlashBar Message for delete successful using record count
      recordsLength > 1
        ? flashbarContext.setFlashBarMessage(
            FLASH_BAR_MESSAGES.TAGS_DELETE_SUCCESSFUL
          )
        : flashbarContext.setFlashBarMessage!(
            FLASH_BAR_MESSAGES.TAG_DELETE_SUCCESSFUL
          );

      //invalidating existing API data up on performing successful mutation to fetch latest data
      queryClient.invalidateQueries({ queryKey: [API_KEYS.GET_TAGS] });
    },
    onError: () => {
      //Display Mutation status using FlashBar message in tags page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.TAGS);

      //Display error FlashBar Message for delete failed using record count
      recordsLength > 1
        ? flashbarContext.setFlashBarMessage(
            FLASH_BAR_MESSAGES.TAGS_DELETE_FAILED
          )
        : flashbarContext.setFlashBarMessage(
            FLASH_BAR_MESSAGES.TAG_DELETE_FAILED
          );

      //invalidating existing API data up on performing failed mutation to fetch latest data
      queryClient.invalidateQueries({ queryKey: [API_KEYS.GET_TAGS] });
    },
  });
};
