import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { getAllIntakeFields } from "../graphql/queries";
import { GetAllIntakeFieldsQuery, AllIntakeQuestionsResponse } from "../API";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { retryConfig, cacheConfig } from "../APIUtils";
import {
  API_KEYS,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

/**
 * A custom React Query hook for fetching intake questions.
 *
 * @returns The result of the query hook.
 */
export const useGetIntakeQuestions =
  (): UseQueryResult<AllIntakeQuestionsResponse> => {
    const flashbarContext = useFlashBarContext();

    return useQuery({
      queryKey: [API_KEYS.GET_INTAKE_QUESTIONS],
      queryFn: async () => {
        const fetchResponse = await API.graphql<
          GraphQLQuery<GetAllIntakeFieldsQuery>
        >(graphqlOperation(getAllIntakeFields));

        return fetchResponse.data?.getAllIntakeFields;
      },

      ...retryConfig,

      ...cacheConfig,

      onError: () => {
        //Display Query status using FlashBar message in security team config page for failed condition
        flashbarContext.setflashBarMessageOnPage!(
          PAGE_TITLE.SECURITY_TEAM_CONFIG
        );

        //Display error FlashBar Message for fetch failed
        flashbarContext.setFlashBarMessage!(
          FLASH_BAR_MESSAGES.SECURITY_TEAM_INTAKE_QUESTIONS_FETCH_FAILED
        );
      },
    });
  };
