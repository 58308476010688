import { SecurityTeam } from "src/api/API";

const BASE_TICKET_URL =
  "https://t.corp.amazon.com/create/options?category=Information%20Security&type=SRC&item=Federate%20Claims%20Update%20Request&severity=THREE";

type SecurityTeamRequiredFields = Pick<
  SecurityTeam,
  "teamName" | "ldapWithSnowRole"
>;

// teamName: {teamLdapGroup: Add/Remove}
type LdapUpdateMap = Map<string, Map<string, "Add" | "Remove">>;

type LdapUpdateType = "Create" | "Update" | "Delete";

export type LdapUpdates = {
  type: LdapUpdateType;
  updates: LdapUpdateMap;
  callback: () => void;
} | null;

export type PromptCutTicketType = (
  selectedRecordIds: string[],
  callback: () => void
) => void;

export const promptUpdateTicketGenerator = (
  setLdapUpdates: (ldapUpdates: LdapUpdates) => void,
  idToSecurityTeam: Map<string, SecurityTeamRequiredFields>
): PromptCutTicketType => {
  return (selectedRecordIds: string[], callback: () => void) => {
    const ldapUpdates = getLdapUpdates({
      securityTeams: selectedRecordIds.map(
        (recordId) => idToSecurityTeam.get(recordId)!
      ),
      callback,
      deleteModal: true,
    });
    if (ldapUpdates?.updates.size !== 0) setLdapUpdates(ldapUpdates);
    else callback();
  };
};

interface GetLdapUpdatesArgs {
  securityTeams: Array<SecurityTeamRequiredFields>;
  callback: () => void;
  deleteModal?: boolean;
  oldSecurityTeam?: SecurityTeamRequiredFields;
}

// This assumes an Array of Security Teams with valid teamNames and teamIds
export function getLdapUpdates({
  securityTeams,
  callback,
  deleteModal,
  oldSecurityTeam,
}: GetLdapUpdatesArgs): LdapUpdates {
  if (deleteModal)
    return securityTeamsToLdapUpdates(securityTeams, "Delete", callback);

  if (!oldSecurityTeam)
    return securityTeamsToLdapUpdates(securityTeams, "Create", callback);

  return getLdapUpdateDiff(securityTeams[0], oldSecurityTeam, callback);
}

function getLdapUpdateDiff(
  newSecurityTeam: SecurityTeamRequiredFields,
  oldSecurityTeam: SecurityTeamRequiredFields,
  callback: () => void
): LdapUpdates {
  const oldLdapUpdates = securityTeamsToLdapUpdates(
    [oldSecurityTeam],
    "Create",
    callback
  );
  const newLdapUpdates = securityTeamsToLdapUpdates(
    [newSecurityTeam],
    "Create",
    callback
  );
  const diffLdapUpdates: LdapUpdates = {
    type: "Update",
    updates: new Map(),
    callback,
  };

  oldLdapUpdates?.updates.forEach((oldUpdateMap, oldUpdateTeam) => {
    // The team has been removed in the udpate
    if (!newLdapUpdates?.updates.has(oldUpdateTeam)) {
      oldUpdateMap.forEach((_action, ldapGroup) => {
        put(diffLdapUpdates.updates, oldUpdateTeam, ldapGroup, "Remove");
      });

      return;
    }

    // The team existed previously
    oldUpdateMap.forEach((_action, ldapGroup) => {
      // LDAP group has been removed in the update
      if (!newLdapUpdates.updates.get(oldUpdateTeam)!.has(ldapGroup))
        put(diffLdapUpdates.updates, oldUpdateTeam, ldapGroup, "Remove");
    });

    newLdapUpdates.updates.get(oldUpdateTeam)!.forEach((_action, ldapGroup) => {
      //LDAP group has been added in the update
      if (!oldUpdateMap.has(ldapGroup))
        put(diffLdapUpdates.updates, oldUpdateTeam, ldapGroup, "Add");
    });
  });

  newLdapUpdates?.updates.forEach((newUpdateMap, newUpdateTeam) => {
    // The team has been added in the udpate
    if (!oldLdapUpdates?.updates.has(newUpdateTeam))
      newUpdateMap.forEach((_action, ldapGroup) => {
        put(diffLdapUpdates.updates, newUpdateTeam, ldapGroup, "Add");
      });
  });

  return diffLdapUpdates;
}

function put<K1, K2, V2>(
  map: Map<K1, Map<K2, V2>>,
  entry: K1,
  key: K2,
  val: V2
) {
  if (!map.has(entry)) map.set(entry, new Map());
  map.get(entry)!.set(key, val);
}

function securityTeamsToLdapUpdates(
  securityTeams: Array<SecurityTeamRequiredFields>,
  type: LdapUpdateType,
  callback: () => void
): LdapUpdates {
  return {
    type,
    updates: new Map(
      securityTeams
        .filter(
          (securityTeam) =>
            !!securityTeam?.ldapWithSnowRole?.find(
              (ldapSnowRole) => !!ldapSnowRole?.teamLdapGroup
            )
        )
        .map((securityTeam) => [
          securityTeam.teamName!,
          new Map(
            securityTeam
              .ldapWithSnowRole!.filter(
                (ldapSnowRole) => !!ldapSnowRole?.teamLdapGroup
              )
              .map((ldapSnowRole) => [
                ldapSnowRole!.teamLdapGroup!,
                type === "Delete" ? "Remove" : "Add",
              ])
          ),
        ])
    ),
    callback,
  };
}

export function getUrl(ldapUpdates: Exclude<LdapUpdates, null>): string {
  let url = `${BASE_TICKET_URL}&title=%5BFEDERATE%20UPDATE%5D%20${ldapUpdates.type}%20Security%20Team&description=`;
  ldapUpdates.updates.forEach((ldapToAction, teamName) => {
    url += `%22${teamName}%22%20Updates%3A%0A`;
    ldapToAction.forEach((action, ldap) => {
      url += `%20%20-%20${action}%20%22${ldap}%22%0A`;
    });
  });
  return url;
}
