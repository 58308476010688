import styled from "styled-components";
import CloudScapeButton from "@cloudscape-design/components/button";
import CloudScapeCheckbox from "@cloudscape-design/components/checkbox";
import { colorBorderStatusError } from "@cloudscape-design/design-tokens";

export const RedButton = styled(CloudScapeButton)`
  color: ${colorBorderStatusError} !important;
  border-color: ${colorBorderStatusError} !important;
`;

export const OrangeButton = styled(CloudScapeButton)`
  background-color: #ff9900 !important;
  border-color: #ff9900 !important;
  color: #000000 !important;
`;

export const RoundedButton = styled(CloudScapeButton)`
  color: #000000 !important;
`;

export const CustomCheckBoxBtn = styled(CloudScapeCheckbox)`
  font-weight: bold !important;
`;
