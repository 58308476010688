export const retryConfig = {
  retry: 3,
  retryDelay: (attempt: number) => {
    return 2 ** attempt * 500; // .5, 1, and 2 seconds between retries
  },
};

export const cacheConfig = {
  staleTime: 60 * 60 * 1000, //As of now if is one hour We have to decide on this stale time value. When we decide on this using DB metrics we can add it back with right value
};
