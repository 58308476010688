import React from "react";
import { MessageBundle } from "@amzn/arb-tools";
import CloudScapeButton from "@cloudscape-design/components/button";
import { FormHandlerType } from "../../components/Header/FullPageHeader";
import {
  FORM_MODE,
  SecurityTeamFields,
  SecurityTeamTableColumnHeadings,
} from "../../constants/constants";

export const securityTeamsColumnFieldsMap: Map<
  SecurityTeamFields,
  SecurityTeamTableColumnHeadings
> = new Map([
  [
    SecurityTeamFields.SECURITY_TEAM_NAME,
    SecurityTeamTableColumnHeadings.SECURITY_TEAM_COLUMN_HEADINGS,
  ],
  [
    SecurityTeamFields.TASK_TAG_NAMES,
    SecurityTeamTableColumnHeadings.TASK_TAG_NAMES,
  ],
  [
    SecurityTeamFields.DISABLE_TIER_3_AUTOMATION,
    SecurityTeamTableColumnHeadings.DISABLE_TIER_3_AUTOMATION,
  ],
  [
    SecurityTeamFields.DISABLE_TIER_4_AUTOMATION,
    SecurityTeamTableColumnHeadings.DISABLE_TIER_4_AUTOMATION,
  ],
  [SecurityTeamFields.IS_ACTIVE, SecurityTeamTableColumnHeadings.IS_ACTIVE],
  [
    SecurityTeamFields.LAST_UPDATED,
    SecurityTeamTableColumnHeadings.LAST_UPDATED,
  ],
  [SecurityTeamFields.UPDATED_BY, SecurityTeamTableColumnHeadings.UPDATED_BY],
]);

const ROW_HEADER = SecurityTeamFields.SECURITY_TEAM_NAME;

export const getSecurityTeamsPageTableColumnDefinition = (
  bundle: MessageBundle,
  formHandler: FormHandlerType
) => {
  return Array.from(securityTeamsColumnFieldsMap.entries()).map(
    ([key, value]) => {
      if (key === ROW_HEADER) {
        return {
          id: key,
          header: bundle.getMessage(value),
          cell: (item: any) => (
            <CloudScapeButton
              variant="inline-link"
              onClick={() => {
                formHandler(FORM_MODE.EDIT, item);
              }}
              data-testid={item.teamName}
            >
              {item[key]}
            </CloudScapeButton>
          ),
          sortingField: key,
          isRowHeader: true,
        };
      }
      return {
        id: key,
        header: bundle.getMessage(value),
        cell: (item: any) => item[key],
        sortingField: key,
      };
    }
  );
};
