import { MessageBundle } from "@amzn/arb-tools";
import {
  PROPERTY_FILTER_OPERATORS_LIST,
  TASK_FIELDS,
  TASK_TABLE_COLUMN_HEADINGS,
} from "../../constants/constants";

export const TasksFilterFieldsMaps: Map<
  TASK_FIELDS,
  TASK_TABLE_COLUMN_HEADINGS
> = new Map([
  [TASK_FIELDS.TASK_NAME, TASK_TABLE_COLUMN_HEADINGS.TASK_NAME_COLUMN_HEADING],
  [TASK_FIELDS.SUMMARY, TASK_TABLE_COLUMN_HEADINGS.SUMMARY],
  [TASK_FIELDS.DESCRIPTION, TASK_TABLE_COLUMN_HEADINGS.DESCRIPTION],
  [TASK_FIELDS.SEVERITY, TASK_TABLE_COLUMN_HEADINGS.SEVERITY],
  [TASK_FIELDS.DUE_DATE, TASK_TABLE_COLUMN_HEADINGS.DUE_DATE],
  [TASK_FIELDS.TAG_NAMES, TASK_TABLE_COLUMN_HEADINGS.TAG_NAMES],
  [TASK_FIELDS.IS_ACTIVE, TASK_TABLE_COLUMN_HEADINGS.IS_ACTIVE],
  [TASK_FIELDS.ISSUE_TITLE, TASK_TABLE_COLUMN_HEADINGS.ISSUE_TITLE],
  [TASK_FIELDS.UPDATED_BY, TASK_TABLE_COLUMN_HEADINGS.UPDATED_BY],
]);

export const getTasksPageFilteringProperties = (bundle: MessageBundle) => {
  return Array.from(TasksFilterFieldsMaps.entries()).map(([key, value]) => {
    return {
      propertyLabel: bundle.getMessage(value),
      key: key,
      groupValuesLabel: bundle.getMessage(value),
      operators: PROPERTY_FILTER_OPERATORS_LIST,
    };
  });
};
