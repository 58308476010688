import React from "react";
import LoadingSpinner from "./Loading/LoadingSpinner";
import { AppInitWrapper } from "./AppInitWrapper";

interface PropsType {
  children: JSX.Element;
}

// Wrap component in application context.
const AppWrapper: React.FC<PropsType> = (props: PropsType) => (
  <AppInitWrapper>
    <React.Suspense fallback={<LoadingSpinner />}>
      {props.children}
    </React.Suspense>
  </AppInitWrapper>
);

export default AppWrapper;
