import { MessageBundle } from "@amzn/arb-tools";
import {
  FORM_MODE,
  TextInputFieldValidationType,
  RECORD_NAME_SIZE_LIMIT,
  RECORD_NAME_VALID_CHARACTERS_REGEX,
  TAGS_FORM_STRINGS,
} from "../../constants/constants";
import { object, string, boolean } from "yup";

export const TagFormValidationSchema = (
  bundle: MessageBundle,
  tagRecordName: string,
  tagsNameSet: Set<string>,
  formMode: FORM_MODE
) => {
  const errorMessage: string = bundle.getMessage(
    TAGS_FORM_STRINGS.ERROR_MESSAGE_REQUIRED
  );

  const specialCharactersErrorMessage: string = bundle.getMessage(
    TAGS_FORM_STRINGS.ERROR_MESSAGE_TAG_TEXT_INPUT_FIELD_CHARACTER_LIMITATIONS
  );

  return object().shape({
    tagName: string()
      .required(errorMessage)
      .test(
        TextInputFieldValidationType.CHARACTERS_SIZE,
        bundle.getMessage(
          TAGS_FORM_STRINGS.ERROR_MESSAGE_TAG_NAME_SIZE_LIMITATIONS
        ),
        function (value: string) {
          // Check if tagName size is within the limit
          return value.length < RECORD_NAME_SIZE_LIMIT;
        }
      )
      .test(
        TextInputFieldValidationType.SPECIAL_CHARACTERS,
        specialCharactersErrorMessage,
        function (value: string) {
          // Check if tagName contains only allowed characters (alphanumeric, _, (, ), &, /, @, ', -, and , characters plus spaces)
          return RECORD_NAME_VALID_CHARACTERS_REGEX.test(value);
        }
      )
      .test(
        TextInputFieldValidationType.UNIQUE,
        bundle.getMessage(TAGS_FORM_STRINGS.ERROR_MESSAGE_TAG_NAME_UNIQUE),
        function (value: string) {
          //Check if tagName is unique using existing tagsName Set we created from Tags Get API response
          const isUnique = isTagNameUnique(
            value,
            tagRecordName,
            tagsNameSet,
            formMode
          );
          return isUnique;
        }
      ),
    description: string()
      .notRequired()
      .test(
        TextInputFieldValidationType.SPECIAL_CHARACTERS,
        specialCharactersErrorMessage,
        function (value: string | undefined | null) {
          if (!value) return true; //Description input is not required thus we need to add this condition
          // Check if description contains only allowed characters (alphanumeric, _, (, ), &, /, @, ', -, and , characters plus spaces)
          return RECORD_NAME_VALID_CHARACTERS_REGEX.test(value);
        }
      ),
    isActive: boolean(),
  });
};

const isTagNameUnique = (
  value: string,
  tagRecordName: string,
  tagsNameSet: Set<string>,
  formMode: FORM_MODE
) => {
  if (formMode === FORM_MODE.EDIT && value === tagRecordName) return true; //In edit mode user didn't changes the existing tag Name in that case it should always return true to avoid unnecessary error
  return !tagsNameSet.has(value.trimEnd());
};
