import React from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { useLocation, useNavigate } from "react-router-dom";
import CloudScapeSideNavigation from "@cloudscape-design/components/side-navigation";
import { HOME_PAGE, SIDENAVBAR_LINKS } from "../../constants/linkConstants";
import { usePanelContext } from "../../context/PanelContextProvider";

function SideNavBar(props: WithBundleProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const panelContext = usePanelContext();

  return (
    <CloudScapeSideNavigation
      activeHref={location.pathname}
      header={{
        href: HOME_PAGE.href,
        text: props.bundle.getMessage(HOME_PAGE.text),
      }}
      items={SIDENAVBAR_LINKS.map((sideLink) => ({
        type: sideLink.type,
        text: props.bundle.getMessage(sideLink.text),
        href: sideLink.href,
      }))}
      onFollow={(event) => {
        event.stopPropagation();
        event.preventDefault();
        panelContext!.setSplitPanelContent(null);
        panelContext!.setToolsPanelContent(null);
        panelContext!.setSplitPanelOpen(false);
        panelContext!.setToolsPanelOpen(false);
        navigate(event.detail.href);
      }}
      data-testid="side-navigation"
    />
  );
}

export default withBundle("components.SideNavBar")(SideNavBar);
