import { MessageBundle } from "@amzn/arb-tools";
import { TagsTableRecord } from "../pages/tagsPage/TagsPageTable";
import { TasksTableRecord } from "../pages/tasksPage/TasksPageTable";
import { SecurityTeamsTableRecord } from "../pages/securityTeamsPage/SecurityTeamsPageTable";

export const getHeaderCounterText = (
  itemsLength: number,
  selectedItemsLength: number
): string => {
  return selectedItemsLength > 0
    ? `(${selectedItemsLength}/${itemsLength})`
    : `(${itemsLength})`;
};

export const getTextFilterCounterText = (
  count: number,
  bundle: MessageBundle
) => {
  return bundle.formatMessage("filter_message", { numRecords: count });
};

export const getRecordId = (
  currentRecord: TagsTableRecord | TasksTableRecord | SecurityTeamsTableRecord
) => {
  return "tagId" in currentRecord
    ? currentRecord.tagId
    : "taskId" in currentRecord
    ? currentRecord.taskId
    : currentRecord.securityTeamId;
};
