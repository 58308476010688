import React, { useEffect, useMemo, useState } from "react";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeTable from "@cloudscape-design/components/table";
import CloudScapePagination from "@cloudscape-design/components/pagination";
import CloudScapePropertyFilter from "@cloudscape-design/components/property-filter";
import { getTagsPageFilteringProperties } from "./TagsPageFilteringProperties";
import { getTagsPageTableColumnDefinition } from "./TagsPageTableColumnDefinition";
import {
  TableEmptyState,
  TableNoMatchState,
} from "../../components/Tables/TableComponents";
import {
  FormHandlerType,
  FullPageHeader,
} from "../../components/Header/FullPageHeader";
import {
  getHeaderCounterText,
  getTextFilterCounterText,
} from "../../utils/HeaderUtils";
import { decodeString, getStatusType } from "../../utils/CommonUtils";
import { usePanelContext } from "../../context/PanelContextProvider";
import {
  FORM_MODE,
  PAGE_SIZE,
  PAGE_TITLE,
  RECORD_LIMITS,
  TAG_FIELDS,
  TagFieldsFilterText,
} from "../../constants/constants";
import { useGetTags } from "../../api/tags/getTagsAPI";
import { Tag } from "../../api/API";
import { TagsPageForm } from "./TagsPageForm";

export interface TagsTableRecord {
  tagId: string;
  tagName: string;
  description: string;
  isActive: string;
  lastUpdated: string;
  updatedBy: string;
}

export const TagsPageTable = withBundle("pages.TagsPageTable")(
  (props: WithBundleProps) => {
    const { data, isLoading, isFetched, isSuccess, refetch } =
      useGetTags(RECORD_LIMITS);
    const [selectedTags, setSelectedTags] = useState<TagsTableRecord[]>([]);
    const panelContext = usePanelContext();
    const filteringProperties = useMemo(() => {
      return getTagsPageFilteringProperties(props.bundle);
    }, [props.bundle]); //To avoid unnecessary re-render's due to change in reference
    const tagNamesSet: Set<string> = useMemo<Set<string>>(
      () =>
        new Set(
          isFetched && isSuccess
            ? data.map((tagRecord: Tag) => tagRecord.tagName!)
            : []
        ),
      [data]
    );
    const {
      items,
      actions,
      filteredItemsCount,
      collectionProps,
      propertyFilterProps,
      paginationProps,
    } = useCollection(
      isFetched && isSuccess
        ? data.map((tag: Tag) => {
            return {
              tagId: tag.tagId!,
              tagName: decodeString(tag.tagName!),
              description: decodeString(tag.description!),
              isActive:
                tag.isActive === true
                  ? props.bundle.getMessage("active")
                  : props.bundle.getMessage("in_active"),
              lastUpdated: props.bundle.formatMessage("last_updated_date", {
                lastUpdated: new Date(0).setUTCSeconds(tag.lastUpdated!),
              }),
              updatedBy: tag.updatedBy!,
            };
          })
        : [],
      {
        pagination: { pageSize: PAGE_SIZE },
        sorting: {},
        propertyFiltering: {
          filteringProperties: filteringProperties,
          empty: (
            <TableEmptyState contentName={PAGE_TITLE.TAGS} refetch={refetch} />
          ),
          noMatch: (
            <TableNoMatchState
              onClearFilter={() => actions.setFiltering("")}
              page={PAGE_TITLE.TAGS}
            />
          ),
        },
        selection: {
          defaultSelectedItems: selectedTags,
        },
      }
    );

    //Update Selected Tag Data if Tag table record items got updated when API [data] is refetched again by tanStackQuery - Cache stale timeout or Mutation Invalidation action
    useEffect(() => {
      if (items) {
        setSelectedTags((prevSelectedTags) =>
          prevSelectedTags.filter((selectedTag: TagsTableRecord) =>
            items.some(
              (item: TagsTableRecord) => item.tagId === selectedTag.tagId //tagId Uniquely identifies table records
            )
          )
        );
      }
    }, [data]); //items depend on [data] which is memoized by tanStack Query so added it instead of items

    //Handler had logic to control Tasks Page Form
    const formHandler: FormHandlerType = (formMode, tagRecord) => {
      if (formMode === FORM_MODE.EDIT) {
        setSelectedTags([tagRecord! as TagsTableRecord]);
        panelContext!.setSplitPanelContent(
          <TagsPageForm
            tagRecord={tagRecord as TagsTableRecord}
            panelContext={panelContext!}
            formMode={FORM_MODE.EDIT}
            tagNamesSet={tagNamesSet!}
          />
        );
        panelContext!.setSplitPanelOpen(true);
      } else {
        setSelectedTags([]); //Clearing selected tags when creating a new tag
        panelContext!.setSplitPanelContent(
          <TagsPageForm
            panelContext={panelContext!}
            formMode={FORM_MODE.CREATE}
            tagNamesSet={tagNamesSet!}
          />
        );
        panelContext!.setSplitPanelOpen(true);
      }
    };

    return (
      <React.Fragment>
        <CloudScapeTable
          {...collectionProps}
          //Tags Table Properties
          variant="full-page" //variant table is entire content of page
          stickyHeader={true} //table header remains visible when the user scrolls down
          resizableColumns={true}
          selectionType="multi"
          stickyColumns={{ first: 1, last: 0 }}
          sortingDisabled={isLoading} //disabling user from sorting before loading the table content
          //Table Content
          items={items}
          columnDefinitions={getTagsPageTableColumnDefinition(
            props.bundle,
            formHandler
          )}
          //empty slot when table items array is empty
          empty={
            <TableEmptyState contentName={PAGE_TITLE.TAGS} refetch={refetch} />
          }
          //slot to add Heading element of table container
          header={
            <FullPageHeader
              currentRecords={selectedTags}
              totalRecords={getHeaderCounterText(
                isFetched && isSuccess ? data.length : 0,
                selectedTags.length
              )}
              page={PAGE_TITLE.TAGS}
              formHandler={formHandler}
            />
          }
          //Loading condition and UI
          loading={isLoading}
          loadingText={props.bundle.getMessage(`loading_${PAGE_TITLE.TAGS}`)}
          //slot to add filter controls for table
          filter={
            <CloudScapePropertyFilter
              {...propertyFilterProps}
              filteringAriaLabel={props.bundle.getMessage(
                `filter_${PAGE_TITLE.TAGS}`
              )}
              filteringPlaceholder={props.bundle.getMessage(
                `search_${PAGE_TITLE.TAGS}`
              )}
              filteringLoadingText={props.bundle.getMessage(
                TagFieldsFilterText.TAG_FIELDS_FILTER_LOADING_TEXT
              )}
              filteringErrorText={props.bundle.getMessage(
                TagFieldsFilterText.TAG_FIELDS_FILTER_ERROR_TEXT
              )}
              filteringRecoveryText={props.bundle.getMessage(
                TagFieldsFilterText.TAG_FIELDS_FILTER_RECOVERY_TEXT
              )}
              filteringFinishedText={props.bundle.getMessage(
                TagFieldsFilterText.TAG_FIELDS_FILTER_FINISHED_TEXT
              )}
              filteringStatusType={getStatusType(isLoading, isSuccess)}
              countText={getTextFilterCounterText(
                filteredItemsCount || 0,
                props.bundle
              )}
              onLoadItems={() => {
                //When Fetch Tags API Failed on clicking retry recovery button we need to make useGetTags to refetch.
                //If issue still persisting we have provided helper text in flashbar message to report issue by creating TT to address
                if (isLoading === false && isSuccess === false) {
                  refetch();
                }
              }}
            />
          }
          //slot to add pagination component of table
          pagination={<CloudScapePagination {...paginationProps} />}
          //Table Selection
          onSelectionChange={({ detail }) =>
            setSelectedTags(detail.selectedItems)
          }
          selectedItems={selectedTags}
          trackBy={TAG_FIELDS.TAG_ID} //specifies property that uniquely identifies individual item & acts as key for react rendering performance optimization
        />
      </React.Fragment>
    );
  }
);
