import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useLocation } from "react-router";
import { getAllTags } from "../graphql/queries";
import { GetAllTagsQuery, Tag } from "../API";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { retryConfig, cacheConfig } from "../APIUtils";
import { API_KEYS, FLASH_BAR_MESSAGES } from "../../constants/constants";
import { getPageTitleFromPathname } from "../../utils/FlashBarUtils";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

/**
 * A custom React Query hook for fetching tags.
 *
 * @param limit - The maximum number of tags to retrieve.
 * @returns The result of the query hook.
 */
export const useGetTags = (limit: number): UseQueryResult<Tag[]> => {
  const flashbarContext = useFlashBarContext();
  const location = useLocation();

  return useQuery({
    queryKey: [API_KEYS.GET_TAGS, limit],
    queryFn: async () => {
      const fetchResponse = await API.graphql<GraphQLQuery<GetAllTagsQuery>>(
        graphqlOperation(getAllTags, {
          limit: limit,
          tagId: "", //Continuation key to get All records data to FE
        })
      );

      return [...(fetchResponse?.data?.getAllTags?.tags ?? [])];
    },
    ...retryConfig,
    ...cacheConfig,
    onError: () => {
      //Display Query status using FlashBar message in tags page for failed condition
      flashbarContext.setflashBarMessageOnPage(
        getPageTitleFromPathname(location.pathname)!
      );

      //Display error FlashBar Message for fetch failed
      flashbarContext.setFlashBarMessage(FLASH_BAR_MESSAGES.TAGS_FETCH_FAILED);
    },
  });
};
