import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { updateTag } from "../graphql/mutations";
import { UpdateTagMutation, UpdateTagRequest } from "../API";
import { useUserContext } from "../../context/UserProvider";
import { retryConfig } from "../APIUtils";
import {
  API_KEYS,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

export const useUpdateTag = (): UseMutationResult<
  GraphQLResult<GraphQLQuery<UpdateTagMutation>>,
  unknown,
  UpdateTagRequest,
  unknown
> => {
  const flashbarContext = useFlashBarContext();
  const { alias } = useUserContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [API_KEYS.UPDATE_TAG],
    mutationFn: async (recordData: UpdateTagRequest) =>
      await API.graphql<GraphQLQuery<UpdateTagMutation>>(
        graphqlOperation(updateTag, {
          input: {
            ...recordData,
            updatedBy: alias,
          },
        })
      ),
    ...retryConfig,
    //After trying mutation as configured in retryConfig we will perform necessary steps accordingly
    onSuccess: () => {
      //Display Mutation status using FlashBar message in tags page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.TAGS);

      //Display success FlashBar Message for update successful
      flashbarContext.setFlashBarMessage(
        FLASH_BAR_MESSAGES.TAG_UPDATE_SUCCESSFUL
      );

      //invalidating existing API data up on performing successful mutation to fetch latest data
      queryClient.invalidateQueries({ queryKey: [API_KEYS.GET_TAGS] });
    },
    onError: () => {
      //Display Mutation status using FlashBar message in tags page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.TAGS);

      //Display success FlashBar Message for update failed
      flashbarContext.setFlashBarMessage(FLASH_BAR_MESSAGES.TAG_UPDATE_FAILED);

      //invalidating existing API data up on performing failed mutation to fetch latest data
      queryClient.invalidateQueries({ queryKey: [API_KEYS.GET_TAGS] });
    },
  });
};
