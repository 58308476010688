import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeFlashbar, {
  FlashbarProps as CloudScapeFlashbarProps,
} from "@cloudscape-design/components/flashbar";
import CloudScapeButton from "@cloudscape-design/components/button";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";
import {
  getFlashBarMessageType,
  displayFlashBarMessage,
  getPageTitleFromPathname,
} from "../../utils/FlashBarUtils";
import {
  FLASHBAR_MESSAGE_TYPE,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import { TT_URL } from "../../constants/urlConstants";

export const FLASHBAR_DATA_TEST_ID = "flashbar-notifications";

//Developed this flashbar component as per design guidelines https://tiny.amazon.com/ik3x1grm/refrclouawsdevflas
//Error Conditions guidelines https://tiny.amazon.com/apiwfki2/refrclouawsdevpattgeneerro
export const FlashBarNotifications = withBundle(
  "components.FlashBarNotifications"
)((props: WithBundleProps) => {
  const [tagsPageDisplayNotifications, setTagsPageDisplayNotifications] =
    useState<CloudScapeFlashbarProps.MessageDefinition[]>([]);
  const [tasksPageDisplayNotifications, setTasksPageDisplayNotifications] =
    useState<CloudScapeFlashbarProps.MessageDefinition[]>([]);
  const [
    securityTeamsPageDisplayNotifications,
    setSecurityTeamsPageDisplayNotifications,
  ] = useState<CloudScapeFlashbarProps.MessageDefinition[]>([]);
  const [
    securityTeamConfigPageDisplayNotifications,
    setSecurityTeamConfigPageDisplayNotifications,
  ] = useState<CloudScapeFlashbarProps.MessageDefinition[]>([]);
  const location = useLocation();
  const currentPage = useMemo(
    () => getPageTitleFromPathname(location.pathname)!,
    [location.pathname]
  );
  const flashBarContext = useFlashBarContext();
  const notificationSetter = useMemo(() => {
    if (flashBarContext.flashBarMessageOnPage === PAGE_TITLE.TAGS)
      return setTagsPageDisplayNotifications;
    if (flashBarContext.flashBarMessageOnPage === PAGE_TITLE.TASKS)
      return setTasksPageDisplayNotifications;
    if (flashBarContext.flashBarMessageOnPage === PAGE_TITLE.SECURITY_TEAMS)
      return setSecurityTeamsPageDisplayNotifications;
    if (
      flashBarContext.flashBarMessageOnPage === PAGE_TITLE.SECURITY_TEAM_CONFIG
    )
      return setSecurityTeamConfigPageDisplayNotifications;
    return null;
  }, [flashBarContext.flashBarMessageOnPage]);

  const getNotificationsArray = (pageTitle: PAGE_TITLE) => {
    if (pageTitle === PAGE_TITLE.TAGS) return tagsPageDisplayNotifications;
    if (pageTitle === PAGE_TITLE.TASKS) return tasksPageDisplayNotifications;
    if (pageTitle === PAGE_TITLE.SECURITY_TEAMS)
      return securityTeamsPageDisplayNotifications;
    if (pageTitle === PAGE_TITLE.SECURITY_TEAM_CONFIG)
      return securityTeamConfigPageDisplayNotifications;
    return [];
  };

  useEffect(() => {
    //When FlashBar Message is set we will display the action
    if (displayFlashBarMessage(flashBarContext.flashBarMessage)) {
      const messageType = getFlashBarMessageType(
        flashBarContext.flashBarMessage
      );
      const messageId = new Date();

      notificationSetter!((displayNotifications) => [
        {
          header:
            messageType === FLASHBAR_MESSAGE_TYPE.ERROR
              ? props.bundle.getMessage(flashBarContext.flashBarMessage)
              : null,
          type: messageType,
          dismissible: true,
          dismissLabel: props.bundle.getMessage("dismiss_label"),
          statusIconAriaLabel: props.bundle.getMessage(`${messageType}_label`),
          content:
            messageType !== FLASHBAR_MESSAGE_TYPE.ERROR
              ? props.bundle.getMessage(flashBarContext.flashBarMessage)
              : props.bundle.getMessage("error_message"),
          id: `${messageId}`,
          onDismiss: () => {
            notificationSetter!((prevNotifications) =>
              prevNotifications!.filter(
                (notification) => notification.id !== `${messageId}`
              )
            );
          },
          action: messageType === FLASHBAR_MESSAGE_TYPE.ERROR && (
            <CloudScapeButton
              onClick={() => {
                window.open(TT_URL, "_blank");
              }}
            >
              {props.bundle.getMessage("action_button_text")}
            </CloudScapeButton>
          ),
        },
        ...displayNotifications,
      ]);
      flashBarContext.setFlashBarMessage(FLASH_BAR_MESSAGES.NONE);
    }
  }, [flashBarContext.flashBarMessage]);

  return (
    <CloudScapeFlashbar
      items={getNotificationsArray(currentPage)}
      stackItems={true}
      i18nStrings={{
        ariaLabel: props.bundle.getMessage("notification_label"),
        notificationBarText: props.bundle.getMessage("notification_bar_text"),
        notificationBarAriaLabel: props.bundle.getMessage(
          "notification_bar_text_label"
        ),
        errorIconAriaLabel: props.bundle.getMessage("error_label"),
        successIconAriaLabel: props.bundle.getMessage("success_label"),
        warningIconAriaLabel: props.bundle.getMessage("warning_label"),
        infoIconAriaLabel: props.bundle.getMessage("info_label"),
      }}
      data-testid={FLASHBAR_DATA_TEST_ID}
    />
  );
});
