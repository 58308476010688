import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { UseQueryResult } from "@tanstack/react-query";
import CloudScapeFormField from "@cloudscape-design/components/form-field";
import CloudScapeContainer from "@cloudscape-design/components/container";
import CloudScapeHeader from "@cloudscape-design/components/header";
import CloudScapeMultiselect from "@cloudscape-design/components/multiselect";
import CloudScapeCheckbox from "@cloudscape-design/components/checkbox";
import CloudScapeSpaceBetween from "@cloudscape-design/components/space-between";
import CloudScapePropertyFilter from "@cloudscape-design/components/property-filter";
import CloudScapeBox from "@cloudscape-design/components/box";
import CloudScapeColumnLayout from "@cloudscape-design/components/column-layout";
import {
  RedButton,
  RoundedButton,
} from "../../../components/Buttons/CustomizedButtons";
import {
  AllIntakeQuestionsResponse,
  CriteriaType,
  Tag,
} from "../../../api/API";
import {
  DisplayToBackend,
  initialPropertyFilterTokenOperation,
} from "../SecurityTeamConfigPage";
import {
  getStatusType,
  dedupeTokens,
  decodeString,
  convertDisplayToBackendTokens,
  convertBackendToDisplayTokens,
} from "../../../utils/CommonUtils";
import {
  SecurityTeamFormStrings,
  ADD_ICON_NAME,
  PropertyFilterProps,
  PROPERTY_FILTER_OPERATORS_LIST,
  SecurityTeamFormFields,
  VENDOR_TIER,
} from "../../../constants/constants";
import { getPropertyFilterValidationError } from "../SecurityTeamConfigPageFormValidationSchema";

export const SECURITY_TEAM_QUESTION_GENERATION_TAG_NAMES_FORM_FIELD_DATA_TEST_ID =
  "security-team-question-generation-tag-names-form-field";

export const SECURITY_TEAM_QUESTION_GENERATION_QUESTION_CRITERIA_SET_CONTAINERS_DATA_TEST_ID =
  "security-team-question-generation-question-criteria-set-containers";

export const SECURITY_TEAM_QUESTION_GENERATION_QUESTION_CRITERIA_SET_ADD_BTN_DATA_TEST_ID =
  "security-team-question-generation-question-criteria-set-add-btn";

export const QUESTION_CRITERIA_SET_CONTAINER_DATA_CLASS_NAME =
  "question-criteria-set";

interface SecurityTeamQuestionCritieriaSetPropertyFilterKeyProps {
  propertyKey: string;
  label: string;
  value: string;
}

const getFiltering = (
  props: SecurityTeamQuestionGenerationLogicProps
): {
  filteringOptions: SecurityTeamQuestionCritieriaSetPropertyFilterKeyProps[];
  filteringProperties: PropertyFilterProps[];
} => {
  const filteringOptions: SecurityTeamQuestionCritieriaSetPropertyFilterKeyProps[] =
    [];
  const filteringProperties: PropertyFilterProps[] = [
    {
      propertyLabel: props.bundle.getMessage(
        SecurityTeamFormStrings.SECURITY_TEAM_TIER_LABEL_TEXT
      ),
      key: props.bundle.getMessage(
        SecurityTeamFormStrings.SECURITY_TEAM_TIER_GROUP_LABEL_TEXT
      ),
      groupValuesLabel: props.bundle.getMessage(
        SecurityTeamFormStrings.SECURITY_TEAM_TIER_GROUP_LABEL_TEXT
      ),
      operators: [PROPERTY_FILTER_OPERATORS_LIST[0]],
      group: props.bundle.getMessage(
        SecurityTeamFormStrings.SECURITY_TEAM_TIER_GROUP_LABEL_TEXT
      ),
      defaultOperator: PROPERTY_FILTER_OPERATORS_LIST[0],
    },
  ];

  if (props.intakeQuestionsQueryResult.isSuccess) {
    props.intakeQuestionsQueryResult.data?.result?.forEach(
      (questionsRecord) => {
        if (questionsRecord!.questionId !== VENDOR_TIER) {
          filteringProperties.push({
            propertyLabel: questionsRecord!.question!,
            key: questionsRecord!.question!,
            groupValuesLabel: props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT
            ),
            operators: [PROPERTY_FILTER_OPERATORS_LIST[0]],
            group: props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT
            ),
            defaultOperator: PROPERTY_FILTER_OPERATORS_LIST[0],
          });
        } else {
          filteringProperties[0].key = questionsRecord!.question!;
        }

        questionsRecord?.choices!.forEach((choice) => {
          filteringOptions.push({
            propertyKey: questionsRecord!.question!,
            label: choice!.displayValue!,
            value: choice!.displayValue!,
          });
        });
      }
    );
  }
  return { filteringOptions, filteringProperties };
};

interface QuestionCriteriaSetProps extends WithBundleProps {
  criteriaSetNumber: number;
  criteriaSetId: string;
  intakeQuestionsQueryResult: UseQueryResult<AllIntakeQuestionsResponse>;
  tagsQueryResult: UseQueryResult<Tag[]>;
  handleCriteriaSetDeleteAction: (criteriaSetNumberToRemove: string) => void;
  securityTeamConfigFormState: any; //this is internal for useFormik
  displayToBackend: DisplayToBackend;
}

export const QuestionCriteriaSet = withBundle(
  "pages.SecurityTeamQuestionGenerationLogic"
)((props: QuestionCriteriaSetProps) => {
  const { filteringOptions, filteringProperties } = useMemo(
    () => getFiltering(props),
    [
      props.intakeQuestionsQueryResult.data,
      props.intakeQuestionsQueryResult.isSuccess,
      props.bundle,
    ]
  );

  return (
    <CloudScapeContainer
      header={
        <CloudScapeHeader
          variant="h2"
          actions={
            <RedButton
              formAction="none"
              onClick={() => {
                props.handleCriteriaSetDeleteAction(props.criteriaSetId);
              }}
              data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_CRITERIA_SET_REMOVE_BTN_TEXT}-${props.criteriaSetNumber}`}
            >
              {props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_CRITERIA_SET_REMOVE_BTN_TEXT
              )}
            </RedButton>
          }
          data-testid={`security-team-question-criteria-set-${props.criteriaSetNumber}`}
        >
          {props.bundle.formatMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_HEADING,
            { criteriaSetNumber: props.criteriaSetNumber + 1 }
          )}
        </CloudScapeHeader>
      }
      data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_HEADER}-${props.criteriaSetNumber}`}
      data-classname={QUESTION_CRITERIA_SET_CONTAINER_DATA_CLASS_NAME}
    >
      <CloudScapeSpaceBetween
        direction="vertical"
        size="l"
        key={props.criteriaSetId}
      >
        <CloudScapeFormField
          errorText={
            props.securityTeamConfigFormState.values[
              SecurityTeamFormFields.DDQ_QUESTION_CRITERIA_SET_CHECKBOX
            ] &&
            props.securityTeamConfigFormState.touched[
              SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
            ] &&
            props.securityTeamConfigFormState.errors[
              SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
            ] &&
            props.securityTeamConfigFormState.errors[
              SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
            ][props.criteriaSetNumber] &&
            `${getPropertyFilterValidationError(
              props.securityTeamConfigFormState.errors[
                SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
              ][props.criteriaSetNumber].tokens
            )}`
          }
          data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_FIELD_PLACEHOLDER_TEXT}-${CriteriaType.DDQ_CRITERIA}-${props.criteriaSetNumber}`}
        >
          <CloudScapePropertyFilter
            onChange={({ detail }) => {
              //Filter token can be set only when Intake Questions API loaded successful
              props.intakeQuestionsQueryResult.isSuccess &&
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS,
                  props.securityTeamConfigFormState.values[
                    SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
                  ].map((ddqLogicCriteriaSet: any, index: number) => {
                    if (index === props.criteriaSetNumber)
                      return {
                        operation: detail.operation,
                        tokens: dedupeTokens(
                          convertDisplayToBackendTokens(
                            detail.tokens,
                            props.displayToBackend
                          )
                        ),
                      };
                    else return ddqLogicCriteriaSet;
                  })
                );
            }}
            query={convertBackendToDisplayTokens(
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
              ][props.criteriaSetNumber]
            )}
            customGroupsText={[
              {
                properties: props.bundle.getMessage(
                  SecurityTeamFormStrings.SECURITY_TEAM_TIER_GROUP_LABEL_TEXT
                ),
                values: props.bundle.getMessage(
                  SecurityTeamFormStrings.SECURITY_TEAM_TIER_GROUP_LABEL_TEXT
                ),
                group: props.bundle.getMessage(
                  SecurityTeamFormStrings.SECURITY_TEAM_TIER_GROUP_LABEL_TEXT
                ),
              },
              {
                properties: props.bundle.getMessage(
                  SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT
                ),
                values: props.bundle.getMessage(
                  SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT
                ),
                group: props.bundle.getMessage(
                  SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT
                ),
              },
            ]}
            filteringOptions={filteringOptions}
            filteringProperties={filteringProperties}
            filteringPlaceholder={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_FIELD_PLACEHOLDER_TEXT
            )}
            filteringLoadingText={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_LOADING_MESSAGE
            )}
            filteringErrorText={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_LOADING_ERROR_MESSAGE
            )}
            filteringRecoveryText={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_RECOVERY_TEXT
            )}
            filteringFinishedText={props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_FINISHED_TEXT
            )}
            filteringStatusType={getStatusType(
              props.intakeQuestionsQueryResult.isLoading,
              props.intakeQuestionsQueryResult.isSuccess
            )}
            onLoadItems={() => {
              //When Fetch Intake Questions API Failed on clicking retry recovery button we need to make useGetIntakeQuestions to refetch.
              //If issue still persisting we have provided helper text in flashbar message to report issue by creating TT to address
              if (
                props.intakeQuestionsQueryResult.isLoading === false &&
                props.intakeQuestionsQueryResult.isSuccess === false
              ) {
                props.intakeQuestionsQueryResult.refetch();
              }
            }}
            data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_FIELD_PLACEHOLDER_TEXT}-${CriteriaType.DDQ_CRITERIA}-${props.criteriaSetNumber}`}
          />
        </CloudScapeFormField>
        <CloudScapeBox
          data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_TAG_NAMES_FIELD_DESCRIPTION}-${props.criteriaSetNumber}`}
        >
          {props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_TAG_NAMES_FIELD_DESCRIPTION
          )}
        </CloudScapeBox>
        <CloudScapeColumnLayout columns={2}>
          <CloudScapeFormField
            errorText={
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DDQ_QUESTION_CRITERIA_SET_CHECKBOX
              ] &&
              props.securityTeamConfigFormState.touched[
                SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS
              ] &&
              props.securityTeamConfigFormState.errors[
                SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS
              ] &&
              props.securityTeamConfigFormState.errors[
                SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS
              ][props.criteriaSetNumber]
            }
            data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_CHECKBOX_LABEL}-${props.criteriaSetNumber}`}
          >
            <CloudScapeMultiselect
              selectedOptions={
                props.securityTeamConfigFormState.values[
                  SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS
                ][props.criteriaSetNumber]
              }
              options={
                !props.tagsQueryResult.isLoading &&
                props.tagsQueryResult.isSuccess
                  ? props.tagsQueryResult.data.map((tagRecord) => {
                      return {
                        label: decodeString(tagRecord.tagName!),
                        value: decodeString(tagRecord.tagName!),
                      };
                    })
                  : []
              }
              onChange={({ detail }) => {
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS,
                  props.securityTeamConfigFormState.values[
                    SecurityTeamFormFields
                      .DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS
                  ].map(
                    (ddqLogicCriteriaSetsTagNamesList: any, index: number) => {
                      if (index === props.criteriaSetNumber)
                        return detail.selectedOptions;
                      else return ddqLogicCriteriaSetsTagNamesList;
                    }
                  )
                );
              }}
              placeholder={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_TAG_NAMES_FIELD_PLACEHOLDER_TEXT
              )}
              empty={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_TAGS_EMPTY_MESSAGE
              )}
              loadingText={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_TAGS_LOADING_MESSAGE
              )}
              errorText={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_TAGS_LOADING_ERROR_MESSAGE
              )}
              recoveryText={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_RECOVERY_TEXT
              )}
              statusType={getStatusType(
                props.tagsQueryResult.isLoading,
                props.tagsQueryResult.isSuccess
              )}
              finishedText={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_TAGS_FINISHED_TEXT
              )}
              onLoadItems={() => {
                //When Fetch Tags API Failed on clicking retry recovery button we need to make useGetTags to refetch.
                //If issue still persisting we have provided helper text in flashbar message to report issue by creating TT to address
                if (
                  !props.tagsQueryResult.isLoading &&
                  !props.tagsQueryResult.isSuccess
                ) {
                  props.tagsQueryResult.refetch();
                }
              }}
              filteringType="auto"
              data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_CHECKBOX_LABEL}-${props.criteriaSetNumber}`}
            />
          </CloudScapeFormField>
        </CloudScapeColumnLayout>
      </CloudScapeSpaceBetween>
    </CloudScapeContainer>
  );
});

export interface SecurityTeamQuestionGenerationLogicProps
  extends WithBundleProps {
  intakeQuestionsQueryResult: UseQueryResult<AllIntakeQuestionsResponse>;
  tagsQueryResult: UseQueryResult<Tag[]>;
  securityTeamConfigFormState: any; //this is internal for useFormik
  displayToBackend: DisplayToBackend;
}

export const SecurityTeamQuestionGenerationLogic = withBundle(
  "pages.SecurityTeamQuestionGenerationLogic"
)((props: SecurityTeamQuestionGenerationLogicProps) => {
  const [criteriaSetIdArray, setCriteriaSetIdArray] = useState<string[]>([
    uuidv4(),
  ]);

  useEffect(() => {
    if (
      props.securityTeamConfigFormState.initialValues[
        SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS
      ].length > 0
    )
      setCriteriaSetIdArray(
        props.securityTeamConfigFormState.initialValues[
          SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS
        ].map(() => uuidv4())
      );
  }, [
    props.securityTeamConfigFormState.initialValues[
      SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS
    ].length,
  ]);

  useEffect(() => {
    if (
      criteriaSetIdArray.length === 0 &&
      props.securityTeamConfigFormState.values[
        SecurityTeamFormFields.DDQ_QUESTION_CRITERIA_SET_CHECKBOX
      ]
    ) {
      props.securityTeamConfigFormState.setFieldValue(
        SecurityTeamFormFields.DDQ_QUESTION_CRITERIA_SET_CHECKBOX,
        false
      );
    }
  }, [criteriaSetIdArray.length]);

  const handleCriteriaSetDeleteAction = (criteriaSetIdToRemove: string) => {
    //Filtering out question critieria set record that user wants to delete
    setCriteriaSetIdArray((prevCriteriaSetIdArray) =>
      prevCriteriaSetIdArray.filter(
        (criteriaSetId, criteriaSetIndex: number) => {
          if (criteriaSetId === criteriaSetIdToRemove) {
            props.securityTeamConfigFormState.setFieldValue(
              SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS,
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
              ].filter((_: any, index: number) => index !== criteriaSetIndex)
            );
            props.securityTeamConfigFormState.setFieldValue(
              SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS,
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS
              ].filter((_: any, index: number) => index !== criteriaSetIndex)
            );
          }
          return criteriaSetId !== criteriaSetIdToRemove;
        }
      )
    );
  };

  return (
    <CloudScapeContainer
      header={
        <CloudScapeHeader variant="h2">
          {props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_HEADER
          )}
        </CloudScapeHeader>
      }
      data-testid={
        SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_HEADER
      }
    >
      <CloudScapeSpaceBetween direction="vertical" size="l">
        <CloudScapeFormField
          data-testid={
            SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_CHECKBOX_LABEL
          }
        >
          <CloudScapeCheckbox
            checked={
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DDQ_QUESTION_GENERATION_CHECKBOX
              ]
            }
            onChange={({ detail }) => {
              props.securityTeamConfigFormState.setFieldValue(
                SecurityTeamFormFields.DDQ_QUESTION_GENERATION_CHECKBOX,
                detail.checked
              );

              !detail.checked &&
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.DDQ_LOGIC_TAG_NAME_LIST,
                  []
                );
            }}
            key={
              SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_CHECKBOX_LABEL
            }
            data-testid={
              SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_CHECKBOX_LABEL
            }
          >
            {props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_CHECKBOX_LABEL
            )}
          </CloudScapeCheckbox>
        </CloudScapeFormField>
        <CloudScapeColumnLayout columns={2}>
          <CloudScapeFormField
            errorText={
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DDQ_QUESTION_GENERATION_CHECKBOX
              ] &&
              props.securityTeamConfigFormState.touched[
                SecurityTeamFormFields.DDQ_LOGIC_TAG_NAME_LIST
              ] &&
              props.securityTeamConfigFormState.errors[
                SecurityTeamFormFields.DDQ_LOGIC_TAG_NAME_LIST
              ] &&
              `${
                props.securityTeamConfigFormState.errors[
                  SecurityTeamFormFields.DDQ_LOGIC_TAG_NAME_LIST
                ]
              }`
            }
            data-testid={
              SECURITY_TEAM_QUESTION_GENERATION_TAG_NAMES_FORM_FIELD_DATA_TEST_ID
            }
          >
            <CloudScapeMultiselect
              selectedOptions={
                props.securityTeamConfigFormState.values[
                  SecurityTeamFormFields.DDQ_LOGIC_TAG_NAME_LIST
                ]
              }
              options={
                !props.tagsQueryResult.isLoading &&
                props.tagsQueryResult.isSuccess
                  ? props.tagsQueryResult.data.map((tagRecord) => {
                      return {
                        label: decodeString(tagRecord.tagName!),
                        value: decodeString(tagRecord.tagName!),
                      };
                    })
                  : []
              }
              disabled={
                !props.securityTeamConfigFormState.values[
                  SecurityTeamFormFields.DDQ_QUESTION_GENERATION_CHECKBOX
                ]
              }
              placeholder={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_TAG_NAMES_FIELD_PLACEHOLDER_TEXT
              )}
              filteringType="auto"
              onChange={({ detail }) => {
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.DDQ_LOGIC_TAG_NAME_LIST,
                  detail.selectedOptions
                );
              }}
              empty={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_TAGS_EMPTY_MESSAGE
              )}
              loadingText={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_TAGS_LOADING_MESSAGE
              )}
              errorText={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_TAGS_LOADING_ERROR_MESSAGE
              )}
              recoveryText={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_RECOVERY_TEXT
              )}
              statusType={getStatusType(
                props.tagsQueryResult.isLoading,
                props.tagsQueryResult.isSuccess
              )}
              finishedText={props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_TAGS_FINISHED_TEXT
              )}
              onLoadItems={() => {
                //When Fetch Tags API Failed on clicking retry recovery button we need to make useGetTags to refetch.
                //If issue still persisting we have provided helper text in flashbar message to report issue by creating TT to address
                if (
                  !props.tagsQueryResult.isLoading &&
                  !props.tagsQueryResult.isSuccess
                ) {
                  props.tagsQueryResult.refetch();
                }
              }}
              data-testid={`${SecurityTeamFormStrings.SECURITY_TEAM_TAG_NAMES_FIELD_PLACEHOLDER_TEXT}-ddq`}
            />
          </CloudScapeFormField>
        </CloudScapeColumnLayout>
        <CloudScapeFormField
          data-testid={
            SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_ENABLE_CHECKBOX_LABEL
          }
        >
          <CloudScapeCheckbox
            checked={
              props.securityTeamConfigFormState.values[
                SecurityTeamFormFields.DDQ_QUESTION_CRITERIA_SET_CHECKBOX
              ]
            }
            onChange={({ detail }) => {
              props.securityTeamConfigFormState.setFieldValue(
                SecurityTeamFormFields.DDQ_QUESTION_CRITERIA_SET_CHECKBOX,
                detail.checked
              );

              if (detail.checked) {
                setCriteriaSetIdArray([uuidv4()]);
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS,
                  [initialPropertyFilterTokenOperation]
                );
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS,
                  [[]]
                );
              } else {
                setCriteriaSetIdArray([]);
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS,
                  []
                );
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS,
                  []
                );
              }
            }}
            key={
              SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_ENABLE_CHECKBOX_LABEL
            }
            data-testid={
              SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_ENABLE_CHECKBOX_LABEL
            }
          >
            {props.bundle.getMessage(
              SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_ENABLE_CHECKBOX_LABEL
            )}
          </CloudScapeCheckbox>
        </CloudScapeFormField>
        {props.securityTeamConfigFormState.values[
          SecurityTeamFormFields.DDQ_QUESTION_CRITERIA_SET_CHECKBOX
        ] && (
          <CloudScapeSpaceBetween direction="vertical" size="l">
            <CloudScapeSpaceBetween
              direction="vertical"
              size="l"
              data-testid={
                SECURITY_TEAM_QUESTION_GENERATION_QUESTION_CRITERIA_SET_CONTAINERS_DATA_TEST_ID
              }
            >
              {criteriaSetIdArray.length > 0 ? (
                criteriaSetIdArray.map(
                  (criteriaSetId: string, criterSetIndexNumber: number) => {
                    return (
                      <QuestionCriteriaSet
                        criteriaSetNumber={criterSetIndexNumber}
                        criteriaSetId={criteriaSetId}
                        intakeQuestionsQueryResult={
                          props.intakeQuestionsQueryResult
                        }
                        tagsQueryResult={props.tagsQueryResult}
                        handleCriteriaSetDeleteAction={
                          handleCriteriaSetDeleteAction
                        }
                        securityTeamConfigFormState={
                          props.securityTeamConfigFormState
                        }
                        key={criteriaSetId}
                        displayToBackend={props.displayToBackend}
                      />
                    );
                  }
                )
              ) : (
                <CloudScapeBox
                  data-testid={
                    SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_EMPTY_TEXT
                  }
                >
                  {props.bundle.getMessage(
                    SecurityTeamFormStrings.SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_EMPTY_TEXT
                  )}
                </CloudScapeBox>
              )}
            </CloudScapeSpaceBetween>
            <RoundedButton
              formAction="none"
              iconName={ADD_ICON_NAME}
              onClick={() => {
                setCriteriaSetIdArray((criteriaSetIdArray) => [
                  ...criteriaSetIdArray,
                  uuidv4(),
                ]);
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS,
                  [
                    ...props.securityTeamConfigFormState.values[
                      SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
                    ],
                    initialPropertyFilterTokenOperation,
                  ]
                );
                props.securityTeamConfigFormState.setFieldValue(
                  SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS,
                  [
                    ...props.securityTeamConfigFormState.values[
                      SecurityTeamFormFields
                        .DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS
                    ],
                    [],
                  ]
                );
              }}
              data-testid={
                SECURITY_TEAM_QUESTION_GENERATION_QUESTION_CRITERIA_SET_ADD_BTN_DATA_TEST_ID
              }
            />
          </CloudScapeSpaceBetween>
        )}
      </CloudScapeSpaceBetween>
    </CloudScapeContainer>
  );
});
