import React from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();

  const errorText =
    error?.statusText || error?.message || "An unexpected error occurred";

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, {errorText.toLowerCase()}.</p>
      <p>
        <i>{errorText}</i>
      </p>
    </div>
  );
}
