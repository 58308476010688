import React, {
  createContext,
  ReactElement,
  useState,
  useContext,
} from "react";
import { FLASH_BAR_MESSAGES, PAGE_TITLE } from "../constants/constants";

export type FlashBarContextType = {
  flashBarMessage: FLASH_BAR_MESSAGES;
  flashBarMessageOnPage: PAGE_TITLE;
  setFlashBarMessage: React.Dispatch<React.SetStateAction<FLASH_BAR_MESSAGES>>;
  setflashBarMessageOnPage: React.Dispatch<React.SetStateAction<PAGE_TITLE>>;
};

const FlashBarContext = createContext<FlashBarContextType>({
  flashBarMessage: FLASH_BAR_MESSAGES.NONE,
  flashBarMessageOnPage: PAGE_TITLE.HOME,
  setFlashBarMessage: () => {}, // eslint-disable-line
  setflashBarMessageOnPage: () => {}, // eslint-disable-line
});

const useFlashBarContext = () => useContext(FlashBarContext);

interface Props {
  children: ReactElement;
}

function FlashBarContextProvider(props: Props): ReactElement {
  const [flashBarMessage, setFlashBarMessage] = useState<FLASH_BAR_MESSAGES>(
    FLASH_BAR_MESSAGES.NONE
  );
  const [flashBarMessageOnPage, setflashBarMessageOnPage] =
    useState<PAGE_TITLE>(PAGE_TITLE.HOME);

  return (
    <FlashBarContext.Provider
      value={{
        flashBarMessage,
        flashBarMessageOnPage,
        setFlashBarMessage,
        setflashBarMessageOnPage,
      }}
    >
      {props.children}
    </FlashBarContext.Provider>
  );
}

export { FlashBarContextProvider, useFlashBarContext };
