import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router";
import { GraphQLQuery } from "@aws-amplify/api";
import { deleteSecurityTeams } from "../graphql/mutations";
import { DeleteSecurityTeamsMutation } from "../API";
import { retryConfig } from "../APIUtils";
import {
  API_KEYS,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import { getPageTitleFromPathname } from "../../utils/FlashBarUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

export const useDeleteSecurityTeams = () => {
  const flashbarContext = useFlashBarContext();
  const [recordsLength, setRecordsLength] = useState(0);
  const queryClient = useQueryClient();
  const location = useLocation();

  return useMutation({
    mutationKey: [API_KEYS.DELETE_SECURITY_TEAMS],
    mutationFn: async (recordIds: string[]) => {
      setRecordsLength(recordIds.length); //store record count to display appropriate flashbar message
      return await API.graphql<GraphQLQuery<DeleteSecurityTeamsMutation>>(
        graphqlOperation(deleteSecurityTeams, {
          input: {
            securityTeamIds: recordIds,
          },
        })
      );
    },
    ...retryConfig,
    //After trying mutation as configured in retryConfig we will perform necessary steps accordingly
    onSuccess: () => {
      //Display Mutation status using FlashBar message in security list view page
      flashbarContext.setflashBarMessageOnPage!(PAGE_TITLE.SECURITY_TEAMS);

      //Display success FlashBar Message for delete successful using record count
      recordsLength > 1
        ? flashbarContext.setFlashBarMessage!(
            FLASH_BAR_MESSAGES.SECURITY_TEAMS_DELETE_SUCCESSFUL
          )
        : flashbarContext.setFlashBarMessage!(
            FLASH_BAR_MESSAGES.SECURITY_TEAM_DELETE_SUCCESSFUL
          );

      //invalidating existing API data up on performing successful mutation to fetch latest data
      queryClient.invalidateQueries({
        queryKey: [API_KEYS.GET_SECURITY_TEAMS],
      });
    },
    onError: () => {
      //Display Mutation status using FlashBar message in security list view page
      flashbarContext.setflashBarMessageOnPage!(
        getPageTitleFromPathname(location.pathname)!
      );

      //Display error FlashBar Message for delete failed using record count
      recordsLength > 1
        ? flashbarContext.setFlashBarMessage!(
            FLASH_BAR_MESSAGES.SECURITY_TEAMS_DELETE_FAILED
          )
        : flashbarContext.setFlashBarMessage!(
            FLASH_BAR_MESSAGES.SECURITY_TEAM_DELETE_FAILED
          );

      //invalidating existing API data up on performing failed mutation to fetch latest data
      queryClient.invalidateQueries({
        queryKey: [API_KEYS.GET_SECURITY_TEAMS],
      });
    },
  });
};
