import React from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeHelpPanel, {
  HelpPanelProps as CloudScapeHelpPanelProps,
} from "@cloudscape-design/components/help-panel";
import { TASKS_FORM_STRINGS } from "../../constants/constants";

interface TasksPageHelpPanelProps
  extends CloudScapeHelpPanelProps,
    WithBundleProps {
  contentString: TASKS_FORM_STRINGS;
}

export const TasksPageHelpPanel = withBundle("components.TasksPageHelpPanel")(
  (props: TasksPageHelpPanelProps) => {
    return (
      <CloudScapeHelpPanel
        header={<h2>{props.bundle.getMessage(props.contentString)}</h2>}
      >
        {
          //Added formating as mentioned in this quip - https://tiny.amazon.com/5rd1k14z/quip0eOZHYDR
          props.bundle.formatMessage(`info_content_${props.contentString}`, {
            div: (child: string, index: number) => <div>{child}</div>,
            strong: (child: string, index: number) => <strong>{child}</strong>,
            br: (child: string, index: number) => <br></br>,
          })
        }
      </CloudScapeHelpPanel>
    );
  }
);
