import { Stage } from "../constants/constants";

export const BETA_SECURITY_TEAM_ID =
  "SecTeam-94e2c48c-e234-11ee-b4e1-d362dc9124fe";

export const GAMMA_SECURITY_TEAM_ID =
  "SecTeam-bb43cef9-e6e0-11ee-8af2-791f4318e525";

export const PROD_SECURITY_TEAM_ID =
  "SecTeam-bb43cef9-e6e0-11ee-8af2-791f4318e524";

export function getTPSSecurityTeamId(stage: string): string {
  switch (stage) {
    case Stage.BETA:
      return BETA_SECURITY_TEAM_ID;
    case Stage.GAMMA:
      return GAMMA_SECURITY_TEAM_ID;
    case Stage.PROD:
    default:
      return PROD_SECURITY_TEAM_ID;
  }
}
