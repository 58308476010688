import React, { useState, useEffect, useMemo, useRef } from "react";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeTable from "@cloudscape-design/components/table";
import CloudScapePagination from "@cloudscape-design/components/pagination";
import CloudScapePropertyFilter from "@cloudscape-design/components/property-filter";
import { getTasksPageTableColumnDefinition } from "./TasksPageTableColumnDefinition";
import {
  TableNoMatchState,
  TableEmptyState,
} from "../../components/Tables/TableComponents";
import {
  FullPageHeader,
  FormHandlerType,
} from "../../components/Header/FullPageHeader";
import {
  getTextFilterCounterText,
  getHeaderCounterText,
} from "../../utils/HeaderUtils";
import { decodeString, getStatusType } from "../../utils/CommonUtils";
import { usePanelContext } from "../../context/PanelContextProvider";
import {
  PAGE_TITLE,
  PAGE_SIZE,
  TASK_FIELDS,
  RECORD_LIMITS,
  FORM_MODE,
  TaskFieldsFilterText,
} from "../../constants/constants";
import { useGetTasks } from "../../api/tasks/getTasksAPI";
import { Task } from "../../api/API";
import { getTasksPageFilteringProperties } from "./TasksPageFilteringProperties";
import { TasksPageForm } from "./TasksPageForm";

export interface TasksTableRecord {
  taskId: string;
  taskName: string;
  summary: string;
  description: string;
  severity: string;
  dueDate: string;
  tagNames: string;
  isActive: string;
  issueTitle: string;
  issueDescription: string;
  whyIssueIsBad: string;
  howToFixIt: string;
  requestedEvidence: string;
  lastUpdated: string;
  updatedBy: string;
}

export const TasksPageTable = withBundle("pages.TasksPageTable")(
  (props: WithBundleProps) => {
    const [selectedTasks, setSelectedTasks] = useState<TasksTableRecord[]>([]);
    const { data, isLoading, isFetched, isSuccess, refetch } =
      useGetTasks(RECORD_LIMITS);
    const panelContext = usePanelContext();
    const filteringProperties = useMemo(() => {
      return getTasksPageFilteringProperties(props.bundle);
    }, [props.bundle]); //To avoid unnecessary re-render's due to change in reference
    const taskNamesSet = useMemo<Set<string>>(
      () =>
        new Set(
          isFetched && isSuccess
            ? data.map((taskRecord: Task) => taskRecord.taskName!)
            : []
        ),
      [data]
    );

    //For Hydra Phase 3B Phase 1 we have no pagination we are retrieving all Tasks to the frontend and applying pagination, filtering, sorting using cloudscape useCollection hook
    //As an fast follow we can deliver pagination move all this logic to Backend to perform filtering, sorting for us
    const {
      items,
      actions,
      filteredItemsCount,
      collectionProps,
      propertyFilterProps,
      paginationProps,
    } = useCollection(
      isFetched && isSuccess
        ? data.map((task: Task) => {
            return {
              taskId: task.taskId!,
              taskName: decodeString(task.taskName!),
              summary: decodeString(task.summary!),
              description: decodeString(task.description!),
              severity: task.severity!,
              dueDate: `${task.dueDate!}`,
              tagNames: decodeString(task.tagNames?.join(", ") ?? ""),
              isActive: task.isActive
                ? props.bundle.getMessage("active")
                : props.bundle.getMessage("in_active"),
              issueTitle: decodeString(task.issueTitle!),
              issueDescription: decodeString(task.issueDescription!),
              whyIssueIsBad: decodeString(task.whyIssueIsBad!),
              howToFixIt: decodeString(task.howToFixIt!),
              requestedEvidence: decodeString(task.requestedEvidence!),
              lastUpdated: props.bundle.formatMessage("last_updated_date", {
                lastUpdated: new Date(0).setUTCSeconds(task.lastUpdated!),
              }),
              updatedBy: task.updatedBy!,
            };
          })
        : [],
      {
        pagination: { pageSize: PAGE_SIZE },
        sorting: {},
        propertyFiltering: {
          filteringProperties: filteringProperties,
          empty: (
            <TableEmptyState contentName={PAGE_TITLE.TASKS} refetch={refetch} />
          ),
          noMatch: (
            <TableNoMatchState
              onClearFilter={() => actions.setFiltering("")}
              page={PAGE_TITLE.TASKS}
            />
          ),
        },
        selection: {
          defaultSelectedItems: selectedTasks,
        },
      }
    );

    //Update Selected Task Data if Task table record items got updated when API [data] is refetched again by tanStackQuery - Cache stale timeout or Mutation Invalidation action
    useEffect(() => {
      if (items) {
        setSelectedTasks((prevSelectedTasks) =>
          prevSelectedTasks.filter((selectedTask: TasksTableRecord) =>
            items.some(
              (item: TasksTableRecord) => item.taskId === selectedTask.taskId //taskId Uniquely identifies table records
            )
          )
        );
      }
    }, [data]); //items depend on [data] which is memoized by tanStack Query so added it instead of items

    const formHandler: FormHandlerType = (formMode, taskRecord) => {
      if (formMode === FORM_MODE.EDIT) {
        setSelectedTasks([taskRecord! as TasksTableRecord]);
        panelContext!.setSplitPanelContent(
          <TasksPageForm
            taskRecord={taskRecord! as TasksTableRecord}
            panelContext={panelContext!}
            formMode={FORM_MODE.EDIT}
            taskNamesSet={taskNamesSet!}
          />
        );
      } else {
        setSelectedTasks([]); //Clearing selected tasks when creating a new task
        panelContext!.setSplitPanelContent(
          <TasksPageForm
            panelContext={panelContext!}
            formMode={FORM_MODE.CREATE}
            taskNamesSet={taskNamesSet!}
          />
        );
      }
      panelContext!.setSplitPanelOpen(true);
    };

    return (
      <React.Fragment>
        <CloudScapeTable
          {...collectionProps}
          //Tasks Table Properties
          variant="full-page" //variant table is entire content of page
          stickyHeader={true} //table header remains visible when the user scrolls down
          resizableColumns={true}
          selectionType="multi"
          stickyColumns={{ first: 1, last: 0 }}
          sortingDisabled={isLoading} //disabling user from sorting before loading the table content
          //Table Content
          items={items}
          columnDefinitions={getTasksPageTableColumnDefinition(
            props.bundle,
            formHandler
          )}
          //empty slot when table items array is empty
          empty={
            <TableEmptyState contentName={PAGE_TITLE.TASKS} refetch={refetch} />
          }
          //slot to add Heading element of table container
          header={
            <FullPageHeader
              currentRecords={selectedTasks}
              totalRecords={getHeaderCounterText(
                isFetched && isSuccess ? data.length : 0,
                selectedTasks.length
              )}
              page={PAGE_TITLE.TASKS}
              formHandler={formHandler}
            />
          }
          //Loading condition and UI
          loading={isLoading}
          loadingText={props.bundle.getMessage(`loading_${PAGE_TITLE.TASKS}`)}
          //slot to add filter controls for table
          filter={
            <CloudScapePropertyFilter
              {...propertyFilterProps}
              filteringAriaLabel={props.bundle.getMessage(
                `filter_${PAGE_TITLE.TASKS}`
              )}
              filteringPlaceholder={props.bundle.getMessage(
                `search_${PAGE_TITLE.TASKS}`
              )}
              filteringLoadingText={props.bundle.getMessage(
                TaskFieldsFilterText.TASK_FIELDS_FILTER_LOADING_TEXT
              )}
              filteringErrorText={props.bundle.getMessage(
                TaskFieldsFilterText.TASK_FIELDS_FILTER_ERROR_TEXT
              )}
              filteringRecoveryText={props.bundle.getMessage(
                TaskFieldsFilterText.TASK_FIELDS_FILTER_RECOVERY_TEXT
              )}
              filteringFinishedText={props.bundle.getMessage(
                TaskFieldsFilterText.TASK_FIELDS_FILTER_FINISHED_TEXT
              )}
              filteringStatusType={getStatusType(isLoading, isSuccess)}
              countText={getTextFilterCounterText(
                filteredItemsCount || 0,
                props.bundle
              )}
              onLoadItems={() => {
                //When Fetch Tags API Failed on clicking retry recovery button we need to make useGetTags to refetch.
                //If issue still persisting we have provided helper text in flashbar message to report issue by creating TT to address
                if (isLoading === false && isSuccess === false) {
                  refetch();
                }
              }}
            />
          }
          //slot to add pagination component of table
          pagination={<CloudScapePagination {...paginationProps} />}
          //Table Selection
          onSelectionChange={({ detail }) =>
            setSelectedTasks(detail.selectedItems)
          }
          selectedItems={selectedTasks}
          trackBy={TASK_FIELDS.TASK_ID} //specifies property that uniquely identifies individual item & acts as key for react rendering performance optimization
        />
      </React.Fragment>
    );
  }
);
