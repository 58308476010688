import React, { useMemo } from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeFormField from "@cloudscape-design/components/form-field";
import CloudScapeContainer from "@cloudscape-design/components/container";
import CloudScapeHeader from "@cloudscape-design/components/header";
import { UseQueryResult } from "@tanstack/react-query";
import CloudScapePropertyFilter from "@cloudscape-design/components/property-filter";
import { AllIntakeQuestionsResponse } from "../../../api/API";
import {
  SecurityTeamFormStrings,
  PropertyFilterProps,
  PROPERTY_FILTER_OPERATORS_LIST,
  SecurityTeamFormFields,
} from "../../../constants/constants";
import {
  getStatusType,
  dedupeTokens,
  convertDisplayToBackendTokens,
  convertBackendToDisplayTokens,
} from "../../../utils/CommonUtils";
import { getPropertyFilterValidationError } from "../SecurityTeamConfigPageFormValidationSchema";
import { DisplayToBackend } from "../SecurityTeamConfigPage";

export const SECURITY_TEAM_DETERMINATION_FORM_CONTAINER_DATA_TEST_ID =
  "security-team-determination-form-container";
export const SECURITY_TEAM_DETERMINATION_PROPERTY_FILTER_FORM_FIELD_DATA_TEST_ID =
  "security-team-determination-property-filter-form-field";

interface SecurityTeamDeterminationPropertyFilterKeyProps {
  propertyKey: string;
  label: string;
  value: string;
}

const getFiltering = (
  props: SecurityTeamDeterminationProps
): {
  filteringOptions: SecurityTeamDeterminationPropertyFilterKeyProps[];
  filteringProperties: PropertyFilterProps[];
} => {
  const filteringOptions: SecurityTeamDeterminationPropertyFilterKeyProps[] =
    [];
  const filteringProperties: PropertyFilterProps[] = [
    {
      propertyLabel: props.bundle.getMessage(
        SecurityTeamFormStrings.SECURITY_TEAM_DETERMINATION_REQUESTERS_SENIOR_LEADER
      ),
      key: props.bundle.getMessage(
        SecurityTeamFormStrings.SECURITY_TEAM_DETERMINATION_REQUESTERS_SENIOR_LEADER
      ),
      groupValuesLabel: props.bundle.getMessage(
        SecurityTeamFormStrings.SECURITY_TEAM_DETERMINATION_PROPERTY_FILTER_OTHER_GROUP_LABEL_TEXT
      ),
      operators: [PROPERTY_FILTER_OPERATORS_LIST[2]],
      group: props.bundle.getMessage(
        SecurityTeamFormStrings.SECURITY_TEAM_DETERMINATION_PROPERTY_FILTER_OTHER_GROUP_LABEL_TEXT
      ),
      defaultOperator: PROPERTY_FILTER_OPERATORS_LIST[2],
    },
  ];

  if (props.intakeQuestionsQueryResult.isSuccess) {
    props.intakeQuestionsQueryResult.data?.result?.forEach(
      (questionsRecord) => {
        filteringProperties.push({
          propertyLabel: questionsRecord!.question!,
          key: questionsRecord!.question!,
          groupValuesLabel: props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT
          ),
          operators: [PROPERTY_FILTER_OPERATORS_LIST[0]],
          group: props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT
          ),
          defaultOperator: PROPERTY_FILTER_OPERATORS_LIST[0],
        });

        questionsRecord?.choices!.forEach((choice) => {
          filteringOptions.push({
            propertyKey: questionsRecord!.question!,
            label: choice!.displayValue!,
            value: choice!.displayValue!,
          });
        });
      }
    );
  }
  return { filteringOptions, filteringProperties };
};

interface SecurityTeamDeterminationProps extends WithBundleProps {
  intakeQuestionsQueryResult: UseQueryResult<AllIntakeQuestionsResponse>;
  securityTeamConfigFormState: any; //this is internal for useFormik
  displayToBackend: DisplayToBackend;
}

export const SecurityTeamDetermination = withBundle(
  "pages.SecurityTeamDetermination"
)((props: SecurityTeamDeterminationProps) => {
  const { filteringOptions, filteringProperties } = useMemo(
    () => getFiltering(props),
    [
      props.intakeQuestionsQueryResult.data,
      props.intakeQuestionsQueryResult.isSuccess,
      props.bundle,
    ]
  );

  return (
    <CloudScapeContainer
      header={
        <CloudScapeHeader
          variant="h2"
          description={props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_DETERMINATION_CONTAINER_HEADER_DESCRIPTION
          )}
        >
          {props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_DETERMINATION_CONTAINER_HEADER
          )}
        </CloudScapeHeader>
      }
      data-testid={SECURITY_TEAM_DETERMINATION_FORM_CONTAINER_DATA_TEST_ID}
    >
      <CloudScapeFormField
        errorText={
          props.securityTeamConfigFormState.touched[
            SecurityTeamFormFields.TEAM_DETERMINATION_CRITERIA
          ] &&
          props.securityTeamConfigFormState.errors[
            SecurityTeamFormFields.TEAM_DETERMINATION_CRITERIA
          ] &&
          `${getPropertyFilterValidationError(
            props.securityTeamConfigFormState.errors[
              SecurityTeamFormFields.TEAM_DETERMINATION_CRITERIA
            ].tokens
          )}`
        }
        stretch={true}
        data-testid={
          SECURITY_TEAM_DETERMINATION_PROPERTY_FILTER_FORM_FIELD_DATA_TEST_ID
        }
      >
        <CloudScapePropertyFilter
          onChange={({ detail }) => {
            props.securityTeamConfigFormState.setFieldValue(
              SecurityTeamFormFields.TEAM_DETERMINATION_CRITERIA,
              {
                operation: detail.operation,
                tokens: dedupeTokens(
                  convertDisplayToBackendTokens(
                    detail.tokens,
                    props.displayToBackend
                  )
                ),
              }
            );
          }}
          query={convertBackendToDisplayTokens(
            props.securityTeamConfigFormState.values[
              SecurityTeamFormFields.TEAM_DETERMINATION_CRITERIA
            ]
          )}
          disableFreeTextFiltering
          customGroupsText={[
            {
              properties: props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_DETERMINATION_PROPERTY_FILTER_OTHER_GROUP_LABEL_TEXT
              ),
              values: props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_DETERMINATION_PROPERTY_FILTER_OTHER_GROUP_LABEL_TEXT
              ),
              group: props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_DETERMINATION_PROPERTY_FILTER_OTHER_GROUP_LABEL_TEXT
              ),
            },
            {
              properties: props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT
              ),
              values: props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT
              ),
              group: props.bundle.getMessage(
                SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT
              ),
            },
          ]}
          filteringOptions={filteringOptions}
          filteringProperties={filteringProperties}
          filteringPlaceholder={props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_FIELD_PLACEHOLDER_TEXT
          )}
          filteringLoadingText={props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_LOADING_MESSAGE
          )}
          filteringErrorText={props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_LOADING_ERROR_MESSAGE
          )}
          filteringRecoveryText={props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_RECOVERY_TEXT
          )}
          filteringFinishedText={props.bundle.getMessage(
            SecurityTeamFormStrings.SECURITY_TEAM_INTAKE_QUESTIONS_FINISHED_TEXT
          )}
          filteringStatusType={getStatusType(
            props.intakeQuestionsQueryResult.isLoading,
            props.intakeQuestionsQueryResult.isSuccess
          )}
          onLoadItems={() => {
            //When Fetch Tags API Failed on clicking retry recovery button we need to make useGetTags to refetch.
            //If issue still persisting we have provided helper text in flashbar message to report issue by creating TT to address
            if (
              !props.intakeQuestionsQueryResult.isLoading &&
              !props.intakeQuestionsQueryResult.isSuccess
            ) {
              props.intakeQuestionsQueryResult.refetch();
            }
          }}
          data-testid={
            SECURITY_TEAM_DETERMINATION_PROPERTY_FILTER_FORM_FIELD_DATA_TEST_ID
          }
        />
      </CloudScapeFormField>
    </CloudScapeContainer>
  );
});
