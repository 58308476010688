import { ServiceNowRoleType } from "../api/API";

export const TRANSLATIONS_DIR_NAMESPACE = "translations";
export const TRANSLATION_ROUTE = `/${TRANSLATIONS_DIR_NAMESPACE}`;
export const DEFAULT_LOCALE = "en-US";
export const HOMEPAGE_MESSAGE_BUNDLE_NAME = "pages.homepage";
export const LOGGING_OUT = "TPSStaffAugmentation_loggingOut";
export const LOADING_SPINNER = "loading-spinner";
export const SECURITY_CONFIG_TEAM_ID_URL_FRAGMENT = "securityteamid";
export const ADD_ICON_NAME = "add-plus";
export const DEEP_LINK = "TPSStaffAugmentationWebsite_deepLink";
export const PAGE_SIZE = 15;
export const RECORD_LIMITS = 1000;
export const RECORD_NAME_SIZE_LIMIT = 150;
export const RECORD_NAME_VALID_CHARACTERS_REGEX = /^[^$`#*^|]*$/;
export const VENDOR_TIER = "vendor_tier";

export const RECORD_ACTIVE_VALUE = true;
export const RECORD_IN_ACTIVE_VALUE = false;

export const SERVICE_NOW_ROLE_LIST: Readonly<ServiceNowRoleType[]> = [
  ServiceNowRoleType.ASSESSOR,
  ServiceNowRoleType.ELEVATED_ASSESSOR,
  ServiceNowRoleType.ASSESSOR_REVIEWER,
];

export const PROPERTY_FILTER_OPERATORS_LIST: Readonly<string[]> = [
  "=",
  "!=",
  ":",
  "!:",
]; //Default property filter operators - https://tiny.amazon.com/13t9rq0bh/cloudesiprop

export enum PAGE_TITLE {
  HOME = "home",
  TAGS = "tags",
  TASKS = "tasks",
  SECURITY_TEAMS = "security_teams",
  SECURITY_TEAM_CONFIG = "security_team_config",
}

export enum FORM_MODE {
  CREATE = "create",
  EDIT = "edit",
}

export enum SEVERITY {
  HIGH = "High",
  MODERATE = "Moderate",
  LOW = "Low",
}

export enum RECORD_STATE {
  ACTIVE = "active",
  IN_ACTIVE = "in_active",
}

export enum TASK_FIELDS {
  TASK_ID = "taskId",
  TASK_NAME = "taskName",
  SUMMARY = "summary",
  DESCRIPTION = "description",
  SEVERITY = "severity",
  DUE_DATE = "dueDate",
  TAG_NAMES = "tagNames",
  ISSUE_TITLE = "issueTitle",
  ISSUE_DESCRIPTION = "issueDescription",
  WHY_ISSUE_IS_BAD = "whyIssueIsBad",
  HOW_TO_FIX_IT = "howToFixIt",
  REQUESTED_EVIDENCE = "requestedEvidence",
  IS_ACTIVE = "isActive",
  LAST_UPDATED = "lastUpdated",
  UPDATED_BY = "updatedBy",
}

export enum TAG_FIELDS {
  TAG_ID = "tagId",
  TAG_NAME = "tagName",
  DESCRIPTION = "description",
  IS_ACTIVE = "isActive",
  LAST_UPDATED = "lastUpdated",
  UPDATED_BY = "updatedBy",
}

//Fields for Security Team Page Table as API's getAllSecurityTeams API is not sending us back all fields
export enum SecurityTeamFields {
  SECURITY_TEAM_ID = "securityTeamId",
  SECURITY_TEAM_NAME = "teamName",
  DISABLE_TIER_3_AUTOMATION = "disableTier3Automation",
  DISABLE_TIER_4_AUTOMATION = "disableTier4Automation",
  IS_ACTIVE = "isActive",
  TASK_TAG_NAMES = "taskTagNames",
  LAST_UPDATED = "lastUpdated",
  UPDATED_BY = "updatedBy",
}

export enum SecurityTeamFormFields {
  SECURITY_TEAM_ID = "securityTeamId",
  SECURITY_TEAM_NAME = "teamName",
  LDAP_WITH_SNOW_ROLE_LIST = "ldapWithSnowRoleList",
  TEAM_LDAP_GROUP = "teamLdapGroup",
  SERVICE_NOW_ROLE = "serviceNowRole",
  DISABLE_TPS_CONFIG = "disableTpsConfig",
  DISABLE_TIER_3_AUTOMATION = "disableTier3Automation",
  DISABLE_TIER_4_AUTOMATION = "disableTier4Automation",
  DISABLE_ARIES_WORKFLOW = "disableAriesWorkflow",
  IS_ACTIVE = "isActive",
  TASK_LOGIC_TAG_NAME_LIST = "taskLogicTagNameList",
  TEAM_DETERMINATION_CRITERIA = "teamDeterminationCriteria",
  DDQ_QUESTION_GENERATION_CHECKBOX = "ddqQuestionGenerationCheckbox",
  DDQ_LOGIC_TAG_NAME_LIST = "ddqLogicTagNameList",
  DDQ_QUESTION_CRITERIA_SET_CHECKBOX = "ddqQuestionCriteriaSetCheckbox",
  DDQ_LOGIC_CRITERIA_SET_LISTS = "ddqLogicCriteriaSetLists",
  DDQ_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS = "ddqLogicCriteriaSetsTagNamesLists",
  TASKS_GENERATION_CHECKBOX = "tasksGenerationCheckbox",
  TASK_LOGIC_CRITERIA_SET_LISTS = "taskLogicCriteriaSetLists",
  TASKS_CRITERIA_SET_CHECKBOX = "tasksCriteriaSetCheckbox",
  TASK_LOGIC_CRITERIA_SETS_TAG_NAMES_LISTS = "taskLogicCriteriaSetsTagNamesLists",
  LAST_UPDATED = "lastUpdated",
  UPDATED_BY = "updatedBy",
}

export enum TASK_TABLE_COLUMN_HEADINGS {
  TASK_NAME_COLUMN_HEADING = "task_name_column_heading",
  SUMMARY = "summary",
  DESCRIPTION = "description",
  SEVERITY = "severity",
  DUE_DATE = "due_date",
  TAG_NAMES = "tag_names",
  IS_ACTIVE = "is_active",
  ISSUE_TITLE = "issue_title",
  LAST_UPDATED = "last_updated",
  UPDATED_BY = "updated_by",
  LAST_UPDATED_DATE = "last_updated_date",
}

export enum TAG_TABLE_COLUMN_HEADINGS {
  TAG_NAME_COLUMN_HEADING = "tag_name_column_heading",
  DESCRIPTION = "description",
  IS_ACTIVE = "is_active",
  LAST_UPDATED = "last_updated",
  UPDATED_BY = "updated_by",
  LAST_UPDATED_DATE = "last_updated_date",
}

export enum SecurityTeamTableColumnHeadings {
  SECURITY_TEAM_COLUMN_HEADINGS = "security_team_name_column_heading",
  TASK_TAG_NAMES = "tag_names",
  DISABLE_TIER_3_AUTOMATION = "disable_tier_3_automation",
  DISABLE_TIER_4_AUTOMATION = "disable_tier_4_automation",
  IS_ACTIVE = "is_active",
  LAST_UPDATED = "last_updated",
  UPDATED_BY = "updated_by",
}

export enum TASKS_FORM_STRINGS {
  //Form Description
  EDIT_TASK = "edit_task",
  NEW_TASK = "new_task",

  //Form field labels
  TASK_NAME = "task_name",
  SUMMARY = "summary",
  DESCRIPTION = "description",
  SEVERITY = "severity",
  DUE_DATE = "due_date",
  TAG_NAMES = "tag_names",
  IS_ACTIVE = "is_active",
  ISSUE_TITLE = "issue_title",
  ISSUE_DESCRIPTION = "issue_description",
  WHY_ISSUE_IS_BAD = "why_issue_is_bad",
  HOW_TO_FIX_IT = "how_to_fix_it",
  REQUESTED_EVIDENCE = "requested_evidence",

  //Form fields constraint
  TASK_NAME_CONSTRAINT = "task_name_constraint",
  DUE_DATE_CONSTRAINT = "due_date_constraint",

  //Form fields description
  DUE_DATE_DESCRIPTION = "due_date_description",

  //Form fields placeholder
  SEVERITY_PLACEHOLDER = "severity_placeholder",
  TAGS_PLACEHOLDER = "tags_placeholder",

  //Tags Form Fields Messages
  TAGS_EMPTY_MESSAGE = "tags_empty_message",
  TAGS_LOADING_MESSAGE = "tags_loading_message",
  TAGS_LOADING_ERROR_MESSAGE = "tags_loading_error_message",
  TAGS_RECOVERY_TEXT = "tags_recovery_text",
  TAGS_FINISHED_TEXT = "tags_finished_text",

  //Form field buttons
  INFO_BUTTON_TEXT = "info_button_text",
  SAVE_TASK = "save_task",
  CANCEL = "cancel",

  //Form field error validation text's
  ERROR_MESSAGE_REQUIRED = "error_message",
  ERROR_MESSAGE_TASK_NAME_UNIQUE = "error_message_task_name_unique",
  ERROR_MESSAGE_DUE_DATE_POSITIVE_NUMBER = "error_message_due_date_positive_number",
  ERROR_MESSAGE_DUE_DATE_INTEGER = "error_message_due_date_integer",
  ERROR_MESSAGE_TASK_NAME_SIZE_LIMITATIONS = "error_message_task_name_size_limitations",
  ERROR_MESSAGE_TASK_TEXT_INPUT_FIELD_CHARACTER_LIMITATIONS = "error_message_task_text_input_field_character_limitations",
}

export enum TAGS_FORM_STRINGS {
  //Form Description
  EDIT_TAG = "edit_tag",
  NEW_TAG = "new_tag",

  //Form field labels
  TAG_NAME = "tag_name",
  DESCRIPTION = "description",
  IS_ACTIVE = "is_active",

  //Form fields constraint
  TAG_NAME_CONSTRAINT = "tag_name_constraint",

  //Form fields placeholder
  TAGS_PLACEHOLDER = "tags_placeholder",

  //Form field buttons
  INFO_BUTTON_TEXT = "info_button_text",
  SAVE_TAG = "save_tag",
  CANCEL = "cancel",

  //Form field error validation text's
  ERROR_MESSAGE_REQUIRED = "error_message",
  ERROR_MESSAGE_TAG_NAME_UNIQUE = "error_message_tag_name_unique",
  ERROR_MESSAGE_TAG_NAME_SIZE_LIMITATIONS = "error_message_tag_name_size_limitations",
  ERROR_MESSAGE_TAG_TEXT_INPUT_FIELD_CHARACTER_LIMITATIONS = "error_message_tag_text_input_field_character_limitations",
}

export enum SecurityTeamFormStrings {
  //Security Team Config Page Basic Information container strings
  SECURITY_TEAM_BASIC_INFORMATION_CONTAINER_HEADER = "security_team_basic_information_container_header",
  SECURITY_TEAM_BASIC_INFORMATION_SECURITY_TEAM_NAME_LABEL = "security_team_basic_information_security_team_name_label",
  SECURITY_TEAM_BASIC_INFORMATION_SECURITY_TEAM_NAME_CONSTRAINT_TEXT = "security_team_basic_information_security_team_name_constraint_text",
  SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_LABEL = "security_team_basic_information_permission_groups_label",
  SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_DESCRIPTION = "security_team_basic_information_permission_groups_description",
  SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_LDAP_GROUP_LABEL = "security_team_basic_information_permission_groups_ldap_group_label",
  SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_SERVICE_NOW_ROLE_MULTISELCT_LABEL = "security_team_basic_information_permission_groups_service_now_role_multiselect_label",
  SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_SERVICE_NOW_ROLE_MULTISELCT_PLACEHOLDER_TEXT = "security_team_basic_information_permission_groups_service_now_role_multiselect_placeholder_text",
  SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_REMOVE_BTN_TEXT = "security_team_basic_information_permission_groups_remove_btn_text",
  SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_ADD_PERMISSION_GROUP_BTN_TEXT = "security_team_basic_information_permission_groups_add_permission_group_btn_text",
  SECURITY_TEAM_BASIC_INFORMATION_PERMISSION_GROUPS_EMPTY_MESSAGE = "security_team_basic_information_permission_groups_empty_message",
  SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TPS_CONFIG_TOGGLE_BTN_LABEL = "security_team_basic_information_disable_tps_config_toggle_btn_label",
  SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TPS_CONFIG_TOGGLE_BTN_DESCRIPTION = "security_team_basic_information_disable_tps_config_toggle_btn_description",
  SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_3_AUTOMATION_TOGGLE_BTN_LABEL = "security_team_basic_information_disable_tier_3_automation_toggle_btn_label",
  SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_3_AUTOMATION_TOGGLE_BTN_DESCRIPTION = "security_team_basic_information_disable_tier_3_automation_toggle_btn_description",
  SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_4_AUTOMATION_TOGGLE_BTN_LABEL = "security_team_basic_information_disable_tier_4_automation_toggle_btn_label",
  SECURITY_TEAM_BASIC_INFORMATION_DISABLE_TIER_4_AUTOMATION_TOGGLE_BTN_DESCRIPTION = "security_team_basic_information_disable_tier_4_automation_toggle_btn_description",
  SECURITY_TEAM_BASIC_INFORMATION_IS_ACTIVE_AUTOMATION_TOGGLE_BTN_LABEL = "security_team_basic_information_is_active_automation_toggle_btn_label",
  SECURITY_TEAM_BASIC_INFORMATION_IS_ACTIVE_AUTOMATION_TOGGLE_BTN_DESCRIPTION = "security_team_basic_information_is_active_automation_toggle_btn_description",
  SECURITY_TEAM_BASIC_INFORMATION_DISABLE_ARIES_WORKFLOW_TOGGLE_BTN_LABEL = "security_team_basic_information_disable_aries_workflow_toggle_btn_label",
  SECURITY_TEAM_BASIC_INFORMATION_DISABLE_ARIES_WORKFLOW_TOGGLE_BTN_DESCRIPTION = "security_team_basic_information_disable_aries_workflow_toggle_btn_description",

  //Security Team Config Page Team Determination container strings
  SECURITY_TEAM_DETERMINATION_CONTAINER_HEADER = "security_team_determination_container_header",
  SECURITY_TEAM_DETERMINATION_CONTAINER_HEADER_DESCRIPTION = "security_team_determination_container_header_description",
  SECURITY_TEAM_DETERMINATION_PROPERTY_FILTER_OTHER_GROUP_LABEL_TEXT = "security_team_determination_property_filter_other_group_label_text",
  SECURITY_TEAM_DETERMINATION_REQUESTERS_SENIOR_LEADER = "security_team_determination_requesters_senior_leader",

  //Security Team Config Page Task Generation logic container strings
  SECURITY_TEAM_TASK_GENERATION_LOGIC_CONTAINER_HEADER = "security_team_task_generation_logic_container_header",
  SECURITY_TEAM_TASK_GENERATION_LOGIC_CONTAINER_CHECKBOX_LABEL = "security_team_task_generation_logic_container_checkbox_label",
  SECURITY_TEAM_TASK_GENERATION_LOGIC_CONTAINER_TASK_CRITERIA_SET_ENABLE_CHECKBOX_LABEL = "security_team_task_generation_logic_container_task_criteria_set_enable_checkbox_label",
  SECURITY_TEAM_TASK_GENERATION_LOGIC_CONTAINER_TASK_CRITERIA_SET_HEADING = "security_team_task_generation_logic_container_task_criteria_set_heading",
  SECURITY_TEAM_TASK_GENERATION_LOGIC_CONTAINER_TASK_CRITERIA_SET_EMPTY_TEXT = "security_team_task_generation_logic_container_task_criteria_set_empty_text",
  SECURITY_TEAM_TASK_GENERATION_LOGIC_CONTAINER_TASK_CRITERIA_SET_TAG_NAMES_FIELD_DESCRIPTION = "security_team_task_generation_logic_container_task_criteria_set_tag_names_field_description",

  //Security Team Config Page Question Generation logic container strings
  SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_HEADER = "security_team_question_generation_logic_container_header",
  SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_CHECKBOX_LABEL = "security_team_question_generation_logic_container_checkbox_label",
  SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_ENABLE_CHECKBOX_LABEL = "security_team_question_generation_logic_container_question_criteria_set_enable_checkbox_label",
  SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_HEADING = "security_team_question_generation_logic_container_question_criteria_set_heading",
  SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_EMPTY_TEXT = "security_team_question_generation_logic_container_question_criteria_set_empty_text",
  SECURITY_TEAM_QUESTION_GENERATION_LOGIC_CONTAINER_QUESTION_CRITERIA_SET_TAG_NAMES_FIELD_DESCRIPTION = "security_team_question_generation_logic_container_question_criteria_set_tag_names_field_description",

  //Security Team Config Page Form Action buttons strings
  SECURITY_TEAM_FORM_CANCEL_BUTTON_TEXT = "security_team_form_cancel_button_text",
  SECURITY_TEAM_CREATE_FORM_SAVE_BUTTON_TEXT = "security_team_create_form_save_button_text",
  SECURITY_TEAM_EDIT_FORM_SAVE_BUTTON_TEXT = "security_team_edit_form_save_button_text",

  //Security Team Config Page common strings
  SECURITY_TEAM_INTAKE_QUESTIONS_EMPTY_MESSAGE = "security_team_intake_questions_empty_message",
  SECURITY_TEAM_INTAKE_QUESTIONS_LOADING_MESSAGE = "security_team_intake_questions_loading_message",
  SECURITY_TEAM_INTAKE_QUESTIONS_LOADING_ERROR_MESSAGE = "security_team_intake_questions_loading_error_message",
  SECURITY_TEAM_INTAKE_QUESTIONS_FINISHED_TEXT = "security_team_intake_questions_finished_text",
  SECURITY_TEAM_INTAKE_QUESTIONS_FIELD_PLACEHOLDER_TEXT = "security_team_intake_questions_field_placeholder_text",
  SECURITY_TEAM_RECOVERY_TEXT = "security_team_recovery_text",
  SECURITY_TEAM_TAG_NAMES_FIELD_PLACEHOLDER_TEXT = "security_team_tag_names_field_placeholder_text",
  SECURITY_TEAM_TAGS_EMPTY_MESSAGE = "security_team_tags_empty_message",
  SECURITY_TEAM_TAGS_LOADING_MESSAGE = "security_team_tags_loading_message",
  SECURITY_TEAM_TAGS_LOADING_ERROR_MESSAGE = "security_team_tags_loading_error_message",
  SECURITY_TEAM_TAGS_FINISHED_TEXT = "security_team_tags_finished_text",
  SECURITY_TEAM_CRITERIA_SET_REMOVE_BTN_TEXT = "security_team_criteria_set_remove_btn_text",
  SECURITY_TEAM_INTAKE_QUESTIONS_GROUP_LABEL_TEXT = "security_team_property_filter_intake_questions_group_label_text",
  SECURITY_TEAM_TIER_GROUP_LABEL_TEXT = "security_team_tier_group_label_text",
  SECURITY_TEAM_TIER_LABEL_TEXT = "security_team_tier_label_text",
  SECURITY_TEAM_EDIT_FORM_FALLBACK_DISPLAY_TEXT = "security_team_edit_form_fallback_display_text",
  SECURITY_TEAM_INPUT_REQUIRED_ERROR_MESSAGE = "security_team_input_required_error_message",
  SECURITY_TEAM_NAME_UNIQUE_ERROR_MESSAGE = "security_team_name_unique_error_message",
  SECURITY_TEAM_FILTER_TOKEN_EMPTY_ERROR_MESSAGE = "security_team_filter_token_empty_error_message",
  SECURITY_TEAM_NAME_SIZE_LIMITATIONS_ERROR_MESSAGE = "security_team_name_size_limitations_error_message",
  SECURITY_TEAM_NAME_CHARACTER_LIMITATIONS_ERROR_MESSAGE = "security_team_name_character_limitations_error_message",
}

//While adding adding API's add neccessary enums here for successful and failed API's execution
//So we can use them in flashbar component
export enum FLASH_BAR_MESSAGES {
  //Tasks CRUD Operations
  TASK_CREATION_SUCCESSFUL = "task_creation_successful",
  TASK_CREATION_FAILED = "task_creation_failed",
  TASKS_FETCH_SUCCESSFUL = "tasks_fetch_successful",
  TASKS_FETCH_FAILED = "tasks_fetch_failed",
  TASK_UPDATE_SUCCESSFUL = "task_update_successful",
  TASK_UPDATE_FAILED = "task_update_failed",
  TASK_DELETE_SUCCESSFUL = "task_delete_successful",
  TASKS_DELETE_SUCCESSFUL = "tasks_delete_successful",
  TASK_DELETE_FAILED = "task_delete_failed",
  TASKS_DELETE_FAILED = "tasks_delete_failed",

  //Tags CRUD Operations
  TAG_CREATION_SUCCESSFUL = "tag_creation_successful",
  TAG_CREATION_FAILED = "tag_creation_failed",
  TAGS_FETCH_SUCCESSFUL = "tags_fetch_successful",
  TAGS_FETCH_FAILED = "tags_fetch_failed",
  TAG_UPDATE_SUCCESSFUL = "tag_update_successful",
  TAG_UPDATE_FAILED = "tag_update_failed",
  TAG_DELETE_SUCCESSFUL = "tag_delete_successful",
  TAGS_DELETE_SUCCESSFUL = "tags_delete_successful",
  TAG_DELETE_FAILED = "tag_delete_failed",
  TAGS_DELETE_FAILED = "tags_delete_failed",

  //Security Teams CRUD Operations
  SECURITY_TEAM_CREATION_SUCCESSFUL = "security_team_creation_successful",
  SECURITY_TEAM_CREATION_FAILED = "security_team_creation_failed",
  SECURITY_TEAMS_FETCH_SUCCESSFUL = "security_teams_fetch_successful",
  SECURITY_TEAMS_FETCH_FAILED = "security_teams_fetch_failed",
  SECURITY_TEAM_UPDATE_SUCCESSFUL = "security_team_update_successful",
  SECURITY_TEAM_UPDATE_FAILED = "security_team_update_failed",
  SECURITY_TEAM_DELETE_SUCCESSFUL = "security_team_delete_successful",
  SECURITY_TEAMS_DELETE_SUCCESSFUL = "security_teams_delete_successful",
  SECURITY_TEAM_DELETE_FAILED = "security_team_delete_failed",
  SECURITY_TEAM_FETCH_SUCCESSFUL = "security_team_fetch_successful",
  SECURITY_TEAM_FETCH_FAILED = "security_team_fetch_failed",
  SECURITY_TEAM_INTAKE_QUESTIONS_FETCH_SUCCESSFUL = "security_team_intake_questions_fetch_successful",
  SECURITY_TEAM_INTAKE_QUESTIONS_FETCH_FAILED = "security_team_intake_questions_fetch_failed",
  SECURITY_TEAMS_DELETE_FAILED = "security_teams_delete_failed",
  NONE = "none",
}

export enum FLASHBAR_MESSAGE_TYPE {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
}

export enum API_KEYS {
  GET_TAGS = "getTags",
  CREATE_TAG = "createTag",
  UPDATE_TAG = "updateTag",
  DELETE_TAGS = "deleteTags",
  GET_TASKS = "getTask",
  CREATE_TASK = "createTask",
  UPDATE_TASK = "updateTask",
  DELETE_TASKS = "deleteTasks",
  GET_SECURITY_TEAMS = "getSecurityTeams",
  DELETE_SECURITY_TEAMS = "deleteSecurityTeams",
  GET_SECURITY_TEAM = "getSecurityTeam",
  GET_INTAKE_QUESTIONS = "getIntakeQuestions",
  CREATE_SECURITY_TEAM = "createSecurityTeam",
  UPDATE_SECURITY_TEAM = "updateSecurityTeam",
}

export enum CLOUDSCAPE_STATUS_TYPE {
  PENDING = "pending",
  LOADING = "loading",
  FINISHED = "finished",
  ERROR = "error",
}

export enum Stage {
  BETA = "beta",
  GAMMA = "gamma",
  PROD = "prod",
}

export enum TagFieldsFilterText {
  TAG_FIELDS_FILTER_LOADING_TEXT = "tag_fields_filter_loading_text",
  TAG_FIELDS_FILTER_ERROR_TEXT = "tag_fields_filter_error_text",
  TAG_FIELDS_FILTER_RECOVERY_TEXT = "tag_fields_filter_recovery_text",
  TAG_FIELDS_FILTER_FINISHED_TEXT = "tag_fields_filter_finished_text",
  TAG_FIELDS_FILTER_EMPTY_TEXT = "tag_fields_filter_empty_text",
}

export enum TaskFieldsFilterText {
  TASK_FIELDS_FILTER_LOADING_TEXT = "task_fields_filter_loading_text",
  TASK_FIELDS_FILTER_ERROR_TEXT = "task_fields_filter_error_text",
  TASK_FIELDS_FILTER_RECOVERY_TEXT = "task_fields_filter_recovery_text",
  TASK_FIELDS_FILTER_FINISHED_TEXT = "task_fields_filter_finished_text",
  TASK_FIELDS_FILTER_EMPTY_TEXT = "task_fields_filter_empty_text",
}

export enum SecurityTeamFieldsFilterText {
  SECURITY_TEAM_FIELDS_FILTER_LOADING_TEXT = "security_team_fields_filter_loading_text",
  SECURITY_TEAM_FIELDS_FILTER_ERROR_TEXT = "security_team_fields_filter_error_text",
  SECURITY_TEAM_FIELDS_FILTER_RECOVERY_TEXT = "security_team_fields_filter_recovery_text",
  SECURITY_TEAM_FIELDS_FILTER_FINISHED_TEXT = "security_team_fields_filter_finished_text",
  SECURITY_TEAM_FIELDS_FILTER_EMPTY_TEXT = "security_team_fields_filter_empty_text",
}

export interface PropertyFilterProps {
  propertyLabel: string;
  key: string;
  groupValuesLabel: string;
  operators: string[];
  group: string;
  defaultOperator?: string;
}

export interface Select {
  label: string;
  value: string;
}

export enum TextInputFieldValidationType {
  UNIQUE = "unique",
  SPECIAL_CHARACTERS = "special_characters",
  CHARACTERS_SIZE = "characters_size",
}
