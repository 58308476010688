import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { getSecurityTeamById } from "../graphql/queries";
import {
  GetSecurityTeamByIdQuery,
  SecurityTeam,
  GetSecurityTeamByIdRequest,
} from "../API";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { retryConfig, cacheConfig } from "../APIUtils";
import {
  API_KEYS,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

/**
 * A custom React Query hook for fetching security team.
 *
 * @param securityTeamId - The Security Team Dynamo DB Record Id to retrieve
 * @returns The result of the query hook.
 */
export const useGetSecurityTeam = (
  input: GetSecurityTeamByIdRequest
): UseQueryResult<SecurityTeam> => {
  const flashbarContext = useFlashBarContext();

  return useQuery({
    queryKey: [API_KEYS.GET_SECURITY_TEAM, input.securityTeamId],
    queryFn: async () => {
      const fetchResponse = await API.graphql<
        GraphQLQuery<GetSecurityTeamByIdQuery>
      >(
        graphqlOperation(getSecurityTeamById, {
          input: input,
        })
      );

      return fetchResponse.data?.getSecurityTeamById;
    },

    ...retryConfig,

    ...cacheConfig,

    onError: () => {
      //Display Query status using FlashBar message in security team config page for failed condition
      flashbarContext.setflashBarMessageOnPage!(
        PAGE_TITLE.SECURITY_TEAM_CONFIG
      );

      //Display error FlashBar Message for fetch failed
      flashbarContext.setFlashBarMessage!(
        FLASH_BAR_MESSAGES.SECURITY_TEAM_FETCH_FAILED
      );
    },

    enabled: !!input.securityTeamId,
  });
};
