import {
  PropertyFilterOperation,
  PropertyFilterQuery,
  PropertyFilterToken,
} from "@cloudscape-design/collection-hooks";
import { CLOUDSCAPE_STATUS_TYPE, Stage } from "../constants/constants";
import { htmlDecode, htmlEncode } from "js-htmlencode";
import {
  BackendToDisplay,
  CriteriaSetValue,
  CriteriaSetValueTokens,
  DisplayToBackend,
  REQUESTERS_SENIOR_LEADER_PROPERTY_KEY,
} from "../pages/securityTeamConfigPage/SecurityTeamConfigPage";
import { CriteriaOutput } from "src/api/API";

//This util will return different status type so CloudScape components can display UI and text based on API response and status accordingly
export const getStatusType = (
  isLoading: boolean,
  isSuccess: boolean
): CLOUDSCAPE_STATUS_TYPE => {
  if (isLoading) return CLOUDSCAPE_STATUS_TYPE.LOADING;
  if (!isSuccess) return CLOUDSCAPE_STATUS_TYPE.ERROR;
  return CLOUDSCAPE_STATUS_TYPE.FINISHED;
};

const getStage = (): string => "prod";

export const convertBackendToDisplay = (
  criteriaOutput: CriteriaOutput,
  backendToDisplay: BackendToDisplay
) => {
  const displayValues =
    backendToDisplay[`${criteriaOutput.questionId}_${criteriaOutput.answer}`];
  // Due to messiness of Beta, we will allow display values to continue to be used for backwards compatibility
  // We can remove this fall back logic if Beta is cleaned up
  if (getStage() === Stage.BETA)
    return (
      displayValues ?? {
        questionDisplay: criteriaOutput.questionId,
        answerDisplay: criteriaOutput.answer,
      }
    );
  return displayValues;
};

//This Util will filter out duplicate tokens selected by the user
export const dedupeTokens = (tokens: CriteriaSetValueTokens) => {
  const filterDuplicateTokensSet = new Set(); //Prevents user from selecting duplicate intake questions filter tokens

  return tokens.filter((token) => {
    const filterTokenSet = `${token.questionId}${token.operator}${token.answer}`;
    const isFilterTokenExist = filterDuplicateTokensSet.has(filterTokenSet);
    if (isFilterTokenExist) return !isFilterTokenExist;
    filterDuplicateTokensSet.add(filterTokenSet);
    return !isFilterTokenExist;
  });
};

export const convertDisplayToBackendTokens = (
  tokens: readonly PropertyFilterToken[],
  displayToBackend: DisplayToBackend
): CriteriaSetValueTokens =>
  tokens.map((token) => {
    let questionId: string;
    let answer: string;
    if (token.propertyKey === REQUESTERS_SENIOR_LEADER_PROPERTY_KEY) {
      questionId = token.propertyKey!;
      answer = token.value as string;
    } else {
      questionId =
        displayToBackend[`${token.propertyKey}_${token.value}`]?.questionId;
      answer = displayToBackend[`${token.propertyKey}_${token.value}`]?.answer;
    }
    // Due to messiness of Beta, we will allow display values to continue to be used for backwards compatibility
    // We can remove this fall back logic if Beta is cleaned up
    if (getStage() === Stage.BETA) {
      questionId = questionId ?? token.propertyKey!;
      answer = answer ?? (token.value as string);
    }

    return {
      operator: token.operator,
      questionDisplay: token.propertyKey!,
      answerDisplay: token.value as string,
      questionId,
      answer,
    };
  });

export const convertBackendToDisplayTokens = (
  criteriaSetValue: CriteriaSetValue
): PropertyFilterQuery => {
  return {
    operation: criteriaSetValue.operation,
    tokens: criteriaSetValue.tokens.map((token) => ({
      propertyKey: token.questionDisplay,
      value: token.answerDisplay,
      operator: token.operator,
    })),
  };
};

export const decodeString = (text: string | null): string =>
  text ? htmlDecode(text) : "";
export const encodeString = (text: string | null): string =>
  text ? htmlEncode(text) : "";
