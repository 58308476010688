/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createTag =
  /* GraphQL */ `mutation CreateTag($input: CreateTagRequest!) {
  createTag(input: $input) {
    intakeId
    tagName
    securityTeam
    description
    isActive
    comments
    lastUpdated
    updatedBy
    tagId
    tier
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateTagMutationVariables,
    APITypes.CreateTagMutation
  >;
export const updateTag =
  /* GraphQL */ `mutation UpdateTag($input: UpdateTagRequest) {
  updateTag(input: $input) {
    intakeId
    tagName
    securityTeam
    description
    isActive
    comments
    lastUpdated
    updatedBy
    tagId
    tier
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateTagMutationVariables,
    APITypes.UpdateTagMutation
  >;
export const deleteTag = /* GraphQL */ `mutation DeleteTag($tagId: String!) {
  deleteTag(tagId: $tagId) {
    intakeId
    tagName
    securityTeam
    description
    isActive
    comments
    lastUpdated
    updatedBy
    tagId
    tier
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagMutationVariables,
  APITypes.DeleteTagMutation
>;
export const deleteTags =
  /* GraphQL */ `mutation DeleteTags($input: DeleteTagsRequest!) {
  deleteTags(input: $input) {
    intakeId
    tagName
    securityTeam
    description
    isActive
    comments
    lastUpdated
    updatedBy
    tagId
    tier
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteTagsMutationVariables,
    APITypes.DeleteTagsMutation
  >;
export const createTask =
  /* GraphQL */ `mutation CreateTask($input: CreateTaskRequest!) {
  createTask(input: $input) {
    taskName
    description
    howToFixIt
    issueDescription
    issueTitle
    requestedEvidence
    severity
    state
    summary
    tagNames
    title
    whyIssueIsBad
    type
    lastUpdated
    updatedBy
    taskId
    dueDate
    isActive
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateTaskMutationVariables,
    APITypes.CreateTaskMutation
  >;
export const updateTask =
  /* GraphQL */ `mutation UpdateTask($input: UpdateTaskRequest) {
  updateTask(input: $input) {
    taskName
    description
    howToFixIt
    issueDescription
    issueTitle
    requestedEvidence
    severity
    state
    summary
    tagNames
    title
    whyIssueIsBad
    type
    lastUpdated
    updatedBy
    taskId
    dueDate
    isActive
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateTaskMutationVariables,
    APITypes.UpdateTaskMutation
  >;
export const deleteTask = /* GraphQL */ `mutation DeleteTask($taskId: String!) {
  deleteTask(taskId: $taskId) {
    taskName
    description
    howToFixIt
    issueDescription
    issueTitle
    requestedEvidence
    severity
    state
    summary
    tagNames
    title
    whyIssueIsBad
    type
    lastUpdated
    updatedBy
    taskId
    dueDate
    isActive
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskMutationVariables,
  APITypes.DeleteTaskMutation
>;
export const deleteTasks =
  /* GraphQL */ `mutation DeleteTasks($input: DeleteTasksRequest!) {
  deleteTasks(input: $input) {
    taskName
    description
    howToFixIt
    issueDescription
    issueTitle
    requestedEvidence
    severity
    state
    summary
    tagNames
    title
    whyIssueIsBad
    type
    lastUpdated
    updatedBy
    taskId
    dueDate
    isActive
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteTasksMutationVariables,
    APITypes.DeleteTasksMutation
  >;
export const createSecurityTeam =
  /* GraphQL */ `mutation CreateSecurityTeam($input: CreateSecurityTeamRequest!) {
  createSecurityTeam(input: $input) {
    securityTeamId
    teamName
    teamDescription
    securityTeamSeniorLeaders
    taskTagNames
    ddqTagNames
    ldapWithSnowRole {
      teamLdapGroup
      serviceNowRole
      __typename
    }
    disableTier3Automation
    disableTier4Automation
    disableAriesWorkflow
    snowGroupName
    isActive
    disableTpsConfig
    lastUpdated
    updatedBy
    criteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    taskCriteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    ddqCriteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateSecurityTeamMutationVariables,
    APITypes.CreateSecurityTeamMutation
  >;
export const updateSecurityTeam =
  /* GraphQL */ `mutation UpdateSecurityTeam($input: UpdateSecurityTeamRequest) {
  updateSecurityTeam(input: $input) {
    securityTeamId
    teamName
    teamDescription
    securityTeamSeniorLeaders
    taskTagNames
    ddqTagNames
    ldapWithSnowRole {
      teamLdapGroup
      serviceNowRole
      __typename
    }
    disableTier3Automation
    disableTier4Automation
    disableAriesWorkflow
    snowGroupName
    isActive
    disableTpsConfig
    lastUpdated
    updatedBy
    criteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    taskCriteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    ddqCriteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateSecurityTeamMutationVariables,
    APITypes.UpdateSecurityTeamMutation
  >;
export const deleteSecurityTeam =
  /* GraphQL */ `mutation DeleteSecurityTeam($securityTeamId: String!) {
  deleteSecurityTeam(securityTeamId: $securityTeamId)
}
` as GeneratedMutation<
    APITypes.DeleteSecurityTeamMutationVariables,
    APITypes.DeleteSecurityTeamMutation
  >;
export const deleteSecurityTeams =
  /* GraphQL */ `mutation DeleteSecurityTeams($input: DeleteSecurityTeamsRequest!) {
  deleteSecurityTeams(input: $input) {
    securityTeamId
    teamName
    teamDescription
    securityTeamSeniorLeaders
    taskTagNames
    ddqTagNames
    ldapWithSnowRole {
      teamLdapGroup
      serviceNowRole
      __typename
    }
    disableTier3Automation
    disableTier4Automation
    disableAriesWorkflow
    snowGroupName
    isActive
    disableTpsConfig
    lastUpdated
    updatedBy
    criteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    taskCriteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    ddqCriteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteSecurityTeamsMutationVariables,
    APITypes.DeleteSecurityTeamsMutation
  >;
