import { WithBundleProps, withBundle } from "@amzn/react-arb-tools";
import CloudScapeModal from "@cloudscape-design/components/modal";
import CloudScapeBox from "@cloudscape-design/components/box";
import CloudSpaceBetween from "@cloudscape-design/components/space-between";
import CloudScapeAlert from "@cloudscape-design/components/alert";
import { OrangeButton } from "../Buttons/CustomizedButtons";
import { LdapUpdates, getUrl } from "../../utils/ModalUtils";

interface TicketModalProps extends WithBundleProps {
  ldapUpdates: LdapUpdates;
  closeModal: () => void;
}

export const TicketModal = withBundle("components.TicketModal")(
  ({ ldapUpdates, closeModal, bundle }: TicketModalProps) => {
    const isLdapUpdatesEmpty = ldapUpdates?.updates.size === 0;
    if (isLdapUpdatesEmpty) closeModal();

    const openTicket = () => {
      ldapUpdates!.callback();
      window.open(getUrl(ldapUpdates!), "_blank")!.focus();
      closeModal();
    };

    const footer = (
      <CloudScapeBox float="right">
        <CloudSpaceBetween direction="horizontal" size="xs">
          <OrangeButton variant="primary" onClick={openTicket}>
            {bundle.getMessage("ticket_modal_create_tt_button_text")}
          </OrangeButton>
        </CloudSpaceBetween>
      </CloudScapeBox>
    );

    return (
      <CloudScapeModal
        onDismiss={(event) =>
          event.detail.reason === "closeButton" && closeModal()
        }
        visible={!!ldapUpdates && !isLdapUpdatesEmpty}
        footer={footer}
        header={bundle.getMessage("ticket_modal_ldap_group_changes_header")}
        data-testid="ticketing-modal"
      >
        <CloudScapeAlert statusIconAriaLabel="Warning" type="warning">
          {bundle.getMessage("ticket_modal_ldap_group_changes_body")}
        </CloudScapeAlert>
      </CloudScapeModal>
    );
  }
);
