import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { Auth } from "aws-amplify";
import LoadingSpinner from "@cloudscape-design/components/spinner";
import { BASE_URL } from "../constants/urlConstants";
import { DEEP_LINK } from "src/constants/constants";

export type UserMetadata = {
  alias: string;
};

// The initial value is not used as UserProvider is only rendered once user data is populated
const UserContext = createContext<UserMetadata>({
  alias: "",
});

const useUserContext = () => useContext(UserContext);

export const EXCLUDED_DEEP_LINKS_URL_SET = new Set([BASE_URL, `${BASE_URL}/`]); //this are expected url's we encounter on initial user login so created an exclude set so this should not impact record specific deeplinks

function UserProvider({ children }: { children: ReactElement }): JSX.Element {
  const [userMetadata, setUserMetadata] = useState<UserMetadata | null>(null);

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authenticatedUser) => {
        if (process.env.NODE_ENV === "development")
          console.log("Cognito User: ", authenticatedUser);
        setUserMetadata({
          alias: `${authenticatedUser.getUsername().split("_")[1]}`,
        });
      })
      .catch(() => {
        //storing deeplink information before redirecting to federate to consume after authentication
        //adding condition here so deepLink value is not overridden incase of any redirection url encoutered during login
        if (!EXCLUDED_DEEP_LINKS_URL_SET.has(window.location.href)) {
          window.localStorage.setItem(DEEP_LINK, window.location.href);
        }

        Auth.federatedSignIn({ customProvider: "AmazonFederated" }).catch(
          (err) => {
            console.log(err);
          }
        );
      });
  }, []);

  if (!userMetadata) {
    return (
      <LoadingSpinner size="large" data-testid="login-page-loading-indicator" />
    );
  }

  return (
    <UserContext.Provider value={userMetadata}>{children}</UserContext.Provider>
  );
}

export { UserProvider, useUserContext };
