import {
  FLASH_BAR_MESSAGES,
  FLASHBAR_MESSAGE_TYPE,
  PAGE_TITLE,
} from "../constants/constants";
import {
  TAGS_PAGE,
  TASKS_PAGE,
  SECURITY_TEAMS_PAGE,
  SECURITY_TEAM_CONFIG_PAGE,
} from "../constants/linkConstants";
import { FlashbarProps as CloudScapeFlashbarProps } from "@cloudscape-design/components/flashbar";

//Security Teams Page Specific error cases
export const SECURITY_TEAMS_ERROR_CASES: ReadonlyArray<FLASH_BAR_MESSAGES> = [
  FLASH_BAR_MESSAGES.SECURITY_TEAMS_FETCH_FAILED,
  FLASH_BAR_MESSAGES.SECURITY_TEAMS_DELETE_FAILED,
  FLASH_BAR_MESSAGES.SECURITY_TEAM_DELETE_FAILED,
  //todo add more security teams page error case as we integrate more API's
];

//Security Teams Page Specific success cases
export const SECURITY_TEAMS_SUCCESS_CASES: ReadonlyArray<FLASH_BAR_MESSAGES> = [
  FLASH_BAR_MESSAGES.SECURITY_TEAMS_FETCH_SUCCESSFUL,
  FLASH_BAR_MESSAGES.SECURITY_TEAMS_DELETE_SUCCESSFUL,
  FLASH_BAR_MESSAGES.SECURITY_TEAM_DELETE_SUCCESSFUL,
  FLASH_BAR_MESSAGES.SECURITY_TEAM_CREATION_SUCCESSFUL,
  FLASH_BAR_MESSAGES.SECURITY_TEAM_UPDATE_SUCCESSFUL,
  //todo add more security teams page success case as we integrate more API's
];

//Security Team Config Page Specific error cases
export const SECURITY_TEAM_CONFIG_ERROR_CASES: ReadonlyArray<FLASH_BAR_MESSAGES> =
  [
    FLASH_BAR_MESSAGES.SECURITY_TEAM_FETCH_FAILED,
    FLASH_BAR_MESSAGES.SECURITY_TEAM_INTAKE_QUESTIONS_FETCH_FAILED,
    FLASH_BAR_MESSAGES.SECURITY_TEAM_CREATION_FAILED,
    FLASH_BAR_MESSAGES.SECURITY_TEAM_UPDATE_FAILED,
    //todo add more security team config page error case as we integrate more API's
  ];

//Security Team Config Page Specific success cases
export const SECURITY_TEAM_CONFIG_SUCCESS_CASES: ReadonlyArray<FLASH_BAR_MESSAGES> =
  [
    FLASH_BAR_MESSAGES.SECURITY_TEAM_FETCH_SUCCESSFUL,
    FLASH_BAR_MESSAGES.SECURITY_TEAM_INTAKE_QUESTIONS_FETCH_SUCCESSFUL,
    //todo add more security team config page success case as we integrate more API's
  ];

//Tasks Page Specific error cases
export const TASKS_ERROR_CASES: ReadonlyArray<FLASH_BAR_MESSAGES> = [
  FLASH_BAR_MESSAGES.TASK_CREATION_FAILED,
  FLASH_BAR_MESSAGES.TASKS_FETCH_FAILED,
  FLASH_BAR_MESSAGES.TASK_UPDATE_FAILED,
  FLASH_BAR_MESSAGES.TASK_DELETE_FAILED,
  FLASH_BAR_MESSAGES.TASKS_DELETE_FAILED,
];

//Tasks Page Specific success cases
export const TASKS_SUCCESS_CASES: ReadonlyArray<FLASH_BAR_MESSAGES> = [
  FLASH_BAR_MESSAGES.TASK_CREATION_SUCCESSFUL,
  FLASH_BAR_MESSAGES.TASKS_FETCH_SUCCESSFUL,
  FLASH_BAR_MESSAGES.TASK_UPDATE_SUCCESSFUL,
  FLASH_BAR_MESSAGES.TASK_DELETE_SUCCESSFUL,
  FLASH_BAR_MESSAGES.TASKS_DELETE_SUCCESSFUL,
];

//Tags Page Specific error cases
export const TAGS_ERROR_CASES: ReadonlyArray<FLASH_BAR_MESSAGES> = [
  FLASH_BAR_MESSAGES.TAG_CREATION_FAILED,
  FLASH_BAR_MESSAGES.TAGS_FETCH_FAILED,
  FLASH_BAR_MESSAGES.TAG_UPDATE_FAILED,
  FLASH_BAR_MESSAGES.TAG_DELETE_FAILED,
  FLASH_BAR_MESSAGES.TAGS_DELETE_FAILED,
];

//Tags Page Specific success cases
export const TAGS_SUCCESS_CASES: ReadonlyArray<FLASH_BAR_MESSAGES> = [
  FLASH_BAR_MESSAGES.TAG_CREATION_SUCCESSFUL,
  FLASH_BAR_MESSAGES.TAGS_FETCH_SUCCESSFUL,
  FLASH_BAR_MESSAGES.TAG_UPDATE_SUCCESSFUL,
  FLASH_BAR_MESSAGES.TAG_DELETE_SUCCESSFUL,
  FLASH_BAR_MESSAGES.TAGS_DELETE_SUCCESSFUL,
];

const FLASHBAR_MESSAGE_ERROR_CASES: ReadonlySet<FLASH_BAR_MESSAGES> =
  new Set<FLASH_BAR_MESSAGES>([
    ...SECURITY_TEAMS_ERROR_CASES,
    ...SECURITY_TEAM_CONFIG_ERROR_CASES,
    ...TASKS_ERROR_CASES,
    ...TAGS_ERROR_CASES,
  ]);

const FLASHBAR_MESSAGE_SUCCESS_CASES: ReadonlySet<FLASH_BAR_MESSAGES> =
  new Set<FLASH_BAR_MESSAGES>([
    ...SECURITY_TEAMS_SUCCESS_CASES,
    ...SECURITY_TEAM_CONFIG_SUCCESS_CASES,
    ...TASKS_SUCCESS_CASES,
    ...TAGS_SUCCESS_CASES,
  ]);

//Function to get FlashBarMessage Type to display UI accordingly like check mark with green for success etc
export const getFlashBarMessageType = (
  flashBarMessage: FLASH_BAR_MESSAGES
): CloudScapeFlashbarProps.Type => {
  if (FLASHBAR_MESSAGE_ERROR_CASES.has(flashBarMessage))
    return FLASHBAR_MESSAGE_TYPE.ERROR as CloudScapeFlashbarProps.Type;
  if (FLASHBAR_MESSAGE_SUCCESS_CASES.has(flashBarMessage))
    return FLASHBAR_MESSAGE_TYPE.SUCCESS as CloudScapeFlashbarProps.Type;
  return FLASHBAR_MESSAGE_TYPE.INFO as CloudScapeFlashbarProps.Type;
};

export const HIDDEN_CASES = [
  FLASH_BAR_MESSAGES.SECURITY_TEAMS_FETCH_SUCCESSFUL,
  FLASH_BAR_MESSAGES.SECURITY_TEAM_FETCH_SUCCESSFUL,
  FLASH_BAR_MESSAGES.SECURITY_TEAM_INTAKE_QUESTIONS_FETCH_SUCCESSFUL,
  FLASH_BAR_MESSAGES.TASKS_FETCH_SUCCESSFUL,
  FLASH_BAR_MESSAGES.TAGS_FETCH_SUCCESSFUL,
  FLASH_BAR_MESSAGES.NONE,
];

//As we had fetching operations we need make sure FlashBar Messages related to this are hidden for better user experience
export const FLASHBAR_MESSAGE_HIDDEN_SET = new Set<FLASH_BAR_MESSAGES>([
  ...HIDDEN_CASES,
]);

export const displayFlashBarMessage = (
  flashBarMessage: FLASH_BAR_MESSAGES
): boolean => {
  return !FLASHBAR_MESSAGE_HIDDEN_SET.has(flashBarMessage);
};

//Todo add config page path while working on it
export function getPageTitleFromPathname(pathname: string) {
  switch (pathname) {
    case TAGS_PAGE.href:
      return PAGE_TITLE.TAGS;
    case TASKS_PAGE.href:
      return PAGE_TITLE.TASKS;
    case SECURITY_TEAMS_PAGE.href:
      return PAGE_TITLE.SECURITY_TEAMS;
    case SECURITY_TEAM_CONFIG_PAGE.href:
      return PAGE_TITLE.SECURITY_TEAM_CONFIG;
    default:
      return null;
  }
}
