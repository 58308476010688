import React, { useEffect, useMemo, useState } from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router";
import { UseQueryResult } from "@tanstack/react-query";
import CloudScapeContentLayout from "@cloudscape-design/components/content-layout";
import CloudScapeForm from "@cloudscape-design/components/form";
import CloudScapeSpaceBetween from "@cloudscape-design/components/space-between";
import CloudScapeButton from "@cloudscape-design/components/button";
import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { UseMutationResult } from "@tanstack/react-query";
import {
  CreateCriteria,
  CreateSecurityTeamMutation,
  CreateSecurityTeamRequest,
  CriteriaOperator,
  CriteriaType,
  ServiceNowRoleType,
  UpdateSecurityTeamMutation,
  UpdateSecurityTeamRequest,
} from "../../api/API";
import { OrangeButton } from "../../components/Buttons/CustomizedButtons";
import {
  RECORD_LIMITS,
  FORM_MODE,
  SecurityTeamFormStrings,
  Select,
  SecurityTeamFormFields,
  SECURITY_CONFIG_TEAM_ID_URL_FRAGMENT,
  PROPERTY_FILTER_OPERATORS_LIST,
  VENDOR_TIER,
  SERVICE_NOW_ROLE_LIST,
} from "../../constants/constants";
import { SECURITY_TEAMS_PAGE } from "../../constants/linkConstants";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { SecurityTeamConfigPageHeader } from "../../components/Header/SecurityTeamConfigPageHeader";
import { useGetIntakeQuestions } from "../../api/securityTeamConfig/getIntakeQuestionsAPI";
import { useGetTags } from "../../api/tags/getTagsAPI";
import { useGetSecurityTeams } from "../../api/securityTeams/getSecurityTeamsAPI";
import { useGetSecurityTeam } from "../../api/securityTeamConfig/getSecurityTeamAPI";
import { useCreateSecurityTeam } from "../../api/securityTeamConfig/createSecurityTeamAPI";
import { useUpdateSecurityTeam } from "../../api/securityTeamConfig/updateSecurityTeamAPI";
import { useBreadCrumbContext } from "../../context/BreadCrumbContextProvider";
import { SecurityTeam } from "../../api/API";
import {
  SecurityTeamBasicInformation,
  SecurityTeamDetermination,
  SecurityTeamTaskGenerationLogic,
  SecurityTeamQuestionGenerationLogic,
} from "./securityTeamFormComponents";
import {
  getLdapUpdates,
  LdapUpdates,
  promptUpdateTicketGenerator,
} from "../../utils/ModalUtils";
import { TicketModal } from "../../components/Modal/TicketModal";
import { SecurityTeamConfigPageFormValidationSchema } from "./SecurityTeamConfigPageFormValidationSchema";
import {
  convertBackendToDisplay,
  decodeString,
  encodeString,
} from "../../utils/CommonUtils";
import { PropertyFilterOperation } from "@cloudscape-design/collection-hooks";

export const SECURITY_TEAM_FORM_CANCEL_BTN__DATA_TEST_ID =
  "security-team-form-cancel-btn";
export const SECURITY_TEAM_FORM_SUBMIT_BTN__DATA_TEST_ID =
  "security-team-form-submit-btn";

export const REQUESTERS_SENIOR_LEADER_PROPERTY_KEY =
  "Requester's Senior Leader";

export const THIRD_PARTY_TIER = "Third party tier";

const isSeniorLeaderCriteriaToken = (token: CriteriaToken) =>
  token.questionDisplay === REQUESTERS_SENIOR_LEADER_PROPERTY_KEY;

const getUrlFragmentValue = (urlFragment: string) =>
  urlFragment.slice(urlFragment.indexOf("=") + 1);

const SERVICE_NOW_ROLE_MAP = new Map<string, ServiceNowRoleType>(
  SERVICE_NOW_ROLE_LIST.map((serviceNowRole) => [
    serviceNowRole,
    serviceNowRole,
  ])
);

export interface BackendToDisplay {
  [key: string]: Pick<CriteriaToken, "questionDisplay" | "answerDisplay">;
}

export interface DisplayToBackend {
  [key: string]: Pick<CriteriaToken, "questionId" | "answer">;
}

const getTeamCriteriaFormFieldsPrefilledData = (
  editSecurityTeamRecordData: SecurityTeam,
  backendToDisplay: BackendToDisplay
) => {
  const teamCriteriaPrefilledData: CriteriaSetValue = {
    tokens: [],
    operation: "or", //as per requirement filter tokens needs to be OR related until unless user changes it
  };
  const requestersSeniorLeaderTokenAliasData =
    editSecurityTeamRecordData.securityTeamSeniorLeaders
      ? editSecurityTeamRecordData.securityTeamSeniorLeaders!.join(",  ")
      : "";

  editSecurityTeamRecordData
    .criteria!.filter((criteria) => criteria !== null)
    .forEach((teamCriteriaSet) => {
      const { questionDisplay, answerDisplay } = convertBackendToDisplay(
        teamCriteriaSet!,
        backendToDisplay
      );
      teamCriteriaPrefilledData.tokens.push({
        answerDisplay,
        questionDisplay,
        operator: PROPERTY_FILTER_OPERATORS_LIST[0], //For Criteria Set's We will have only "=" operator
        questionId: teamCriteriaSet!.questionId!,
        answer: teamCriteriaSet!.answer!,
      });
      teamCriteriaPrefilledData.operation =
        teamCriteriaSet!.operator!.toLocaleLowerCase() as PropertyFilterOperation;
    });

  if (!!requestersSeniorLeaderTokenAliasData) {
    teamCriteriaPrefilledData.tokens.push({
      answerDisplay: requestersSeniorLeaderTokenAliasData,
      questionDisplay: REQUESTERS_SENIOR_LEADER_PROPERTY_KEY,
      operator: PROPERTY_FILTER_OPERATORS_LIST[2], //For Criteria Set's Requester's Senior Leader We will have only ":" operator
      questionId: REQUESTERS_SENIOR_LEADER_PROPERTY_KEY,
      answer: requestersSeniorLeaderTokenAliasData,
    });
  }

  return teamCriteriaPrefilledData;
};

const getCriteriaSetsContainerFormFieldsPrefilledData = (
  editSecurityTeamRecordData: SecurityTeam,
  backendToDisplay: BackendToDisplay,
  criteriaType:
    | SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
    | SecurityTeamFormFields.TASK_LOGIC_CRITERIA_SET_LISTS
) => {
  const criteriaSetsLists: CriteriaSetValue[] = [];
  const criteriaSetsTagNamesLists: Select[][] = [];
  const responseField =
    criteriaType === SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
      ? editSecurityTeamRecordData.ddqCriteria!
      : editSecurityTeamRecordData.taskCriteria!;

  responseField &&
    responseField!.forEach((criteriaSet, criteriaSetIndex) => {
      criteriaSetsLists[criteriaSetIndex] = {
        tokens: [],
        operation: "or", //as per requirement filter tokens needs to be OR related until unless user changes it
      };
      criteriaSetsTagNamesLists[criteriaSetIndex] = criteriaSet![0]!.tagNames!
        ? criteriaSet![0]!.tagNames!.map((tagName) => {
            return {
              label: decodeString(tagName!),
              value: decodeString(tagName!),
            };
          })
        : [];

      criteriaSet
        .filter((criteriaSetValue) => criteriaSetValue !== null)
        .forEach((criteriaSetValue) => {
          const { questionDisplay, answerDisplay } = convertBackendToDisplay(
            criteriaSetValue!,
            backendToDisplay
          );
          criteriaSetsLists[criteriaSetIndex].tokens.push({
            answerDisplay,
            questionDisplay:
              questionDisplay === VENDOR_TIER
                ? THIRD_PARTY_TIER
                : questionDisplay,
            operator: PROPERTY_FILTER_OPERATORS_LIST[0], //For Criteria Set's We will have only "=" operator,
            questionId: criteriaSetValue!.questionId!,
            answer: criteriaSetValue!.answer!,
          });
          criteriaSetsLists[criteriaSetIndex].operation = criteriaSetValue!
            .operator!
            ? (criteriaSetValue!.operator!.toLowerCase() as PropertyFilterOperation)
            : "or";
        });
    });

  return { criteriaSetsLists, criteriaSetsTagNamesLists };
};

interface GenerateCriteriaRequestPayLoadArgs {
  criteriaSetTokens: CriteriaToken[];
  criteriaOperator: string;
  criteriaType: CriteriaType;
  criteriaSetTagNamesList?: string[][];
  criteriaSetIndex?: number;
}

type GenerateSecurityTeamRequestPayload = Omit<
  GenerateCriteriaRequestPayLoadArgs,
  "criteriaSetTagNamesList" | "criteriaSetIndex"
>;

const generateSecurityTeamRequestPayload = ({
  criteriaSetTokens,
  criteriaOperator,
  criteriaType,
}: GenerateSecurityTeamRequestPayload): [string[], CreateCriteria[]] => {
  const seniorLeadershipDedupedTokens = new Set<string>();

  criteriaSetTokens.filter(isSeniorLeaderCriteriaToken).forEach((token) => {
    token.answerDisplay
      .split(",")
      .forEach(
        (value) =>
          value.trim() && seniorLeadershipDedupedTokens.add(value.trim())
      );
  });

  return [
    Array.from(seniorLeadershipDedupedTokens),
    generateCriteriaRequestPayLoad({
      criteriaSetTokens: criteriaSetTokens.filter(
        (token) => !isSeniorLeaderCriteriaToken(token)
      ),
      criteriaOperator,
      criteriaType,
    }),
  ];
};

export const generateCriteriaRequestPayLoad = ({
  criteriaSetTokens,
  criteriaOperator,
  criteriaType,
  criteriaSetTagNamesList,
  criteriaSetIndex,
}: GenerateCriteriaRequestPayLoadArgs): CreateCriteria[] => {
  const dedupedPropertyToValue = new Map<string, Set<string>>(); // Set to filter any duplicate tokens if selected to prevent BE Mutation failure issues
  const intakeQuestionCriteriaRequestPayload: CreateCriteria[] = [];

  criteriaSetTokens.forEach((token) => {
    const key = token.questionId;
    const value = token.answer;

    if (key && value && !dedupedPropertyToValue.get(key)?.has(value)) {
      intakeQuestionCriteriaRequestPayload.push({
        operator:
          criteriaOperator === "and"
            ? CriteriaOperator.AND
            : CriteriaOperator.OR,
        tagNames:
          criteriaSetTagNamesList &&
          criteriaSetIndex !== undefined &&
          criteriaSetTagNamesList[criteriaSetIndex] &&
          criteriaSetTagNamesList[criteriaSetIndex].length > 0
            ? criteriaSetTagNamesList[criteriaSetIndex]
            : null,
        intakeMaps: { key, value },
        criteriaType: criteriaType,
      });

      if (!dedupedPropertyToValue.has(key))
        dedupedPropertyToValue.set(key, new Set());
      dedupedPropertyToValue.get(key)!.add(value);
    }
  });

  return intakeQuestionCriteriaRequestPayload;
};

export interface LdapWithSnowRoleFormValues {
  teamLdapGroup: string;
  serviceNowRole: null | Select;
}

export interface CriteriaToken {
  operator: string;
  questionDisplay: string;
  answerDisplay: string;
  questionId: string;
  answer: string;
}

export interface CriteriaSetValue {
  tokens: CriteriaToken[];
  operation: PropertyFilterOperation;
}

export type CriteriaSetValueTokens = CriteriaSetValue["tokens"];

//To feed CloudScape form components data needs to be in this structure
export interface SecurityTeamConfigFormValues {
  //Basic Information Container Form State
  teamName: string;
  ldapWithSnowRoleList: LdapWithSnowRoleFormValues[];
  disableTpsConfig: boolean;
  disableTier3Automation: boolean;
  disableTier4Automation: boolean;
  disableAriesWorkflow: boolean;
  isActive: boolean;

  //Team Determination Container Form State
  teamDeterminationCriteria: CriteriaSetValue;

  //Question Generation Logic Container Form State
  ddqQuestionGenerationCheckbox: boolean;
  ddqLogicTagNameList: Select[];
  ddqQuestionCriteriaSetCheckbox: boolean;
  ddqLogicCriteriaSetLists: CriteriaSetValue[];
  ddqLogicCriteriaSetsTagNamesLists: Select[][];

  //Task Generation Logic Container Form State
  tasksGenerationCheckbox: boolean;
  taskLogicTagNameList: Select[];
  tasksCriteriaSetCheckbox: boolean;
  taskLogicCriteriaSetLists: CriteriaSetValue[];
  taskLogicCriteriaSetsTagNamesLists: Select[][];
}

export const initialPropertyFilterTokenOperation: Readonly<CriteriaSetValue> = {
  tokens: [],
  operation: "or", //as per requirement filter tokens needs to be OR related until unless user changes it
};

//Initial form state when user clicks on create new task button
export const FORM_INTIIAL_STATE: SecurityTeamConfigFormValues = Object.freeze({
  teamName: "",
  ldapWithSnowRoleList: [],
  disableTpsConfig: false,
  disableTier3Automation: false,
  disableTier4Automation: false,
  disableAriesWorkflow: false,
  isActive: false,
  teamDeterminationCriteria: initialPropertyFilterTokenOperation,
  ddqQuestionGenerationCheckbox: false,
  ddqLogicTagNameList: [],
  ddqQuestionCriteriaSetCheckbox: false,
  ddqLogicCriteriaSetLists: [],
  ddqLogicCriteriaSetsTagNamesLists: [],
  tasksGenerationCheckbox: false,
  taskLogicTagNameList: [],
  tasksCriteriaSetCheckbox: false,
  taskLogicCriteriaSetLists: [],
  taskLogicCriteriaSetsTagNamesLists: [],
});

const SECURITY_MUTATION_MAP: Record<
  FORM_MODE,
  () => UseMutationResult<
    GraphQLResult<
      GraphQLQuery<CreateSecurityTeamMutation | UpdateSecurityTeamMutation>
    >,
    unknown,
    any,
    unknown
  >
> = {
  create: useCreateSecurityTeam,
  edit: useUpdateSecurityTeam,
};

const getCriteriaPayloadComponent = (
  formState: SecurityTeamConfigFormValues
) => {
  const [
    requestersSeniorLeadersRequestPayLoad,
    teamDeterminationCriteriaRequestPayLoad,
  ] = generateSecurityTeamRequestPayload({
    criteriaSetTokens: formState.teamDeterminationCriteria.tokens,
    criteriaOperator: formState.teamDeterminationCriteria.operation,
    criteriaType: CriteriaType.SECURITY_TEAM_CRITERIA,
  });

  const ddqCriteriaSetsTagNamesCleanedList =
    formState.ddqLogicCriteriaSetsTagNamesLists.map(
      (ddqCriteriaSetsTagNamesList) =>
        ddqCriteriaSetsTagNamesList.map(
          (selectedTagName) => selectedTagName.value
        )
    );

  const ddqGenerationLogicRequestPayLoad: CreateCriteria[][] = [];

  formState.ddqLogicCriteriaSetLists.forEach((ddqCriteriaSet, index) => {
    if (ddqCriteriaSet.tokens.length > 0)
      ddqGenerationLogicRequestPayLoad.push(
        generateCriteriaRequestPayLoad({
          criteriaSetTokens: ddqCriteriaSet.tokens,
          criteriaOperator: ddqCriteriaSet.operation,
          criteriaType: CriteriaType.DDQ_CRITERIA,
          criteriaSetTagNamesList: ddqCriteriaSetsTagNamesCleanedList,
          criteriaSetIndex: index,
        })
      );
  });

  const taskCriteriaSetsTagNamesCleanedList =
    formState.taskLogicCriteriaSetsTagNamesLists.map(
      (taskCriteriaSetsTagNamesList) =>
        taskCriteriaSetsTagNamesList.map(
          (selectedTagName) => selectedTagName.value
        )
    );

  const taskGenerationLogicRequestPayLoad: CreateCriteria[][] = [];

  formState.taskLogicCriteriaSetLists.forEach((taskCriteriaSet, index) => {
    if (taskCriteriaSet.tokens.length > 0)
      taskGenerationLogicRequestPayLoad.push(
        generateCriteriaRequestPayLoad({
          criteriaSetTokens: taskCriteriaSet.tokens,
          criteriaOperator: taskCriteriaSet.operation,
          criteriaType: CriteriaType.TASK_CRITERIA,
          criteriaSetTagNamesList: taskCriteriaSetsTagNamesCleanedList,
          criteriaSetIndex: index,
        })
      );
  });

  return {
    securityTeamSeniorLeaders:
      requestersSeniorLeadersRequestPayLoad.length > 0
        ? requestersSeniorLeadersRequestPayLoad
        : null,
    criteria:
      teamDeterminationCriteriaRequestPayLoad.length > 0
        ? teamDeterminationCriteriaRequestPayLoad
        : null,
    ddqCriteriaRequests:
      ddqGenerationLogicRequestPayLoad.length > 0
        ? ddqGenerationLogicRequestPayLoad
        : null,
    taskCriteriaRequests:
      taskGenerationLogicRequestPayLoad.length > 0
        ? taskGenerationLogicRequestPayLoad
        : null,
  };
};

const getLdapWithSnowRole = (formState: SecurityTeamConfigFormValues) => {
  return formState.ldapWithSnowRoleList?.length !== 0
    ? formState.ldapWithSnowRoleList!.map((ldapWithSnowRoleObject) => {
        return {
          teamLdapGroup: ldapWithSnowRoleObject.teamLdapGroup.trimEnd(),
          serviceNowRole: ldapWithSnowRoleObject.serviceNowRole
            ? SERVICE_NOW_ROLE_MAP.get(
                ldapWithSnowRoleObject.serviceNowRole.value
              )
            : SERVICE_NOW_ROLE_LIST[0],
        };
      })
    : null;
};

const getMutationPayload = (
  formState: SecurityTeamConfigFormValues,
  formMode: FORM_MODE,
  editSecurityTeamId?: string
): CreateSecurityTeamRequest | UpdateSecurityTeamRequest => {
  return {
    ...getCriteriaPayloadComponent(formState),
    teamName: encodeString(formState.teamName),
    ldapWithSnowRole: getLdapWithSnowRole(formState),
    disableTpsConfig: formState.disableTpsConfig,
    disableTier3Automation: formState.disableTier3Automation,
    disableTier4Automation: formState.disableTier4Automation,
    disableAriesWorkflow: formState.disableAriesWorkflow,
    isActive: formState.isActive,
    ddqTagNames:
      formState.ddqLogicTagNameList.length > 0
        ? formState.ddqLogicTagNameList.map(
            (selectedTagName) => selectedTagName.value
          )
        : null,
    taskTagNames:
      formState.taskLogicTagNameList.length > 0
        ? formState.taskLogicTagNameList.map(
            (selectedTagName) => selectedTagName.value
          )
        : null,
    ...(formMode === FORM_MODE.EDIT
      ? { securityTeamId: editSecurityTeamId }
      : {}), //In case of edit mode to update existing Security Team record data we are sending securityTeamId which is it's primary key
  };
};

export const SecurityTeamConfigPage = withBundle(
  "pages.SecurityTeamConfigPage"
)((props: WithBundleProps) => {
  const getSecurityTeamsQueryResult = useGetSecurityTeams(RECORD_LIMITS);
  const securityTeamsNamesSet = useMemo<Set<string>>(() => {
    if (
      getSecurityTeamsQueryResult.data &&
      getSecurityTeamsQueryResult.data.length > 0
    ) {
      return new Set(
        getSecurityTeamsQueryResult.data.map(
          (securityTeam) => securityTeam.teamName!
        )
      );
    } else {
      return new Set("");
    }
  }, [getSecurityTeamsQueryResult.data]);
  const location = useLocation();
  const { editSecurityTeamId, formMode } = useMemo(() => {
    let temp: { editSecurityTeamId: string | undefined; formMode: FORM_MODE } =
      {
        editSecurityTeamId: undefined,
        formMode: FORM_MODE.CREATE,
      };

    if (location.hash) {
      location.hash.split("#").forEach((urlFragment: string) => {
        if (urlFragment.includes(SECURITY_CONFIG_TEAM_ID_URL_FRAGMENT)) {
          temp = {
            editSecurityTeamId: getUrlFragmentValue(urlFragment),
            formMode: FORM_MODE.EDIT,
          };
        }
      });
    }
    return temp;
  }, [location]);
  const editSecurityTeamQueryResult: UseQueryResult<SecurityTeam> =
    useGetSecurityTeam({ securityTeamId: editSecurityTeamId! });

  const [ldapUpdates, setLdapUpdates] = useState<LdapUpdates>(null);
  const { editSecurityTeamName, promptCutTicket } = useMemo(() => {
    if (formMode !== FORM_MODE.EDIT)
      return { editSecurityTeamName: undefined, promptCutTicket: undefined };

    if (
      !editSecurityTeamQueryResult.isLoading &&
      editSecurityTeamQueryResult.isSuccess &&
      editSecurityTeamQueryResult.data?.teamName
    ) {
      return {
        editSecurityTeamName: decodeString(
          editSecurityTeamQueryResult.data.teamName!
        ),
        promptCutTicket: promptUpdateTicketGenerator(
          setLdapUpdates,
          new Map([
            [
              editSecurityTeamQueryResult.data.securityTeamId!,
              editSecurityTeamQueryResult.data,
            ],
          ])
        ),
      };
    } else {
      return {
        editSecurityTeamName: props.bundle.getMessage(
          SecurityTeamFormStrings.SECURITY_TEAM_EDIT_FORM_FALLBACK_DISPLAY_TEXT
        ),
        promptCutTicket: undefined,
      };
    }
  }, [formMode, editSecurityTeamQueryResult]);
  const { setBreadCrumbDisplayValue } = useBreadCrumbContext();
  const intakeQuestionsQueryResult = useGetIntakeQuestions();
  const tagsQueryResult = useGetTags(RECORD_LIMITS);
  const navigate = useNavigate();
  const { mutate, isLoading } = SECURITY_MUTATION_MAP[formMode]();
  const { backendToDisplay, displayToBackend } = useMemo(() => {
    const backendToDisplay: BackendToDisplay = {};
    const displayToBackend: DisplayToBackend = {};
    if (intakeQuestionsQueryResult.data?.result) {
      for (const questionsRecord of intakeQuestionsQueryResult.data.result) {
        if (questionsRecord && questionsRecord.choices) {
          for (const choice of questionsRecord.choices) {
            if (
              choice &&
              choice.displayValue &&
              choice.backendValue &&
              questionsRecord.questionId &&
              questionsRecord.question
            ) {
              backendToDisplay[
                `${questionsRecord.questionId}_${choice.backendValue}`
              ] = {
                questionDisplay: questionsRecord.question,
                answerDisplay: choice.displayValue,
              };

              displayToBackend[
                `${questionsRecord.question}_${choice.displayValue}`
              ] = {
                questionId: questionsRecord.questionId,
                answer: choice.backendValue,
              };
            }
          }
        }
      }
    }

    return { backendToDisplay, displayToBackend };
  }, [intakeQuestionsQueryResult.data?.result]);

  const callMutate = (formState: SecurityTeamConfigFormValues) =>
    mutate(getMutationPayload(formState, formMode, editSecurityTeamId), {
      onSuccess: () => navigate(SECURITY_TEAMS_PAGE.href),
    });
  const securityTeamConfigFormState = useFormik<SecurityTeamConfigFormValues>({
    initialValues: FORM_INTIIAL_STATE,
    validationSchema: SecurityTeamConfigPageFormValidationSchema(
      props.bundle,
      formMode === FORM_MODE.EDIT ? editSecurityTeamName! : "",
      securityTeamsNamesSet,
      formMode
    ),
    onSubmit: () => {
      const ldapUpdates = getLdapUpdates({
        securityTeams: [
          {
            teamName: securityTeamConfigFormState.values.teamName.trimEnd(),
            ldapWithSnowRole:
              getLdapWithSnowRole(securityTeamConfigFormState.values)?.map(
                (ldapWithSnowRole) => {
                  return {
                    // This has to be manually added as we're mapping between input/output types
                    __typename: "SNowRoleModelOutput",
                    ...ldapWithSnowRole,
                  };
                }
              ) ?? null,
          },
        ],
        oldSecurityTeam:
          formMode === FORM_MODE.EDIT
            ? editSecurityTeamQueryResult.data
            : undefined,
        callback: () => callMutate(securityTeamConfigFormState.values),
      });

      if (ldapUpdates?.updates.size !== 0) setLdapUpdates(ldapUpdates);
      else callMutate(securityTeamConfigFormState.values);
    },
  });
  const [formStateLoaded, setFormStateLoaded] = useState(
    formMode === FORM_MODE.CREATE
  );

  useEffect(() => {
    if (editSecurityTeamName) setBreadCrumbDisplayValue(editSecurityTeamName!);
    else setBreadCrumbDisplayValue("");
  }, [editSecurityTeamName]);

  useEffect(() => {
    if (formMode === FORM_MODE.EDIT) {
      if (
        editSecurityTeamQueryResult.isSuccess &&
        editSecurityTeamQueryResult.data &&
        Object.keys(backendToDisplay).length
      ) {
        const tempEditRecordDataHolder =
          editSecurityTeamQueryResult.data! as SecurityTeam; //To avoid type conversion and undefined checking on every prefil
        const ddqContainerFormDataConverted =
          getCriteriaSetsContainerFormFieldsPrefilledData(
            tempEditRecordDataHolder,
            backendToDisplay,
            SecurityTeamFormFields.DDQ_LOGIC_CRITERIA_SET_LISTS
          );
        const taskContainerFormDataConverted =
          getCriteriaSetsContainerFormFieldsPrefilledData(
            tempEditRecordDataHolder,
            backendToDisplay,
            SecurityTeamFormFields.TASK_LOGIC_CRITERIA_SET_LISTS
          );

        securityTeamConfigFormState.resetForm({
          values: {
            teamName: decodeString(tempEditRecordDataHolder.teamName!),
            ldapWithSnowRoleList: tempEditRecordDataHolder.ldapWithSnowRole
              ? tempEditRecordDataHolder.ldapWithSnowRole!.map(
                  (ldapWithSnowRoleObject) => {
                    return {
                      teamLdapGroup: ldapWithSnowRoleObject!.teamLdapGroup!,
                      serviceNowRole: {
                        label: ldapWithSnowRoleObject!.serviceNowRole!,
                        value: ldapWithSnowRoleObject!.serviceNowRole!,
                      },
                    };
                  }
                )
              : [],
            disableTpsConfig: tempEditRecordDataHolder.disableTpsConfig!,
            disableTier3Automation:
              tempEditRecordDataHolder.disableTier3Automation!,
            disableTier4Automation:
              tempEditRecordDataHolder.disableTier4Automation!,
            disableAriesWorkflow:
              tempEditRecordDataHolder.disableAriesWorkflow!,
            isActive: tempEditRecordDataHolder.isActive!,
            teamDeterminationCriteria: getTeamCriteriaFormFieldsPrefilledData(
              tempEditRecordDataHolder,
              backendToDisplay
            ),
            ddqQuestionGenerationCheckbox:
              tempEditRecordDataHolder.ddqTagNames &&
              tempEditRecordDataHolder.ddqTagNames.length > 0
                ? true
                : false,
            ddqLogicTagNameList: tempEditRecordDataHolder.ddqTagNames
              ? tempEditRecordDataHolder.ddqTagNames!.map((ddqTagName) => {
                  return {
                    label: decodeString(ddqTagName!),
                    value: decodeString(ddqTagName!),
                  };
                })
              : [],
            ddqQuestionCriteriaSetCheckbox:
              ddqContainerFormDataConverted.criteriaSetsLists.length > 0 ||
              ddqContainerFormDataConverted.criteriaSetsTagNamesLists.length > 0
                ? true
                : false,
            ddqLogicCriteriaSetLists:
              ddqContainerFormDataConverted.criteriaSetsLists,
            ddqLogicCriteriaSetsTagNamesLists:
              ddqContainerFormDataConverted.criteriaSetsTagNamesLists,
            tasksGenerationCheckbox:
              tempEditRecordDataHolder.taskTagNames &&
              tempEditRecordDataHolder.taskTagNames.length > 0
                ? true
                : false,
            taskLogicTagNameList: tempEditRecordDataHolder.taskTagNames
              ? tempEditRecordDataHolder.taskTagNames!.map((taskTagName) => {
                  return {
                    label: decodeString(taskTagName!),
                    value: decodeString(taskTagName!),
                  };
                })
              : [],
            tasksCriteriaSetCheckbox:
              taskContainerFormDataConverted.criteriaSetsLists.length > 0 ||
              taskContainerFormDataConverted.criteriaSetsTagNamesLists.length >
                0
                ? true
                : false,
            taskLogicCriteriaSetLists:
              taskContainerFormDataConverted.criteriaSetsLists,
            taskLogicCriteriaSetsTagNamesLists:
              taskContainerFormDataConverted.criteriaSetsTagNamesLists,
          },
        });

        setFormStateLoaded(true);
      }
    } else {
      !editSecurityTeamQueryResult.isLoading && setFormStateLoaded(true);
    }
  }, [editSecurityTeamQueryResult.isSuccess, backendToDisplay, formMode]);

  return (
    <React.Fragment>
      <TicketModal
        ldapUpdates={ldapUpdates}
        closeModal={() => setLdapUpdates(null)}
      />
      <CloudScapeContentLayout
        header={
          <SecurityTeamConfigPageHeader
            formMode={formMode}
            editSecurityTeamName={editSecurityTeamName}
            securityTeamId={editSecurityTeamId}
            disableFormActionButtons={
              formMode === FORM_MODE.EDIT &&
              (!editSecurityTeamQueryResult.isSuccess ||
                !editSecurityTeamQueryResult.data)
            }
            submitForm={securityTeamConfigFormState.submitForm}
            formSubmissionStatus={isLoading}
            promptCutTicket={promptCutTicket!}
          />
        }
      >
        {!formStateLoaded ? (
          <LoadingSpinner />
        ) : (
          <form onSubmit={securityTeamConfigFormState.handleSubmit}>
            <CloudScapeForm
              actions={
                <CloudScapeSpaceBetween direction="horizontal" size="xs">
                  <CloudScapeButton
                    formAction="none"
                    variant="link"
                    data-testid={SECURITY_TEAM_FORM_CANCEL_BTN__DATA_TEST_ID}
                    onClick={() => {
                      securityTeamConfigFormState.resetForm({
                        values: { ...FORM_INTIIAL_STATE },
                      });
                      navigate(SECURITY_TEAMS_PAGE.href);
                    }}
                  >
                    {props.bundle.getMessage(
                      SecurityTeamFormStrings.SECURITY_TEAM_FORM_CANCEL_BUTTON_TEXT
                    )}
                  </CloudScapeButton>
                  <OrangeButton
                    variant="primary"
                    loading={isLoading} //Showing loading spinner indicator when valid form data mutation is in progress
                    formAction="submit"
                    data-testid={SECURITY_TEAM_FORM_SUBMIT_BTN__DATA_TEST_ID}
                  >
                    {formMode === FORM_MODE.CREATE
                      ? props.bundle.getMessage(
                          SecurityTeamFormStrings.SECURITY_TEAM_CREATE_FORM_SAVE_BUTTON_TEXT
                        )
                      : props.bundle.getMessage(
                          SecurityTeamFormStrings.SECURITY_TEAM_EDIT_FORM_SAVE_BUTTON_TEXT
                        )}
                  </OrangeButton>
                </CloudScapeSpaceBetween>
              }
            >
              <CloudScapeSpaceBetween direction="vertical" size="xs">
                <SecurityTeamBasicInformation
                  securityTeamConfigFormState={securityTeamConfigFormState}
                />
                <SecurityTeamDetermination
                  intakeQuestionsQueryResult={intakeQuestionsQueryResult}
                  securityTeamConfigFormState={securityTeamConfigFormState}
                  displayToBackend={displayToBackend}
                />
                <SecurityTeamTaskGenerationLogic
                  intakeQuestionsQueryResult={intakeQuestionsQueryResult}
                  tagsQueryResult={tagsQueryResult}
                  securityTeamConfigFormState={securityTeamConfigFormState}
                  displayToBackend={displayToBackend}
                />
                <SecurityTeamQuestionGenerationLogic
                  intakeQuestionsQueryResult={intakeQuestionsQueryResult}
                  tagsQueryResult={tagsQueryResult}
                  securityTeamConfigFormState={securityTeamConfigFormState}
                  displayToBackend={displayToBackend}
                />
              </CloudScapeSpaceBetween>
            </CloudScapeForm>
          </form>
        )}
      </CloudScapeContentLayout>
    </React.Fragment>
  );
});
