export interface Link {
  type: "link";
  text: string;
  href: string;
}

export const HOME_PAGE: Link = { type: "link", text: "home_page", href: "/" };
export const SECURITY_TEAMS_PAGE: Link = {
  type: "link",
  text: "security_teams",
  href: "/security-teams",
};
export const TAGS_PAGE: Link = { type: "link", text: "tags", href: "/tags" };
export const TASKS_PAGE: Link = { type: "link", text: "tasks", href: "/tasks" };
export const SECURITY_TEAM_CONFIG_PAGE: Link = {
  type: "link",
  text: "security_team_config",
  href: "/security-teams/security-team-config",
};

export const SIDENAVBAR_LINKS: Link[] = [
  SECURITY_TEAMS_PAGE,
  TAGS_PAGE,
  TASKS_PAGE,
];
