var endpointMap = {
	en: {
		"components.BreadCrumbs": "translation-en.json",
		"components.DeleteModal": "translation-en.json",
		"components.FlashBarNotifications": "translation-en.json",
		"components.FullPageHeader": "translation-en.json",
		"components.SecurityTeamConfigPageHeader": "translation-en.json",
		"components.SideNavBar": "translation-en.json",
		"components.TableComponents": "translation-en.json",
		"components.TasksPageHelpPanel": "translation-en.json",
		"components.TicketModal": "translation-en.json",
		"components.TopNavBar": "translation-en.json",
		"pages.SecurityTeamBasicInformation": "translation-en.json",
		"pages.SecurityTeamConfigPage": "translation-en.json",
		"pages.SecurityTeamDetermination": "translation-en.json",
		"pages.SecurityTeamQuestionGenerationLogic": "translation-en.json",
		"pages.SecurityTeamTaskGenerationLogic": "translation-en.json",
		"pages.SecurityTeamsPageTable": "translation-en.json",
		"pages.TagsPageForm": "translation-en.json",
		"pages.TagsPageTable": "translation-en.json",
		"pages.TasksPageForm": "translation-en.json",
		"pages.TasksPageTable": "translation-en.json"
	},
	"en-US": {
		"components.BreadCrumbs": "translation-en.json",
		"components.DeleteModal": "translation-en.json",
		"components.FlashBarNotifications": "translation-en.json",
		"components.FullPageHeader": "translation-en.json",
		"components.SecurityTeamConfigPageHeader": "translation-en.json",
		"components.SideNavBar": "translation-en.json",
		"components.TableComponents": "translation-en.json",
		"components.TasksPageHelpPanel": "translation-en.json",
		"components.TicketModal": "translation-en.json",
		"components.TopNavBar": "translation-en.json",
		"pages.SecurityTeamBasicInformation": "translation-en.json",
		"pages.SecurityTeamConfigPage": "translation-en.json",
		"pages.SecurityTeamDetermination": "translation-en.json",
		"pages.SecurityTeamQuestionGenerationLogic": "translation-en.json",
		"pages.SecurityTeamTaskGenerationLogic": "translation-en.json",
		"pages.SecurityTeamsPageTable": "translation-en.json",
		"pages.TagsPageForm": "translation-en.json",
		"pages.TagsPageTable": "translation-en.json",
		"pages.TasksPageForm": "translation-en.json",
		"pages.TasksPageTable": "translation-en.json"
	},
	und: {
		"components.BreadCrumbs": "translation.json",
		"components.DeleteModal": "translation.json",
		"components.FlashBarNotifications": "translation.json",
		"components.FullPageHeader": "translation.json",
		"components.SecurityTeamConfigPageHeader": "translation.json",
		"components.SideNavBar": "translation.json",
		"components.TableComponents": "translation.json",
		"components.TasksPageHelpPanel": "translation.json",
		"components.TicketModal": "translation.json",
		"components.TopNavBar": "translation.json",
		"pages.SecurityTeamBasicInformation": "translation.json",
		"pages.SecurityTeamConfigPage": "translation.json",
		"pages.SecurityTeamDetermination": "translation.json",
		"pages.SecurityTeamQuestionGenerationLogic": "translation.json",
		"pages.SecurityTeamTaskGenerationLogic": "translation.json",
		"pages.SecurityTeamsPageTable": "translation.json",
		"pages.TagsPageForm": "translation.json",
		"pages.TagsPageTable": "translation.json",
		"pages.TasksPageForm": "translation.json",
		"pages.TasksPageTable": "translation.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
