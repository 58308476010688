import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { getAllTasks } from "../graphql/queries";
import { GetAllTasksQuery, Task } from "../API";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { retryConfig, cacheConfig } from "../APIUtils";
import {
  API_KEYS,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

/**
 * A custom React Query hook for fetching tasks.
 *
 * @param limit - The maximum number of tasks to retrieve.
 * @returns The result of the query hook.
 */
export const useGetTasks = (limit: number): UseQueryResult<Task[]> => {
  const flashbarContext = useFlashBarContext();

  return useQuery({
    queryKey: [API_KEYS.GET_TASKS, limit],
    queryFn: async () => {
      const fetchResponse = await API.graphql<GraphQLQuery<GetAllTasksQuery>>(
        graphqlOperation(getAllTasks, {
          limit: limit,
          taskId: "", //Continuation key to get All records data to FE
        })
      );

      return [...(fetchResponse.data?.getAllTasks?.tasks ?? [])];
    },
    ...retryConfig,
    ...cacheConfig,
    onError: () => {
      //Display Query status using FlashBar message in tasks page for failed condition
      flashbarContext.setflashBarMessageOnPage!(PAGE_TITLE.TASKS);

      //Display error FlashBar Message for fetch failed
      flashbarContext.setFlashBarMessage!(
        FLASH_BAR_MESSAGES.TASKS_FETCH_FAILED
      );
    },
  });
};
