import React, { useState, useEffect } from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { MessageBundle } from "@amzn/arb-tools";
import { useFormik } from "formik";
import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { UseMutationResult } from "@tanstack/react-query";
import { CreateTaskMutation, UpdateTaskMutation } from "../../api/API";
import CloudScapeSplitPanel from "@cloudscape-design/components/split-panel";
import CloudScapeForm from "@cloudscape-design/components/form";
import CloudScapeSpaceBetween from "@cloudscape-design/components/space-between";
import CloudScapeButton from "@cloudscape-design/components/button";
import CloudScapeFormField from "@cloudscape-design/components/form-field";
import CloudScapeInput from "@cloudscape-design/components/input";
import CloudScapeColumnLayout from "@cloudscape-design/components/column-layout";
import CloudScapeTextarea from "@cloudscape-design/components/textarea";
import CloudScapeSelect from "@cloudscape-design/components/select";
import CloudScapeMultiselect from "@cloudscape-design/components/multiselect";
import {
  OrangeButton,
  CustomCheckBoxBtn,
} from "../../components/Buttons/CustomizedButtons";
import { TasksPageHelpPanel } from "../../components/HelpPanel/TasksPageHelpPanel";
import { PanelContextType } from "../../context/PanelContextProvider";
import {
  FORM_MODE,
  SEVERITY,
  TASK_FIELDS,
  TASKS_FORM_STRINGS,
  LOADING_SPINNER,
  RECORD_ACTIVE_VALUE,
  RECORD_IN_ACTIVE_VALUE,
  RECORD_STATE,
  RECORD_LIMITS,
} from "../../constants/constants";
import { encodeString, getStatusType } from "../../utils/CommonUtils";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useCreateTask } from "../../api/tasks/createTaskAPI";
import { useUpdateTask } from "../../api/tasks/updateTaskAPI";
import { useGetTags } from "../../api/tags/getTagsAPI";
import { TasksTableRecord } from "./TasksPageTable";
import { TaskFormValidationSchema } from "./TasksPageFormValidationSchema";

export const TASK_SAVE_BUTTON_DATA_TEST_ID = "save-task-btn";
export const TASK_CANCEL_BUTTON_DATA_TEST_ID = "cancel-btn";

export interface Select {
  label: string;
  value: string;
}

//To feed CloudScape form components data needs to be in this structure
export interface TaskFormValues {
  taskName: string;
  summary: string;
  description: string;
  severity: null | Select;
  dueDate: string | null;
  tagNames: null | Select[];
  issueTitle: string;
  issueDescription: string;
  whyIssueIsBad: string;
  howToFixIt: string;
  requestedEvidence: string;
  isActive: boolean;
  lastUpdated?: string; // Added this unused fields to match upstream types
  updatedBy?: string;
  taskId?: string;
}

//Initial form state when user clicks on create new task button
export const formInitialState: TaskFormValues = {
  taskName: "",
  summary: "",
  description: "",
  severity: null,
  dueDate: null,
  tagNames: [],
  issueTitle: "",
  issueDescription: "",
  whyIssueIsBad: "",
  howToFixIt: "",
  requestedEvidence: "",
  isActive: RECORD_IN_ACTIVE_VALUE, //As per requirements active field is by default inactive(false) until unless user activates it by checking checkbox
};

export interface TasksPageFormIssueFieldsProps {
  taskFormState: any; //taskFormState value is controlled by formik so we cannot assign an type for it
  bundle: MessageBundle;
  infoBtnHandler: (arg: TASKS_FORM_STRINGS) => void;
}

function TasksPageFormIssueFields(props: TasksPageFormIssueFieldsProps) {
  return (
    <React.Fragment>
      <CloudScapeSpaceBetween size="l" direction="vertical">
        <CloudScapeFormField
          label={props.bundle.getMessage(TASKS_FORM_STRINGS.ISSUE_TITLE)}
          stretch={true}
          info={
            <CloudScapeButton
              variant="inline-link"
              onClick={() =>
                props.infoBtnHandler(TASKS_FORM_STRINGS.ISSUE_TITLE)
              }
              formAction="none"
            >
              {props.bundle.getMessage(TASKS_FORM_STRINGS.INFO_BUTTON_TEXT)}
            </CloudScapeButton>
          }
          errorText={
            props.taskFormState.touched.issueTitle &&
            props.taskFormState.errors.issueTitle &&
            `${props.taskFormState.errors.issueTitle}`
          }
          data-test-id={TASKS_FORM_STRINGS.ISSUE_TITLE}
        >
          <CloudScapeInput
            value={props.taskFormState.values.issueTitle}
            onChange={({ detail }) => {
              props.taskFormState.setFieldValue(
                TASK_FIELDS.ISSUE_TITLE,
                detail.value.trimStart()
              );
            }}
          />
        </CloudScapeFormField>
        <CloudScapeFormField
          label={props.bundle.getMessage(TASKS_FORM_STRINGS.ISSUE_DESCRIPTION)}
          stretch={true}
          info={
            <CloudScapeButton
              variant="inline-link"
              onClick={() =>
                props.infoBtnHandler(TASKS_FORM_STRINGS.ISSUE_DESCRIPTION)
              }
              formAction="none"
            >
              {props.bundle.getMessage(TASKS_FORM_STRINGS.INFO_BUTTON_TEXT)}
            </CloudScapeButton>
          }
          errorText={
            props.taskFormState.touched.issueDescription &&
            props.taskFormState.errors.issueDescription &&
            `${props.taskFormState.errors.issueDescription}`
          }
          data-test-id={TASKS_FORM_STRINGS.ISSUE_DESCRIPTION}
        >
          <CloudScapeTextarea
            value={props.taskFormState.values.issueDescription}
            onChange={({ detail }) => {
              props.taskFormState.setFieldValue(
                TASK_FIELDS.ISSUE_DESCRIPTION,
                detail.value.trimStart()
              );
            }}
          />
        </CloudScapeFormField>
        <CloudScapeFormField
          label={props.bundle.getMessage(TASKS_FORM_STRINGS.WHY_ISSUE_IS_BAD)}
          stretch={true}
          info={
            <CloudScapeButton
              variant="inline-link"
              onClick={() =>
                props.infoBtnHandler(TASKS_FORM_STRINGS.WHY_ISSUE_IS_BAD)
              }
              formAction="none"
            >
              {props.bundle.getMessage(TASKS_FORM_STRINGS.INFO_BUTTON_TEXT)}
            </CloudScapeButton>
          }
          errorText={
            props.taskFormState.touched.whyIssueIsBad &&
            props.taskFormState.errors.whyIssueIsBad &&
            `${props.taskFormState.errors.whyIssueIsBad}`
          }
          data-test-id={TASKS_FORM_STRINGS.WHY_ISSUE_IS_BAD}
        >
          <CloudScapeTextarea
            value={props.taskFormState.values.whyIssueIsBad}
            onChange={({ detail }) => {
              props.taskFormState.setFieldValue(
                TASK_FIELDS.WHY_ISSUE_IS_BAD,
                detail.value.trimStart()
              );
            }}
          />
        </CloudScapeFormField>
        <CloudScapeFormField
          label={props.bundle.getMessage(TASKS_FORM_STRINGS.HOW_TO_FIX_IT)}
          stretch={true}
          info={
            <CloudScapeButton
              variant="inline-link"
              onClick={() =>
                props.infoBtnHandler(TASKS_FORM_STRINGS.HOW_TO_FIX_IT)
              }
              formAction="none"
            >
              {props.bundle.getMessage(TASKS_FORM_STRINGS.INFO_BUTTON_TEXT)}
            </CloudScapeButton>
          }
          errorText={
            props.taskFormState.touched.howToFixIt &&
            props.taskFormState.errors.howToFixIt &&
            `${props.taskFormState.errors.howToFixIt}`
          }
          data-test-id={TASKS_FORM_STRINGS.HOW_TO_FIX_IT}
        >
          <CloudScapeTextarea
            value={props.taskFormState.values.howToFixIt}
            onChange={({ detail }) => {
              props.taskFormState.setFieldValue(
                TASK_FIELDS.HOW_TO_FIX_IT,
                detail.value.trimStart()
              );
            }}
          />
        </CloudScapeFormField>
        <CloudScapeFormField
          label={props.bundle.getMessage(TASKS_FORM_STRINGS.REQUESTED_EVIDENCE)}
          stretch={true}
          errorText={
            props.taskFormState.touched.requestedEvidence &&
            props.taskFormState.errors.requestedEvidence &&
            `${props.taskFormState.errors.requestedEvidence}`
          }
          data-test-id={TASKS_FORM_STRINGS.REQUESTED_EVIDENCE}
        >
          <CloudScapeTextarea
            value={props.taskFormState.values.requestedEvidence}
            onChange={({ detail }) => {
              props.taskFormState.setFieldValue(
                TASK_FIELDS.REQUESTED_EVIDENCE,
                detail.value.trimStart()
              );
            }}
          />
        </CloudScapeFormField>
      </CloudScapeSpaceBetween>
    </React.Fragment>
  );
}

export interface TasksPageFormProps extends WithBundleProps {
  panelContext: PanelContextType;
  formMode: FORM_MODE;
  taskNamesSet: Set<string>;
  taskRecord?: TasksTableRecord;
}

const TASK_MUTATION_MAP: Record<
  FORM_MODE,
  () => UseMutationResult<
    GraphQLResult<GraphQLQuery<CreateTaskMutation | UpdateTaskMutation>>,
    unknown,
    any,
    unknown
  >
> = {
  create: useCreateTask,
  edit: useUpdateTask,
};

export const TasksPageForm = withBundle("pages.TasksPageForm")(
  (props: TasksPageFormProps) => {
    const {
      setSplitPanelOpen,
      setSplitPanelContent,
      setToolsPanelOpen,
      setToolsPanelContent,
    } = props.panelContext!;
    const { mutate, isIdle, isLoading, isSuccess } =
      TASK_MUTATION_MAP[props.formMode]();
    const tagsQuery = useGetTags(RECORD_LIMITS);
    const taskFormState = useFormik({
      initialValues: { ...formInitialState },
      validationSchema: TaskFormValidationSchema(
        props.bundle,
        props.formMode === FORM_MODE.EDIT ? props.taskRecord!.taskName : "",
        props.taskNamesSet!,
        props.formMode
      ),
      onSubmit: () => {
        //API Mutation hook will be called when form fields had no error's
        mutate({
          taskName: encodeString(taskFormState.values.taskName.trimEnd()),
          summary: encodeString(taskFormState.values.summary.trimEnd()),
          description: encodeString(taskFormState.values.description.trimEnd()),
          severity: taskFormState.values.severity!.value,
          dueDate: Number(taskFormState.values.dueDate),
          tagNames:
            taskFormState.values.tagNames!.length >= 1
              ? taskFormState.values.tagNames!.map((obj: any) => obj.value)
              : null,
          issueTitle: encodeString(taskFormState.values.issueTitle.trimEnd()),
          issueDescription: encodeString(
            taskFormState.values.issueDescription.trimEnd()
          ),
          whyIssueIsBad: encodeString(
            taskFormState.values.whyIssueIsBad.trimEnd()
          ),
          howToFixIt: encodeString(taskFormState.values.howToFixIt.trimEnd()),
          requestedEvidence: encodeString(
            taskFormState.values.howToFixIt.trimEnd()
          ),
          isActive: taskFormState.values.isActive,
          ...(props.formMode === FORM_MODE.EDIT
            ? { taskId: props.taskRecord!.taskId }
            : {}), //In case of edit mode to update existing task record data we are sending taskId which is it's primary key
        });
      },
    }); //just added placeholder need to add logic here about form states validating, submitting etc will cover this in another CR
    const [formStateLoaded, setFormStateLoaded] = useState(false);

    useEffect(() => {
      if (isIdle === false && isLoading === false && isSuccess === true) {
        setSplitPanelContent(null);
        setSplitPanelOpen(false);
      }
    }, [isIdle, isLoading]);

    useEffect(() => {
      setFormStateLoaded(false);
      if (props.formMode === FORM_MODE.EDIT) {
        taskFormState.resetForm({
          values: {
            taskName: props.taskRecord!.taskName,
            summary: props.taskRecord!.summary,
            description: props.taskRecord!.description,
            severity: props.taskRecord!.severity
              ? {
                  label: props.taskRecord!.severity,
                  value: props.taskRecord!.severity,
                }
              : null,
            dueDate: `${props.taskRecord!.dueDate}`,
            tagNames:
              props.taskRecord!.tagNames !== ""
                ? props.taskRecord!.tagNames!.split(",").map((tag: string) => ({
                    label: tag.trim(),
                    value: tag.trim(),
                  })) //We added some space before tag when comma separated to look good so trimming back here
                : [],
            isActive:
              props.taskRecord!.isActive.toLowerCase() === RECORD_STATE.ACTIVE
                ? RECORD_ACTIVE_VALUE
                : RECORD_IN_ACTIVE_VALUE,
            issueTitle: props.taskRecord!.issueTitle,
            issueDescription: props.taskRecord!.issueDescription,
            whyIssueIsBad: props.taskRecord!.whyIssueIsBad,
            howToFixIt: props.taskRecord!.howToFixIt,
            requestedEvidence: props.taskRecord!.requestedEvidence,
          },
        });
      } else {
        //When form is in edit state and user clicks create new task button in full page header to create brand new task so in that case form needs to be reset to formInitialState
        taskFormState.resetForm({ values: { ...formInitialState } });
      }
      setFormStateLoaded(true);
    }, [props.taskRecord, props.formMode]);

    useEffect(() => {
      //When user changes the Severity to Low or Moderate issue field values will be set to reset to avoid below two cases
      //Case 1: Added character validation then this fields can have validation errors that prevent user from submitting and user cannot see issues fields that have error when severity changed to Low or Moderate
      //Case 2: If we did not add validation error for issue fields as mentioned in Case 1 this could lead one more issue that user entered invalid characters and set Severity to Low or Moderate then Issue fields will not have validation error but fails API mutation
      if (
        taskFormState.values.severity?.value &&
        taskFormState.values.severity.value !== SEVERITY.HIGH
      ) {
        taskFormState.resetForm({
          values: {
            ...taskFormState.values,
            issueTitle: "",
            issueDescription: "",
            whyIssueIsBad: "",
            howToFixIt: "",
            requestedEvidence: "",
          },
        });
      }
    }, [taskFormState.values.severity]);

    const infoBtnHandler = (contentString: TASKS_FORM_STRINGS) => {
      //Handler to control Help Panel state and content
      setToolsPanelOpen(true);
      setToolsPanelContent(
        <TasksPageHelpPanel contentString={contentString} />
      );
    };

    return (
      <CloudScapeSplitPanel
        header={
          props.formMode === FORM_MODE.EDIT
            ? props.bundle.getMessage(TASKS_FORM_STRINGS.EDIT_TASK)
            : props.bundle.getMessage(TASKS_FORM_STRINGS.NEW_TASK)
        }
        hidePreferencesButton
        closeBehavior="hide"
      >
        {formStateLoaded ? (
          <form onSubmit={taskFormState.handleSubmit}>
            <CloudScapeForm
              actions={
                <CloudScapeSpaceBetween direction="horizontal" size="xs">
                  <CloudScapeButton
                    formAction="none"
                    variant="link"
                    onClick={() => {
                      setSplitPanelContent(null);
                      setSplitPanelOpen(false);
                    }}
                    data-testid={TASK_CANCEL_BUTTON_DATA_TEST_ID}
                  >
                    {props.bundle.getMessage(TASKS_FORM_STRINGS.CANCEL)}
                  </CloudScapeButton>
                  <OrangeButton
                    variant="primary"
                    loading={isLoading} //Showing loading spinner indicator when valid form data mutation is in progress
                    formAction="submit"
                    data-testid={TASK_SAVE_BUTTON_DATA_TEST_ID}
                  >
                    {props.bundle.getMessage(TASKS_FORM_STRINGS.SAVE_TASK)}
                  </OrangeButton>
                </CloudScapeSpaceBetween>
              }
            >
              <CloudScapeColumnLayout
                columns={
                  taskFormState.values.severity?.value === SEVERITY.HIGH ? 2 : 1
                }
              >
                <CloudScapeSpaceBetween size="l" direction="vertical">
                  <CloudScapeFormField
                    constraintText={props.bundle.getMessage(
                      TASKS_FORM_STRINGS.TASK_NAME_CONSTRAINT
                    )}
                    label={props.bundle.getMessage(
                      TASKS_FORM_STRINGS.TASK_NAME
                    )}
                    errorText={
                      taskFormState.touched.taskName &&
                      taskFormState.errors.taskName &&
                      `${taskFormState.errors.taskName}`
                    }
                    stretch={true}
                    data-test-id={TASKS_FORM_STRINGS.TASK_NAME}
                  >
                    <CloudScapeInput
                      value={taskFormState.values.taskName}
                      onChange={({ detail }) => {
                        taskFormState.setFieldValue(
                          TASK_FIELDS.TASK_NAME,
                          detail.value.trimStart()
                        );
                      }}
                    />
                  </CloudScapeFormField>
                  <CloudScapeFormField
                    label={props.bundle.getMessage(TASKS_FORM_STRINGS.SUMMARY)}
                    errorText={
                      taskFormState.touched.summary &&
                      taskFormState.errors.summary &&
                      `${taskFormState.errors.summary}`
                    }
                    stretch={true}
                    data-test-id={TASKS_FORM_STRINGS.SUMMARY}
                  >
                    <CloudScapeTextarea
                      value={taskFormState.values.summary}
                      onChange={({ detail }) => {
                        taskFormState.setFieldValue(
                          TASK_FIELDS.SUMMARY,
                          detail.value.trimStart()
                        );
                      }}
                    />
                  </CloudScapeFormField>
                  <CloudScapeFormField
                    label={props.bundle.getMessage(
                      TASKS_FORM_STRINGS.DESCRIPTION
                    )}
                    errorText={
                      taskFormState.touched.description &&
                      taskFormState.errors.description &&
                      `${taskFormState.errors.description}`
                    }
                    stretch={true}
                    data-test-id={TASKS_FORM_STRINGS.DESCRIPTION}
                  >
                    <CloudScapeTextarea
                      value={taskFormState.values.description}
                      onChange={({ detail }) => {
                        taskFormState.setFieldValue(
                          TASK_FIELDS.DESCRIPTION,
                          detail.value.trimStart()
                        );
                      }}
                    />
                  </CloudScapeFormField>
                  <CloudScapeFormField
                    label={props.bundle.getMessage(TASKS_FORM_STRINGS.SEVERITY)}
                    info={
                      <CloudScapeButton
                        variant="inline-link"
                        onClick={() => {
                          infoBtnHandler(TASKS_FORM_STRINGS.SEVERITY);
                        }}
                        formAction="none"
                      >
                        {props.bundle.getMessage(
                          TASKS_FORM_STRINGS.INFO_BUTTON_TEXT
                        )}
                      </CloudScapeButton>
                    }
                    stretch={true}
                    errorText={
                      taskFormState.touched.severity &&
                      taskFormState.errors.severity &&
                      `${taskFormState.errors.severity}`
                    }
                    data-test-id={TASKS_FORM_STRINGS.SEVERITY}
                  >
                    <CloudScapeSelect
                      options={[
                        SEVERITY.HIGH,
                        SEVERITY.MODERATE,
                        SEVERITY.LOW,
                      ].map((severity) => {
                        return {
                          label: props.bundle.getMessage(
                            severity.toLowerCase()
                          ), //Retrieving this value from puff.json for internalization
                          value: severity,
                        };
                      })}
                      selectedOption={taskFormState.values.severity}
                      onChange={({ detail }) => {
                        taskFormState.setFieldValue(
                          TASK_FIELDS.SEVERITY,
                          detail.selectedOption
                        );
                      }}
                      placeholder={props.bundle.getMessage(
                        TASKS_FORM_STRINGS.SEVERITY_PLACEHOLDER
                      )}
                    />
                  </CloudScapeFormField>
                  <CloudScapeFormField
                    constraintText={props.bundle.getMessage(
                      TASKS_FORM_STRINGS.DUE_DATE_CONSTRAINT
                    )}
                    description={props.bundle.getMessage(
                      TASKS_FORM_STRINGS.DUE_DATE_DESCRIPTION
                    )}
                    label={props.bundle.getMessage(TASKS_FORM_STRINGS.DUE_DATE)}
                    stretch={true}
                    errorText={
                      taskFormState.touched.dueDate &&
                      taskFormState.errors.dueDate &&
                      `${taskFormState.errors.dueDate}`
                    }
                    data-test-id={TASKS_FORM_STRINGS.DUE_DATE}
                  >
                    <CloudScapeInput
                      inputMode="numeric"
                      type="number"
                      value={
                        taskFormState.values.dueDate === null
                          ? ""
                          : `${taskFormState.values.dueDate}`
                      }
                      onChange={({ detail }) => {
                        taskFormState.setFieldValue(
                          TASK_FIELDS.DUE_DATE,
                          detail.value
                        );
                      }}
                    />
                  </CloudScapeFormField>

                  <CloudScapeFormField
                    label={props.bundle.getMessage(
                      TASKS_FORM_STRINGS.TAG_NAMES
                    )}
                    stretch={true}
                    data-test-id={TASKS_FORM_STRINGS.TAG_NAMES}
                  >
                    <CloudScapeMultiselect
                      selectedOptions={taskFormState.values.tagNames ?? []}
                      options={
                        tagsQuery.isLoading === false &&
                        tagsQuery.isSuccess === true
                          ? tagsQuery.data.map((tagRecord) => {
                              return {
                                label: tagRecord.tagName!,
                                value: tagRecord.tagName!,
                              };
                            })
                          : []
                      }
                      placeholder={props.bundle.getMessage(
                        TASKS_FORM_STRINGS.TAGS_PLACEHOLDER
                      )}
                      filteringType="auto"
                      onChange={({ detail }) => {
                        taskFormState.setFieldValue(
                          TASK_FIELDS.TAG_NAMES,
                          detail.selectedOptions
                        );
                      }}
                      empty={props.bundle.getMessage(
                        TASKS_FORM_STRINGS.TAGS_EMPTY_MESSAGE
                      )}
                      loadingText={props.bundle.getMessage(
                        TASKS_FORM_STRINGS.TAGS_LOADING_MESSAGE
                      )}
                      errorText={props.bundle.getMessage(
                        TASKS_FORM_STRINGS.TAGS_LOADING_ERROR_MESSAGE
                      )}
                      recoveryText={props.bundle.getMessage(
                        TASKS_FORM_STRINGS.TAGS_RECOVERY_TEXT
                      )}
                      statusType={getStatusType(
                        tagsQuery.isLoading,
                        tagsQuery.isSuccess
                      )}
                      finishedText={props.bundle.getMessage(
                        TASKS_FORM_STRINGS.TAGS_FINISHED_TEXT
                      )}
                      onLoadItems={() => {
                        //When Fetch Tags API Failed on clicking retry recovery button we need to make useGetTags to refetch.
                        //If issue still persisting we have provided helper text in flashbar message to report issue by creating TT to address
                        if (
                          tagsQuery.isLoading === false &&
                          tagsQuery.isSuccess === false
                        ) {
                          tagsQuery.refetch();
                        }
                      }}
                    />
                  </CloudScapeFormField>

                  {/* Below are form field for issues*/}
                </CloudScapeSpaceBetween>
                <CloudScapeSpaceBetween size="l" direction="vertical">
                  {taskFormState.values.severity?.value === SEVERITY.HIGH && (
                    <TasksPageFormIssueFields
                      taskFormState={taskFormState}
                      bundle={props.bundle}
                      infoBtnHandler={infoBtnHandler}
                    />
                  )}
                  <CloudScapeFormField
                    data-test-id={TASKS_FORM_STRINGS.IS_ACTIVE}
                  >
                    <CustomCheckBoxBtn
                      checked={taskFormState.values.isActive}
                      onChange={({ detail }) => {
                        taskFormState.setFieldValue(
                          TASK_FIELDS.IS_ACTIVE,
                          detail.checked
                        );
                      }}
                    >
                      {props.bundle.getMessage(TASKS_FORM_STRINGS.IS_ACTIVE)}
                    </CustomCheckBoxBtn>
                  </CloudScapeFormField>
                </CloudScapeSpaceBetween>
              </CloudScapeColumnLayout>
            </CloudScapeForm>
          </form>
        ) : (
          <LoadingSpinner data-testid={LOADING_SPINNER} />
        )}
      </CloudScapeSplitPanel>
    );
  }
);
