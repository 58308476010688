import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { deleteTasks } from "../graphql/mutations";
import { DeleteTasksMutation } from "../API";
import { retryConfig } from "../APIUtils";
import {
  API_KEYS,
  FLASH_BAR_MESSAGES,
  PAGE_TITLE,
} from "../../constants/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

export const useDeleteTasks = () => {
  const flashbarContext = useFlashBarContext();
  const [recordsLength, setRecordsLength] = useState(0);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [API_KEYS.DELETE_TASKS],
    mutationFn: async (recordIds: string[]) => {
      setRecordsLength(recordIds.length); //store record count to display appropriate flashbar message
      return await API.graphql<GraphQLQuery<DeleteTasksMutation>>(
        graphqlOperation(deleteTasks, {
          input: {
            taskIds: recordIds,
          },
        })
      );
    },
    ...retryConfig,
    //After trying mutation as configured in retryConfig we will perform necessary steps accordingly
    onSuccess: () => {
      //Display Mutation status using FlashBar message in tasks page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.TASKS);

      //Display success FlashBar Message for delete successful using record count
      recordsLength > 1
        ? flashbarContext.setFlashBarMessage(
            FLASH_BAR_MESSAGES.TASKS_DELETE_SUCCESSFUL
          )
        : flashbarContext.setFlashBarMessage(
            FLASH_BAR_MESSAGES.TASK_DELETE_SUCCESSFUL
          );

      //invalidating existing API data up on performing successful mutation to fetch latest data
      queryClient.invalidateQueries({ queryKey: [API_KEYS.GET_TASKS] });
    },
    onError: () => {
      //Display Mutation status using FlashBar message in tasks page
      flashbarContext.setflashBarMessageOnPage(PAGE_TITLE.TASKS);

      //Display error FlashBar Message for delete failed using record count
      recordsLength > 1
        ? flashbarContext.setFlashBarMessage(
            FLASH_BAR_MESSAGES.TASKS_DELETE_FAILED
          )
        : flashbarContext.setFlashBarMessage(
            FLASH_BAR_MESSAGES.TASK_DELETE_FAILED
          );

      //invalidating existing API data up on performing failed mutation to fetch latest data
      queryClient.invalidateQueries({ queryKey: [API_KEYS.GET_TASKS] });
    },
  });
};
