import React, { useState } from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeHeader from "@cloudscape-design/components/header";
import CloudScapeSpaceBetween from "@cloudscape-design/components/space-between";
import CloudScapeButton from "@cloudscape-design/components/button";
import {
  RedButton,
  OrangeButton,
} from "../../components/Buttons/CustomizedButtons";
import { DeleteModal } from "../../components/Modal/DeleteModal";
import { FORM_MODE, PAGE_TITLE } from "../../constants/constants";
import { getRecordId } from "../../utils/HeaderUtils";
import { TagsTableRecord } from "../../pages/tagsPage/TagsPageTable";
import { TasksTableRecord } from "../../pages/tasksPage/TasksPageTable";
import { SecurityTeamsTableRecord } from "../../pages/securityTeamsPage/SecurityTeamsPageTable";
import { PromptCutTicketType } from "../../utils/ModalUtils";

export type FormHandlerType = (
  formMode: FORM_MODE,
  recordItem?: TagsTableRecord | TasksTableRecord | SecurityTeamsTableRecord
) => void;

interface FullPageHeaderProps extends WithBundleProps {
  currentRecords:
    | TagsTableRecord[]
    | TasksTableRecord[]
    | SecurityTeamsTableRecord[];
  totalRecords: string;
  page: PAGE_TITLE.TAGS | PAGE_TITLE.TASKS | PAGE_TITLE.SECURITY_TEAMS;
  formHandler: FormHandlerType;
  promptCutTicket?: PromptCutTicketType;
}

export const FullPageHeader = withBundle("components.FullPageHeader")(
  (props: FullPageHeaderProps) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    return (
      <React.Fragment>
        <DeleteModal
          selectedRecordIds={props.currentRecords.map((currentRecord) =>
            getRecordId(currentRecord)
          )} //sending list of recordId's to be deleted at deleteModal Component
          deleteModalOpen={deleteModalOpen}
          closeModal={() => setDeleteModalOpen(false)}
          page={props.page} //used to render right page specific alert info content and performing right delete mutations
          promptCutTicket={props.promptCutTicket!}
        />
        <CloudScapeHeader
          variant="awsui-h1-sticky"
          data-testid="full-page-header"
          actions={
            <CloudScapeSpaceBetween size="xs" direction="horizontal">
              {props.currentRecords.length > 0 && (
                <RedButton
                  data-testid="header-btn-delete"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  {props.bundle.formatMessage(
                    `delete_button_content_for_${props.page}`,
                    {
                      numRecords: props.currentRecords.length,
                    }
                  )}
                </RedButton>
              )}
              {props.currentRecords.length === 1 && (
                <CloudScapeButton
                  data-testid="header-btn-edit"
                  onClick={() =>
                    props.formHandler(FORM_MODE.EDIT, props.currentRecords[0])
                  }
                >
                  {props.bundle.formatMessage("edit_button_content", {
                    page: props.page,
                  })}
                </CloudScapeButton>
              )}
              <OrangeButton
                data-testid="header-btn-create"
                variant="primary"
                onClick={() => props.formHandler(FORM_MODE.CREATE)}
              >
                {props.bundle.formatMessage("new_button_content", {
                  page: props.page,
                })}
              </OrangeButton>
            </CloudScapeSpaceBetween>
          }
          counter={props.totalRecords}
        >
          {props.bundle.formatMessage("page_header", {
            page: props.page,
          })}
        </CloudScapeHeader>
      </React.Fragment>
    );
  }
);
