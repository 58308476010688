import { MessageBundle } from "@amzn/arb-tools";
import {
  PROPERTY_FILTER_OPERATORS_LIST,
  TAG_FIELDS,
  TAG_TABLE_COLUMN_HEADINGS,
} from "../../constants/constants";

export const TagsFilterFieldsMap: Map<TAG_FIELDS, TAG_TABLE_COLUMN_HEADINGS> =
  new Map([
    [TAG_FIELDS.TAG_NAME, TAG_TABLE_COLUMN_HEADINGS.TAG_NAME_COLUMN_HEADING],
    [TAG_FIELDS.DESCRIPTION, TAG_TABLE_COLUMN_HEADINGS.DESCRIPTION],
    [TAG_FIELDS.IS_ACTIVE, TAG_TABLE_COLUMN_HEADINGS.IS_ACTIVE],
    [TAG_FIELDS.UPDATED_BY, TAG_TABLE_COLUMN_HEADINGS.UPDATED_BY],
  ]);

export const getTagsPageFilteringProperties = (bundle: MessageBundle) => {
  return Array.from(TagsFilterFieldsMap.entries()).map(([key, value]) => {
    return {
      propertyLabel: bundle.getMessage(value),
      key: key,
      groupValuesLabel: bundle.getMessage(value),
      operators: PROPERTY_FILTER_OPERATORS_LIST,
    };
  });
};
