import { API, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useLocation } from "react-router";
import { getAllSecurityTeams } from "../graphql/queries";
import { GetAllSecurityTeamsQuery, SecurityTeam } from "../API";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { retryConfig, cacheConfig } from "../APIUtils";
import { API_KEYS, FLASH_BAR_MESSAGES } from "../../constants/constants";
import { getPageTitleFromPathname } from "../../utils/FlashBarUtils";
import { useFlashBarContext } from "../../context/FlashBarContextProvider";

/**
 * A custom React Query hook for fetching security teams.
 *
 * @param limit - The maximum number of security team records to retrieve.
 * @returns The result of the query hook.
 */
export const useGetSecurityTeams = (
  limit: number
): UseQueryResult<SecurityTeam[]> => {
  const flashbarContext = useFlashBarContext();
  const location = useLocation();

  return useQuery({
    queryKey: [API_KEYS.GET_SECURITY_TEAMS, limit],
    queryFn: async () => {
      const fetchResponse = await API.graphql<
        GraphQLQuery<GetAllSecurityTeamsQuery>
      >(
        graphqlOperation(getAllSecurityTeams, {
          limit: limit,
          securityTeamId: "", //Continuation key to get All records data to FE
        })
      );

      return [...(fetchResponse.data?.getAllSecurityTeams?.securityTeam ?? [])];
    },
    ...retryConfig,
    ...cacheConfig,
    onError: () => {
      //Display Query status using FlashBar message in security teams page for failed condition
      flashbarContext.setflashBarMessageOnPage!(
        getPageTitleFromPathname(location.pathname)!
      );

      //Display error FlashBar Message for fetch failed
      flashbarContext.setFlashBarMessage!(
        FLASH_BAR_MESSAGES.SECURITY_TEAMS_FETCH_FAILED
      );
    },
  });
};
