import React from "react";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { useUserContext } from "../../context/UserProvider";

function TopNavBar(props: WithBundleProps) {
  const { alias } = useUserContext();

  return (
    <div
      id="h"
      style={{ position: "sticky", top: 0, zIndex: 1002 }}
      data-testid="topnavbar-wrapper"
    >
      <TopNavigation
        identity={{
          href: "",
        }}
        utilities={[
          { type: "button" }, //to have a small split behind profile name
          {
            type: "button",
            text: alias,
          },
        ]}
        data-testid="top-navbar-component"
      />
    </div>
  );
}

export default withBundle("components.TopNavBar")(TopNavBar);
