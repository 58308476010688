import React from "react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import CloudScapeBox from "@cloudscape-design/components/box";
import CloudScapeSpaceBetween from "@cloudscape-design/components/space-between";
import CloudScapeButton from "@cloudscape-design/components/button";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/query-core";
import { Tag, Task, SecurityTeam } from "../../api/API";

interface TableNoMatchStateProps extends WithBundleProps {
  page: string;
  onClearFilter: () => void;
}

export const TableNoMatchState = withBundle("components.TableComponents")(
  (props: TableNoMatchStateProps) => {
    return (
      <CloudScapeBox
        margin={{ vertical: "xs" }}
        textAlign="center"
        color="inherit"
      >
        <CloudScapeSpaceBetween size="xxs">
          {props.bundle.formatMessage("no_fuzzy_string_match", {
            page: props.page,
            div: (child: string, index: number) => (
              <div key={`div_${index}`}>{child}</div>
            ),
            p: (child: string, index: number) => (
              <p key={`p_${index}`}>{child}</p>
            ),
          })}
          <CloudScapeButton onClick={props.onClearFilter}>
            {props.bundle.getMessage("clear_filter")}
          </CloudScapeButton>
        </CloudScapeSpaceBetween>
      </CloudScapeBox>
    );
  }
);

interface TableEmptyStateProps extends WithBundleProps {
  contentName: string;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<Tag[] | Task[] | SecurityTeam[]>>;
}

export const TableEmptyState = withBundle("components.TableComponents")(
  (props: TableEmptyStateProps) => {
    return (
      <CloudScapeBox
        margin={{ vertical: "xs" }}
        textAlign="center"
        color="inherit"
      >
        <CloudScapeSpaceBetween size="l" direction="vertical">
          {props.bundle.formatMessage("empty_state", {
            page: props.contentName,
            div: (child: string) => <div key="1">{child}</div>,
            p: (child: string) => <p key="1">{child}</p>,
          })}
          <CloudScapeButton onClick={() => props.refetch()}>
            {props.bundle.getMessage("table_data_recovery_text")}
          </CloudScapeButton>
        </CloudScapeSpaceBetween>
      </CloudScapeBox>
    );
  }
);
