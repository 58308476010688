/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTags = /* GraphQL */ `query GetTags($input: GetTagsInputType!) {
  getTags(input: $input) {
    tags {
      intakeId
      tagName
      securityTeam
      description
      isActive
      comments
      lastUpdated
      updatedBy
      tagId
      tier
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagsQueryVariables, APITypes.GetTagsQuery>;
export const getTasks =
  /* GraphQL */ `query GetTasks($input: GetTasksInputType!) {
  getTasks(input: $input) {
    tasks {
      taskName
      description
      howToFixIt
      issueDescription
      issueTitle
      requestedEvidence
      severity
      state
      summary
      tagNames
      title
      whyIssueIsBad
      type
      lastUpdated
      updatedBy
      taskId
      dueDate
      isActive
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTasksQueryVariables, APITypes.GetTasksQuery>;
export const getTagById =
  /* GraphQL */ `query GetTagById($input: GetTagByIdRequest!) {
  getTagById(input: $input) {
    intakeId
    tagName
    securityTeam
    description
    isActive
    comments
    lastUpdated
    updatedBy
    tagId
    tier
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetTagByIdQueryVariables,
    APITypes.GetTagByIdQuery
  >;
export const getAllTags =
  /* GraphQL */ `query GetAllTags($limit: Int, $tagId: String) {
  getAllTags(limit: $limit, tagId: $tagId) {
    tags {
      intakeId
      tagName
      securityTeam
      description
      isActive
      comments
      lastUpdated
      updatedBy
      tagId
      tier
      __typename
    }
    continuationKey
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetAllTagsQueryVariables,
    APITypes.GetAllTagsQuery
  >;
export const getTaskById =
  /* GraphQL */ `query GetTaskById($input: GetTaskByIdRequest!) {
  getTaskById(input: $input) {
    taskName
    description
    howToFixIt
    issueDescription
    issueTitle
    requestedEvidence
    severity
    state
    summary
    tagNames
    title
    whyIssueIsBad
    type
    lastUpdated
    updatedBy
    taskId
    dueDate
    isActive
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetTaskByIdQueryVariables,
    APITypes.GetTaskByIdQuery
  >;
export const getAllTasks =
  /* GraphQL */ `query GetAllTasks($limit: Int, $taskId: String) {
  getAllTasks(limit: $limit, taskId: $taskId) {
    tasks {
      taskName
      description
      howToFixIt
      issueDescription
      issueTitle
      requestedEvidence
      severity
      state
      summary
      tagNames
      title
      whyIssueIsBad
      type
      lastUpdated
      updatedBy
      taskId
      dueDate
      isActive
      __typename
    }
    continuationKey
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetAllTasksQueryVariables,
    APITypes.GetAllTasksQuery
  >;
export const getSecurityTeamById =
  /* GraphQL */ `query GetSecurityTeamById($input: GetSecurityTeamByIdRequest!) {
  getSecurityTeamById(input: $input) {
    securityTeamId
    teamName
    teamDescription
    securityTeamSeniorLeaders
    taskTagNames
    ddqTagNames
    ldapWithSnowRole {
      teamLdapGroup
      serviceNowRole
      __typename
    }
    disableTier3Automation
    disableTier4Automation
    snowGroupName
    isActive
    disableTpsConfig
    disableAriesWorkflow
    lastUpdated
    updatedBy
    criteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    taskCriteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    ddqCriteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetSecurityTeamByIdQueryVariables,
    APITypes.GetSecurityTeamByIdQuery
  >;
export const getSecurityTeam =
  /* GraphQL */ `query GetSecurityTeam($input: GetSecurityTeamType!) {
  getSecurityTeam(input: $input) {
    securityTeamId
    teamName
    teamDescription
    securityTeamSeniorLeaders
    taskTagNames
    ddqTagNames
    ldapWithSnowRole {
      teamLdapGroup
      serviceNowRole
      __typename
    }
    disableTier3Automation
    disableTier4Automation
    disableAriesWorkflow
    snowGroupName
    isActive
    disableTpsConfig
    lastUpdated
    updatedBy
    criteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    taskCriteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    ddqCriteria {
      criteriaId
      questionId
      answer
      operator
      tagNames
      securityTeamId
      criteriaType
      mappedCriteria
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetSecurityTeamQueryVariables,
    APITypes.GetSecurityTeamQuery
  >;
export const getAllSecurityTeams =
  /* GraphQL */ `query GetAllSecurityTeams($limit: Int, $securityTeamId: String) {
  getAllSecurityTeams(limit: $limit, securityTeamId: $securityTeamId) {
    securityTeam {
      securityTeamId
      teamName
      teamDescription
      securityTeamSeniorLeaders
      taskTagNames
      ddqTagNames
      ldapWithSnowRole {
        teamLdapGroup
        serviceNowRole
        __typename
      }
      disableTier3Automation
      disableTier4Automation
      disableAriesWorkflow
      snowGroupName
      isActive
      disableTpsConfig
      lastUpdated
      updatedBy
      criteria {
        criteriaId
        questionId
        answer
        operator
        tagNames
        securityTeamId
        criteriaType
        mappedCriteria
        __typename
      }
      taskCriteria {
        criteriaId
        questionId
        answer
        operator
        tagNames
        securityTeamId
        criteriaType
        mappedCriteria
        __typename
      }
      ddqCriteria {
        criteriaId
        questionId
        answer
        operator
        tagNames
        securityTeamId
        criteriaType
        mappedCriteria
        __typename
      }
      __typename
    }
    continuationKey
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetAllSecurityTeamsQueryVariables,
    APITypes.GetAllSecurityTeamsQuery
  >;
export const getAllIntakeFields = /* GraphQL */ `query getAllIntakeFields {
  getAllIntakeFields {
    result {
      question
      questionType
      questionId
      choices {
        displayValue
        backendValue
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllIntakeFieldsQueryVariables,
  APITypes.GetAllIntakeFieldsQuery
>;
